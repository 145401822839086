import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import { subDays } from "date-fns";


const StartSubscriptionModal = ({ subscriptionShow, subscriptionData, toggleSubscriptionModal, UpdateSchoolSubscription, amountError, setAmountError }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [school_detail_id, setSchool_detail_id] = useState("")
    const [row, setRow] = useState()
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [amount, setAmount] = useState()
    // const [amountError, setAmountError] = useState()
    const [startDateDefault, setStartDateDefault] = useState("")

    const [yearOption] = useState([
        { value: 365 * 1, label: "1 Year" },
        { value: 365 * 2, label: "2 Years" },
        { value: 365 * 3, label: "3 Years" },
        { value: 365 * 4, label: "4 Years" },
        { value: 365 * 5, label: "5 Years" },
    ])
    const [duration, setDuration] = useState(365 * 1)
    useEffect(() => {
        setModalOpen(subscriptionShow);
        setRow(subscriptionData)
        setSchool_detail_id(subscriptionData.school_detail_id)
        setEndDate(subscriptionData.end_date)
        setStartDateDefault(subscriptionData.start_date)
        setAmount(subscriptionData.amount)
    }, [subscriptionShow, subscriptionData.start_date]);

    const dateHandler = (date) => {
        setStartDate(new Date(date.setDate(date.getDate())));
        setEndDate(new Date(date.setDate(date.getDate() + duration)))
    }

    const amountHandler = (e) => {
        setAmount(e.target.value)
    }

    const dataSubmit = (event) => {
        if (amount === null) {
            setAmountError(true)
        } else {
            const payload = {
                school_detail_id: school_detail_id,
                start_date: startDate === "" ? startDateDefault : moment(startDate).format("YYYY-MM-DD"),
                end_date: moment(endDate).format("YYYY-MM-DD"),
                type: "subscription",
                amount: amount,
                // subscription_plan: duration/365
            }
            UpdateSchoolSubscription(event, payload)
            setAmountError()
        }

    }
    
    return (
        <Modal
            backdrop={true}
            keyboard={false}
            className="confirm_delete_modal confirm_modal"
            show={modalOpen}
            aria-labelledby="md-modal"
            onHide={(event) => toggleSubscriptionModal()}
        >
            <Modal.Header>
                <Modal.Title>Start Subscripition</Modal.Title>
                <span className="pointer" onClick={(event) => toggleSubscriptionModal()}>
                    <i className="fas fa-times me-2 fs-6" ></i>
                </span>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <label className="fw-bold mb-2">Select Subscription Plan</label>
                        <Select
                            type="select"
                            value={yearOption.filter(option => option.value === duration)}
                            options={yearOption}
                            onChange={(event) => setDuration(event.value)}
                        />
                    </div>
                    <div className="col-12 mt-4">
                        <label className="fw-bold mb-2">Start Date</label>
                        <div className="">
                            <ReactDatePicker
                                showIcon
                                dateFormat="yyyy/MM/dd"
                                wrapperClassName="trial_datePicker_superadmin"
                                className="form-control"
                                selected={startDate}
                                onChange={(date) => dateHandler(date)}
                            />
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <label className="fw-bold mb-2">End Date *</label>
                        <input className="form-control"
                            value={moment(endDate).format('YYYY/MM/DD')}
                            readOnly
                        />
                    </div>
                    {
                        <div className="col-12 mt-4">
                            <label className="fw-bold mb-2">Amount *</label>
                            <input className="form-control amount_subscription"
                                type="number"
                                value={amount}
                                onChange={(e) => amountHandler(e)}
                            />
                            {console.log("dddddddddddddd", amountError)}
                            {amountError ? <div class="error field-error">This field is required!</div> : ""}
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-secondary apply-btn min-75px me-4" onClick={(event) => toggleSubscriptionModal()}>Cancel</button>
                <button className="btn btn-sm btn-primary apply-btn min-75px" onClick={() => dataSubmit()}>Ok</button>
            </Modal.Footer>
        </Modal>
    );
};

export default StartSubscriptionModal;

