import React, { useState } from 'react'
import parse from 'html-react-parser';
import Select from 'react-select';
import { Box, Pagination, Stack } from '@mui/material';

function SchoolActivities({ logData, count, setSearch, page, setPage, limit, setLimit }) {
    const row_limit = [
        { value: 10, label: 10 },
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
        { value: 250, label: 250 },
        { value: 500, label: 500 }
    ]

    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-fluid" style={{ padding: "0px" }}>
                    <div className="school-edit-form">
                        <div className="" id="Snapshot">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="card tabs-outer">
                                            {/* <div className="card-header position-relative py-0 details-bg">
                                                <h2 className="card-title  m-0">
                                                    Activities
                                                </h2>
                                            </div> */}
                                            <div className="card-body position-relative">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="d-flex justify-content-between align-items-center pb-5 px-2 border-bottom border-1 border-secondary">
                                                            <div className="card-toolbar">
                                                                <div className="d-flex align-items-center justify-content-between export-out">
                                                                    <div className="d-flex align-items-center position-relative">
                                                                        <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                <path
                                                                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                    fill="currentColor"
                                                                                ></path>
                                                                            </svg>
                                                                        </span>
                                                                        <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }}
                                                                            onChange={(e) => { setSearch(e.target.value); setPage(1) }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <span className="text-secondary"><strong>Date & Time</strong></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        {logData.length>0 ? logData.map((item) => {
                                                            console.log(item, "::item345678")
                                                            return (
                                                                <div className="row py-3 px-2 border-bottom">
                                                                    <div className="col-9">
                                                                        <div className="d-flex justify-content-start align-items-center">
                                                                            <div className="me-3">
                                                                                {/* <img
                                                                                    alt="/assets/media/school-logo.png"
                                                                                    src={item.thumbnail_image === "" || item.thumbnail_image === "https://bssnew.dikonia.in/" ? "/assets/media/school-logo.png" : item.thumbnail_image}
                                                                                    className="rounded-circle" style={{ width: "30px" }}
                                                                                /> */}
                                                                            </div>
                                                                            <div>
                                                                                {parse(item.html_info)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 text-end">
                                                                        <div className="d-flex justify-content-end align-items-center">
                                                                            <span className="bg-light-info px-2 py-1 rounded-3 text-secondary">{item.created_date}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) :
                                                            <Box sx={{ textAlign: "center", mt: 2, color: "gray" }}>No Data Found!</Box>
                                                        }
                                                    </div>
                                                </div>

                                                {
                                                    logData.length>0 &&
                                                    <div className="d-flex justify-content-between mt-4">
                                                        <Select
                                                            menuPlacement="top"
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            defaultValue={row_limit[0]}
                                                            name="color"
                                                            options={row_limit}
                                                            onChange={(event) => setLimit(event.value)}
                                                        />
                                                        <Stack spacing={1} >
                                                            <Pagination
                                                                count={Math.ceil(count / limit)}
                                                                page={page}
                                                                onChange={(event, value) => setPage(value)}
                                                                color="primary"
                                                            />
                                                        </Stack>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SchoolActivities