import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import Notification from "../../Constant/Notification";
import Sidebar from "../../Constant/sidebar";
import Cdate from "../../Constant/date";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import Menu from "../Menus";
import * as userActions from "../../../actions/index";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SnapshotItem from "./SnapshotItem";
import { socket } from '../../../constants/actionTypes';
import BlinkingDot from "./BlinkingDot";
import SnapshotPastoralCommentModal from "../../Utility/SnapshotPastoralCommentModal";
import SnapshotMedicalCommentModal from "../../Utility/SnapshotMedicalCommentModal";
import ReturnApprovalModal from "../../Utility/ReturnApprovalModalBYSnapshot";
import { el } from "date-fns/locale";
import debounce from 'lodash/debounce';
import moment from "moment";
import { Link } from "react-router-dom";
import { updateBooleanValue } from "../../../reducers/Snapshot";
import { Check } from "@mui/icons-material";
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
    valueContainer: (base) => ({
        ...base,
        maxHeight: 60,
        overflowY: "auto"
    }),
};
var oncampus = [];

var student_Data = [];

const current = new Date();

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const schoolName = user_info?.user_school?.actual_school_code
var student_ids = '';

const Snapshot = (props) => {
    const dispatch = useDispatch();
    const [both_per, setBoth_per] = useState(true)
    const [male_per, setMale_per] = useState(true)
    const [female_per, setFemale_per] = useState(true)


    const [filterlimit, setfilterlimit] = useState("");
    const [filterpage, setfilterpage] = useState(1);
    const [filterorder] = useState("asc");
    const [filtersort] = useState("student_first_name");
    const [StudentList, setStudentList] = useState([]);
    const [ReasonData, setReasonData] = useState([]);
    const [on_campus_location, seton_campus_location] = useState([]);
    const [dormitory_id, setdormitory_id] = useState([]);
    const [dormitory_ids, setdormitory_ids] = useState("");
    const [dormitory_name, setdormitory_name] = useState("");
    const [class_id, setclass_id] = useState([]);
    const [class_ids, setclass_ids] = useState("");
    const [class_name, setclass_name] = useState("");
    const [class_value, setclass_value] = useState([]);
    const [class_values, setclass_values] = useState([]);
    const [reason_ids, setreason_ids] = useState('');
    const [multiAPI, setMultiAPI] = useState(false)
    const [student_count, setStudent_Count] = useState("")
    const [search, setSearch] = useState("");
    const [gender, setgender] = useState(""
        // both_per === true && male_per === true && female_per === true ? "":(both_per === false && female_per === true ? "female":(both_per === false && male_per === true? "male":null))
    );
    const [StudentListcount, setStudentListcount] = useState(0);

    const [location_status, setlocation_status] = useState(
        // [
        // { label: "Select Student Display", value: "" },
        // { label: "Present", value: "present" },
        // { label: "OnCampus", value: "oncampus" },
        // { label: "Known Location", value: "KnownLocation" },
        //     // { label: "AdHoc", value: "AdHoc" },
        //     // { label: "AdHoc Late Return", value: "AdHoc LateReturn" },
        //     // { label: "Leave", value: "Leave" },
        //     // { label: "Leave Late Return", value: "Leave Late Return" },
        //     { label: "MIA", value: "MIA" },
        // ]
    );
    const [is_student_activate, setis_student_activate] = useState();

    const [oncampus_ids, setOncampus_ids] = useState([]);
    const [reason_id, setreason_id] = useState();

    const [callNotification, setCallNotification] = useState(0);
    const [socketcalled, setSocketcalled] = useState();
    const [reason_Name, setReason_Name] = useState("");


    const [showBlink, setShowBlink] = useState(true)
    const [compareFilter, setCompareFilter] = useState([
        { label: "All", value: "" },
        { label: "Student with amber light", value: "true" },
        { label: "Student without amber light", value: "false" },
    ]);
    // const [selectedCompare, setSelectedCompare] = useState()
    const [rollcall_compare_status, setRollcall_compare_status] = useState("")
    const [is_compare_rollcall_required, setis_compare_rollcall_required] = useState(
        JSON.parse(localStorage.getItem("Comparision")) ? JSON.parse(localStorage.getItem("Comparision")) : ''
    )

    // const [loc, setLoc] = useState('/dashboard');
    const [checkedAll, setCheckedAll] = useState(false);
    const [selectlocation, setSelectLocation] = useState([]);
    const [allLocation, setAllLocation] = useState();
    const [final, setFinal] = useState([])

    const [pastoralComment, setPastoralComment] = useState('')
    const [medicalComment, setMedicalComment] = useState('')
    const [add_pastrol_comment_modal, setadd_pastrol_comment_modal] = useState(false);
    const [add_medical_comment_modal, setadd_medical_comment_modal] = useState(false)

    const [student_ids_for_commnet, setStudent_ids_for_comment] = useState([])
    const [genderSetCall, setGenderCall] = useState(false)

    const [return_approval_modal, setReturn_Approval_modal] = useState(false)
    const [boarerLeaveData, setBoarderLeaveData] = useState({})

    const [statusDefaultCount, setStatusDefaultCount] = useState()
    const [eventData, setEventData] = useState(null);

    const navigate = useNavigate();

    // console.log("allLocation", allLocation)

    const navigateToSnapshot = () => {
        navigate(`/${school_code}/conduct-rollcall`, { replace: true });
    }

    useEffect(() => {
        if (male_per === true && female_per === true && both_per === true) {
            setgender("")
        } else if (male_per === true && female_per === false && both_per === false) {
            setgender("male")
        } else if (male_per === false && female_per === true && both_per === false) {
            setgender("female")
        }
        setGenderCall(true)
    }, [male_per, female_per, both_per])

    useEffect(() => {
        const local_dormitory = JSON.parse(localStorage.getItem('dormitory_for_snapshot'))
        const local_classes = JSON.parse(localStorage.getItem('classes_for_snapshot'))
        if (!local_dormitory) {
            setdormitory_ids(local_dormitory)
        }
        if (!local_classes) {
            setclass_ids(local_classes)
        }

        console.log("local_storage", local_dormitory, local_classes);
    }, [])
    useEffect(() => {
        let permissions;
        if (JSON.parse(localStorage.getItem("permissions")) === null) {
        } else {
            permissions = JSON.parse(localStorage.getItem("permissions") ? localStorage.getItem("permissions") : "")
            if (permissions.snapshot_gender_both) {
                setMale_per(true)
                setFemale_per(true)
                setBoth_per(true)
            } else {
                setMale_per(permissions.snapshot_gender_male)
                setFemale_per(permissions.snapshot_gender_female)
                setBoth_per(permissions.snapshot_gender_both)
            }
        }
    }, [])


    const AddPastrolpoup = (event) => {
        setadd_pastrol_comment_modal(true)
        let pastoralCommentArray = [];
        let student_ids = [];
        StudentList.forEach((item) => {
            if (item.checked === true) {
                pastoralCommentArray.push(item.student_first_name + " " + item.student_last_name)
                student_ids.push(item.student_id)
            }
        })
        setStudent_ids_for_comment(student_ids)
        setPastoralComment(pastoralCommentArray)
    }

    const AddMedicalpoup = () => {
        setadd_medical_comment_modal(true)
        let medicalCommentArray = [];
        let student_ids = [];
        StudentList.forEach((item) => {
            if (item.checked === true) {
                medicalCommentArray.push(item.student_first_name + " " + item.student_last_name)
                student_ids.push(item.student_id)
            }
        })
        setStudent_ids_for_comment(student_ids)
        setMedicalComment(medicalCommentArray)
    }



    const showAddModalHandlePastrolComment = async (event) => {
        setadd_pastrol_comment_modal(false)
    }
    const showAddModalHandleMedicalComment = async (event) => {
        setadd_medical_comment_modal(false)
    }
    const CreateDiaryPastoralComments = async (form_data) => {
        if (JSON.parse(form_data.student_ids).length === 0) {
            toast.error(" Please add a student name to save Pastrol Comments", {
                position: toast.POSITION.TOP_CENTER,
            });
        } else {
            props.onCreateDiaryPastoralComments(form_data).then((response) => {
                if (response.success === true) {
                    GetAllSnapshotStudents()
                    setadd_pastrol_comment_modal(false)
                    setfilterpage(1);
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }
    }
    const CreateDiaryMedicalComments = async (form_data) => {
        props.onCreateDiaryMedicalComment(form_data).then((response) => {
            if (response.success === true) {
                GetAllSnapshotStudents()
                setadd_medical_comment_modal(false)
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }


    const GetEventHandler = event => {
        event.currentTarget.classList.toggle('bg-salmon');
        console.log("event", event);
        var search_by_days = event.target.attributes.search_by_days.value;
        var dormitory_name = event.target.attributes.dormitory_name.value;
        var student_uuid = event.target.attributes.student_uuid.value;

        props.onExportStudentLocationHistoryPdf(student_uuid, dormitory_name, search_by_days).then((response) => {
            if (response.success === true) {
                const csv_url = response.file;
                const link = document.createElement("a");
                link.href = csv_url;
                link.target = "_blank";
                // link.setAttribute('download', 'manager.pdf');
                document.body.appendChild(link);
                link.click();

                // Clean up and remove the link
                // link.parentNode.removeChild(link);

                // GetAllCourses();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };

    useEffect(() => {
        const Socket_Toast = JSON.parse(localStorage.getItem("Socket_calls"));
        setSocketcalled(Socket_Toast)
    })

    const handleBlink = (e) => {
        // console.log("status change", e)
        // console.log("e.target.value", e.target)
        let student_ids;
        // if (e.target) {
        //     student_ids = e?.target?.id;
        // } else {
        student_ids = e
        // }
        const Payload = {
            student_id: student_ids
        }

        props.onUpdateSnapshotRollCallCompareStatus(Payload).then((response) => {
            if (response.success === true) {
                GetAllSnapshotStudents();
                setfilterpage(1);

                // toast.success(response.message, {
                //     position: toast.POSITION.TOP_CENTER,
                // });

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });

    }



    //////////////////// dormitory and class change handler //////////////////

   

    // }
    // const setDataAndSubmit = () => {
    //     const payload = {
    //         check_in_out: status === true ? "out" : "in",
    //         adhoc_leave_uuid: leaveUuid
    //     }
    //     props.UpdateAdhocLeaveSignInOrSignOu(payload)
    // }


    const UpdateAdhocLeaveSignInOrSignOut = async(leave_data) => {
        await props.onUpdateAdhocLeaveSignInOrSignOut(leave_data).then((response) => {
            if (response.success === true) {
                // setConfirm_modal(false)
                // GetAllAdhocLeaveStudentDetails()

                // toast.success(response.message, {
                //     position: toast.POSITION.TOP_CENTER,
                // });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        })
    }

    const ChangeStatus = async(e) => {
        dispatch(updateBooleanValue(true))
        // console.log("11-2222--event---ChangeStatus", e.target.attributes);
        // console.log("11-2222--event---ChangeStatus", e.target.attributes.reason.value);
        let student;
        let student_ids = e.target.id;
        student_Data.map(item => {
            let studentID = Math.floor(student_ids)
            if (studentID === item.student_id) {
                console.log("Student list item", item)
                student = item;
            }
        })
        let adhocFilter = student?.stu_adhoc_data.filter((data) => data?.check_in_out === 'out');
        if(adhocFilter && adhocFilter.length >0 ){
            const payloadData = {
                check_in_out: "in",
                adhoc_leave_uuid: adhocFilter[0].adhoc_leave_uuid

            }
            await UpdateAdhocLeaveSignInOrSignOut(payloadData)
        }
console.log(e.target.attributes.campus,"::dfdfdfdf")
        let on_campus_location_ids = e.target.attributes.campus ? e.target.attributes.campus.value : "";
        let reason_ids = e.target.attributes.reason ? e.target.attributes.reason.value : "";
        let is_loc_changes = reason_ids ? (student.current_loc_data.reason_id === Math.floor(reason_ids) ? false : true) : null;
        let stu_current_loc = is_loc_changes ? (is_loc_changes === true && reason_ids === "1" ? "MIA" : "") : "";
        let dormitory = student.dormitory_data.dormitory_name;
        let stu_first_name = student?.student_first_name
        let stu_last_name = student?.student_last_name;
        // console.log("Is location change check", is_loc_changes);
        console.log("location name check", stu_last_name);

        let Payload = {
            student_id: student_ids,
            on_campus_location_id: on_campus_location_ids,
            reason_id: reason_ids,
            is_loc_changed: is_loc_changes,
            stu_current_loc_name: stu_current_loc,
            dormitory_name: dormitory,
            student_first_name: stu_first_name,
            student_last_name: stu_last_name
        };
        console.log("Payload", Payload);

        // if(reason_ids === "1") {
        //     setCallNotification(callNotification + 1)
        // }

        // NotifyMIA(); 

        // sortMIAstudent();
        await props.onSignInStudentReasonOrOncampus(Payload).then((response) => {
            if (response.success === true) {

                handleBlink(Payload.student_id)
                GetAllSnapshotStudents();
                setfilterpage(1);
                if (reason_ids === "1") {
                    setCallNotification(callNotification + 1)
                }
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };

    const ChangeStatusPresent = (event) => {

        console.log("-22-1111----------ChangeStatusPresent", event);
        let student_ids = event.target.id;
        let stu_reason_oncampus_uuid = event.target.attributes.stu_reason_oncampus_uuid ? event.target.attributes.stu_reason_oncampus_uuid.value : "";
        // let reason_ids = event.target.attributes.reason ? event.target.attributes.reason.value : "";
        let student;
        student_Data.map(item => {
            let studentID = Math.floor(student_ids)
            if (studentID === item.student_id) {
                console.log("Student list item", item)
                student = item;
            }
        })
        let adhocFilter = student?.stu_adhoc_data.filter((data) => data?.check_in_out === 'out');

        if(adhocFilter && adhocFilter.length >0 ){
            const payloadData = {
                check_in_out: "in",
                adhoc_leave_uuid: adhocFilter[0].adhoc_leave_uuid

            }
            UpdateAdhocLeaveSignInOrSignOut(payloadData)
        }
        let Payload = {
            student_id: student_ids,
            stu_reason_oncampus_uuid: stu_reason_oncampus_uuid,
        };
        console.log("Payload", Payload);
        // sortMIAstudent();
        props.onSignOutStudentReasonOrOncampus(Payload).then((response) => {
            if (response.success === true) {
                handleBlink(Payload.student_id)
                GetAllSnapshotStudents();
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };




    const GetAllSnapshotStudents = useCallback(async () => {
        dispatch(userActions.GetAllSnapshotStudents(filtersort, filterorder, filterpage, filterlimit, search, dormitory_ids, class_ids, gender, location_status, oncampus_ids, reason_id, rollcall_compare_status, is_compare_rollcall_required)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                const campusData = response.data.oncampus_location_data;
                const reasonArrData = response.data.reason_data;
                let reasons = [];
                let reason_level = []

                /////////////////////    Select Attendance Status  ///////////////////////

                reasons.push({
                    label: 'Select Status',
                    value: '',
                    reason_Id: '',
                })
                for (let key in reasonArrData) {
                    reasons.push({
                        label: reasonArrData[key].reason_name === "SL" ? "Leave" : reasonArrData[key].reason_name === "ad-hoc" ? "AdHoc" : reasonArrData[key].reason_name,
                        value: reasonArrData[key].reason_id,
                        reason_Id: reasonArrData[key].reason_id,
                    })
                }
                reasons.push({
                    label: 'Present',
                    value: 'present',
                    reason_Id: 'present',
                })

                /////////////////////  all student change status dropdown  ///////////////////////

                for (let key in reasonArrData) {
                    if (reasonArrData[key].reason_id !== 4 && reasonArrData[key].reason_id !== 2 && reasonArrData[key].reason_id !== 5) {
                        reason_level.push({
                            label: reasonArrData[key].reason_name === "SL" ? "Leave" : reasonArrData[key].reason_name === "ad-hoc" ? "AdHoc" : reasonArrData[key].reason_name,
                            value: JSON.parse(key),
                            reason_Id: reasonArrData[key].reason_id,
                            on_campus_location_id: "",
                            signInOut: 1
                        })
                    }
                }
                for (let key in campusData) {
                    reason_level.push({
                        label: campusData[key].location_name + " (On Campus)",
                        reason_Id: "",
                        on_campus_location_id: campusData[key].on_campus_location_id,
                        signInOut: 1,
                        value: key
                    })
                }
                reason_level.push({
                    label: 'Present',
                    on_campus_location_id: '',
                    reason_Id: '',
                    signInOut: 2,
                    value: reason_level.length + 1
                })

                setSelectLocation(reason_level)


                student_Data = arrayData;
                if (student_Data.length !== 0) {
                    // var res = student_Data.sort((a, b) => a?.current_loc_data?.reason_id === 1 ? -1 : 1)
                    student_Data.forEach((item, index) => {
                        const filter_adhoc_data = item.stu_adhoc_data.filter((leave) => {
                            if (moment(item?.stu_adhoc_data[0]?.arrival_date).format("YYYY/MM/DD") === moment(leave.arrival_date).format("YYYY/MM/DD")) {
                                return leave
                            }
                        })
                        student_Data[index].stu_adhoc_data = filter_adhoc_data;
                        student_Data[index].checked = false;
                    })
                    setStudentList(student_Data)

                } else if (student_Data.length === 0) {
                    setStudentList(arrayData)
                }
                setReasonData(reasons);
                seton_campus_location(campusData);
                setStudentListcount(response.data.count);
                setStatusDefaultCount(response.StuDefaultCount)
            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate('/logout')

                }, 5000);
            }
            else {
            }
        });
    }, [dispatch, filtersort, filterorder, search, filterpage, filterlimit, dormitory_ids, class_ids, gender, location_status, oncampus_ids, reason_id, rollcall_compare_status, is_compare_rollcall_required]);

    const debouncedGetAllSnapshotStudents = debounce(GetAllSnapshotStudents, 500);

    const toggleChecked = (event) => {
        console.log("::event",event.target.value)
        let total_selected = []
        let std_id = event.target.attributes.stu_id.value;
        if (event.target.value === "false") {
            let listcheckbox = []
            student_Data.forEach(item => {
                console.log(std_id , item.student_id,"::itedsdmitem")
                if (std_id == item.student_id) {
                    listcheckbox.push({ ...item, checked: item?.current_loc_data?.current_loc_reason?.reason_name === "ad-hoc" || item?.current_loc_data?.current_loc_reason?.reason_name === "SL" ? false : true })
                } else {
                    listcheckbox.push(item)
                }
            })

            listcheckbox.forEach((item) => {
                if (item.checked === true && 
                    item?.current_loc_data?.current_loc_reason?.reason_name !== "ad-hoc" &&
                    item?.current_loc_data?.current_loc_reason?.reason_name !== "SL") {
                        total_selected.push(item.student_id);
                }
            });

            // listcheckbox.forEach((item) => {
            //     if (item.checked === true) {
            //         total_selected.push(item.student_id)
            //     }
            // })
            setCheckedAll(total_selected.length === listcheckbox.length ? true : false)
            setStudent_Count(total_selected)
            student_Data = listcheckbox;
            setStudentList(listcheckbox)
            console.log(listcheckbox,"::DFlistcheckbox")
        } else {
            let listcheckbox = []
            student_Data.forEach(item => {
                console.log(std_id , item.student_id,":p:item")
                if (std_id == item.student_id) {
                    listcheckbox.push({ ...item, checked: item?.current_loc_data?.current_loc_reason?.reason_name === "ad-hoc" || item?.current_loc_data?.current_loc_reason?.reason_name === "SL" ? true : false })
                } else {
                    listcheckbox.push(item)
                }
            })
            
            listcheckbox.forEach((item) => {
                if (item.checked === true && 
                    item?.current_loc_data?.current_loc_reason?.reason_name !== "ad-hoc" &&
                    item?.current_loc_data?.current_loc_reason?.reason_name !== "SL") {
                        total_selected.push(item.student_id);
                }
            });
            // listcheckbox.filter((item) => {
            //     if (item.checked !== false) {
            //         total_selected.push(item.student_id)
            //     }
            // })
            setCheckedAll(total_selected.length === listcheckbox.length ? true : false)
            setStudent_Count(total_selected)
            student_Data = listcheckbox;
            setStudentList(listcheckbox)
            console.log(listcheckbox,"::dadfdfdfdtdat")

        }
    }

    const handleAllChecked = (event) => {
        console.log("::dadfdfdfdtdat",event.target.checked)
        setCheckedAll(event.target.checked);
        if (checkedAll === false) {
            // let listcheckbox = StudentList.map(item => {
            //     return { ...item, checked: true }
            // })
            
            let listcheckbox = student_Data.map(item => {
                return {
                    ...item,
                    checked: item?.current_loc_data?.current_loc_reason?.reason_name === "ad-hoc" || item?.current_loc_data?.current_loc_reason?.reason_name === "SL" ? false : true
                };
            });
            
              console.log(listcheckbox,"::listcheckboxlistcheckbox")
            setStudentList(listcheckbox)
            student_Data = listcheckbox;
            setStudent_Count(listcheckbox)
        } else {
            // let listcheckbox = StudentList.map(item => {
            //     return { ...item, checked: false }
            // })
            let listcheckbox = student_Data.map(item => {
                return {
                    ...item,
                    checked: item?.current_loc_data?.current_loc_reason?.reason_name === "ad-hoc" || item?.current_loc_data?.current_loc_reason?.reason_name === "SL" ? true : false
                };
            });
            setStudentList(listcheckbox)
            student_Data = listcheckbox;
            setStudent_Count([])
            console.log(listcheckbox,"::dadfdfdfdtdat")
        }
    }

    const statusSelectHandle = (event) => {
        setEventData(event);
    }

    useEffect(() => {
        if (!eventData) return; // Return if eventData is null
        setReason_Name(eventData.label)
        // setAllLocation(eventData)
        // setReason_Ids(eventData.value);
        var reason_ids = eventData.reason_Id;
        const reasonName = eventData.label;
        var campus_location_id = eventData.on_campus_location_id;
        var signInOut = eventData.signInOut;
        let attendance = [];
        let listcheckbox = [];
        student_Data.map(item => {
            console.log('::ditem', item)

            var stu_reason_oncampus_uuid = item.campus_or_reasons_data[0] ? item.campus_or_reasons_data[0].stu_reason_oncampus_uuid : '';
            // var sign_in_out_status = item.campus_or_reasons_data[0] ? item.campus_or_reasons_data[0].sign_in_out_status : '';
            var stu_ids = item.student_id
            var stu_first_name = item.student_first_name
            var stu_last_name = item.student_last_name
            var dormitory = item.dormitory_data.dormitory_name
            student_ids = stu_ids
            var old_reason = item.reason_check == null ? '' : item.reason_check;
            var old_reason = item?.current_loc_data?.reason_id === 4 ? "OC" :
                item?.current_loc_data?.reason_id !== null ?
                    item?.current_loc_data?.current_loc_reason?.reason_name :
                    item?.current_loc_data?.current_present_status === true ? "Present" :
                        item?.current_loc_data?.current_loc_oncampus !== null && item?.current_loc_data?.current_loc_oncampus.location_name == "Ground" ? "Ground (On Campus)" : item?.current_loc_data?.current_loc_oncampus.location_name == "Class" ? "Class (On Campus)" :item?.current_loc_data?.current_loc_oncampus.location_name == "Hospital" ? "Hospital (On Campus)" : "";
            // var stu_reason_oncampus_id = item.stu_reason_oncampus_id === "" ? null : item.stu_reason_oncampus_id;
            if (item.checked == true && (item?.current_loc_data?.current_loc_reason?.reason_name != "SL" && item?.current_loc_data?.current_loc_reason?.reason_name != "ad-hoc")) {
                if (reasonName != old_reason) {
                    return (
                        attendance.push(
                            {
                                stu_reason_oncampus_uuid: stu_reason_oncampus_uuid,
                                sign_in_out_status: signInOut,
                                student_id: student_ids,
                                reason_id: reason_ids == '' ? '' : parseInt(reason_ids),
                                on_campus_location_id: campus_location_id,
                                is_loc_changed: true,
                                stu_current_loc_name: (old_reason !== reasonName && reason_ids === 1 ? "MIA" : ""),
                                student_first_name: stu_first_name,
                                student_last_name: stu_last_name,
                                dormitory_name: dormitory,

                            })

                    )
                }
            }
            //   return item; 
        }

        )
        // console.log("attendance @@@@@@@@@@", attendance)

        // setStudentList(listcheckbox)
        // student_Data = listcheckbox;
        setFinal(attendance);
    },[student_Data, StudentList, eventData])

   

    // console.log("final", final)
    // console.log("studentList", StudentList)

    const submitHandler = (e) => {
        const unique = [...new Map(final.map((m) => [m.student_id, m])).values()];
        const snapshot_data = JSON.stringify(unique);
        if (final != '') {
            const Payload = {
                snapshotData: snapshot_data,
            };
            console.log("Payload", Payload)
            props.onSnapshotSignInOrSignOutStudents(Payload).then((response) => {
                if (response.success === true) {
                    setStudent_Count([])
                    GetAllSnapshotStudents();
                    setfilterpage(1);
                    if (reason_Name === "MIA") {
                        setCallNotification(callNotification + 1)
                    }
                    setCheckedAll(false)
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });

                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        } else {
            toast.error("Please try a different location as you are choosing the already selected location", {
                position: toast.POSITION.TOP_CENTER,
            });
            setStudent_Count([])
            GetAllSnapshotStudents();
        }
    };

    //     console.log("reasonName", reason_Name)
    // console.log("checkedall", checkedAll)

    const GetAllDormitoryListForSnapshot = useCallback(() => {
        dispatch(userActions.GetAllDormitoryStudent()).then((response) => {
            const local_dormitory = JSON.parse(localStorage.getItem('dormitory_for_snapshot'))
            if (response.success === true) {
                let state_data = [];
                state_data.push({
                    label: "Select Dormitory",
                    value: "",
                });
                const arrayData = response.data.rows;
                if (permission_info) {
                    const permClassDormArray = permission_info?.perm_class_dorm;
                    const filteredPermDorm = permClassDormArray.filter(item => item?.dormitory_id !== null)
                    const allowedDormIds = filteredPermDorm.map(item => item?.dormitory_id);
                    const filteredData = arrayData.filter(item => allowedDormIds.includes(item.dormitory_id));
                    for (let key in filteredData) {
                        state_data.push({
                            label: filteredData[key].dormitory_name,
                            value: filteredData[key].dormitory_id,
                            dormitory_id: filteredData[key].dormitory_id,
                        });
                    }

                    let defaultdormitory = filteredData.map((item) => {
                        return item.dormitory_id
                    })
                    setdormitory_ids(JSON.stringify(defaultdormitory))
                } else {
                    for (let key in arrayData) {
                        state_data.push({
                            label: arrayData[key].dormitory_name,
                            value: arrayData[key].dormitory_id,
                            dormitory_id: arrayData[key].dormitory_id,
                        });
                    }
                    let defaultdormitory = arrayData.map((item) => {
                        return item.dormitory_id
                    })
                    local_dormitory === null ? setdormitory_ids(JSON.stringify(defaultdormitory)) : setdormitory_ids(local_dormitory);
                }
                setdormitory_id(state_data);
            } else {
                //notify.show(response.message, "error");
            }
        });
    }, [dispatch]);
    const GetAllClassesListForSnapshot = useCallback(() => {
        dispatch(userActions.GetAllClassesListForSnapshot()).then((response) => {
            const local_classes = JSON.parse(localStorage.getItem('classes_for_snapshot'))
            console.log("local_classes", local_classes);
            if (response.success === true) {
                let state_data = [];
                state_data.push({
                    label: "Select Year",
                    value: "",
                });
                const arrayData = response.data;

                if (permission_info) {
                    const permClassDormArray = permission_info?.perm_class_dorm;
                    const filteredPermDorm = permClassDormArray?.filter(item => item?.class_id !== null)
                    const allowedDormIds = filteredPermDorm?.map(item => item?.class_id);
                    const filteredData = arrayData?.filter(item => allowedDormIds?.includes(item?.class_id));
                    for (let key in filteredData) {
                        state_data.push({
                            label: filteredData[key].class_name,
                            value: filteredData[key].class_id,
                            class_id: filteredData[key].class_id,
                        });
                    }

                    let defaultdormitory = filteredData?.map((item) => {
                        return item?.class_id
                    })
                    setclass_ids(JSON.stringify(defaultdormitory))
                } else {
                    for (let key in arrayData) {
                        state_data.push({
                            label: arrayData[key].class_name,
                            value: arrayData[key].class_id,
                            class_id: arrayData[key].class_id,
                        });
                    }
                    let defaultYear = arrayData.map((item) => {
                        return item.class_id
                    })
                    local_classes === null ? setclass_ids(JSON.stringify(defaultYear)) : setclass_ids(local_classes)
                }
                setclass_id(state_data);
            } else { }
        });
    }, [dispatch]);

    useEffect(() => {
        GetAllClassesListForSnapshot();
        GetAllDormitoryListForSnapshot();
        setMultiAPI(true)
    }, [])

    useEffect(() => {
        if (dormitory_id.length > 0 && class_id.length > 0) {
            if (socketcalled !== undefined && genderSetCall === true) {
                console.log("socketcalled============inner",);
                GetAllSnapshotStudents()
                // debouncedGetAllSnapshotStudents()
            }
        }
    }, [GetAllSnapshotStudents, socketcalled, search, filterpage, filterlimit, filtersort, filterorder, dormitory_ids, class_ids, gender, location_status, oncampus_ids, reason_id, is_compare_rollcall_required]);

    // useEffect(() => {
    //     if(socketcalled){
    //         GetAllSnapshotStudents()
    //     }
    // }, [socketcalled ])

    const GetLoginUserPermissions = useCallback(async () => {
        dispatch(userActions.GetLoginUserPermissions()).then((response) => {
            if (response.success === true) {
            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch]);
    useEffect(() => {
        GetLoginUserPermissions();
    }, []);


    const handleReasonFilter = (event) => {
        console.log("event", event);
        setStudent_Count([])
        setCheckedAll(false)
        let onCampusID = on_campus_location.map(item => { return item.on_campus_location_id })
        setreason_ids(event.value);
        let reas_id = event.value
        if (reas_id == 1) {
            setreason_id(reas_id)
            setlocation_status("mia")
        } else if (reas_id == 2) {
            setreason_id(reas_id)
            setlocation_status("sickleave")
        } else if (reas_id == 5) {
            setreason_id(reas_id)
            setlocation_status("AdHoc")
        }
        else if (reas_id == 3) {
            setreason_id(reas_id)
            setlocation_status("knownlocation")
        } else if (reas_id == 4) {
            setreason_id(reas_id)
            setlocation_status("oncampus")
            setOncampus_ids(JSON.stringify(onCampusID))
        } else if (reas_id == 'present') {
            setlocation_status('present')
        } else {
            setreason_id()
            setlocation_status()
            setOncampus_ids()
        }
        if (event.value === "") {
            if (class_name === "" && dormitory_name === "" && rollcall_compare_status === "" && gender === "") {
                setfilterlimit(12)
                setfilterpage(1)

            }
        } else {
            setfilterlimit()
            setfilterpage()
        }
    }

    const Dormitory_and_class = (event, identifire) => {
        setStudent_Count([])
        setCheckedAll(false)
        if (identifire === "year") {
            console.log("identifire========", event);
            let setYear = [];
            if (event.length > 0) {
                let check_empty_value = event.find((event) => event.value === "")
                if (check_empty_value !== undefined) {
                    class_id.forEach((item) => {
                        if (item.value !== "") {
                            setYear.push(item.value)
                        }
                    })
                    setclass_ids(JSON.stringify(setYear))
                } else {
                    event.forEach((item) => {
                        if (item.value !== "") {
                            setYear.push(item.value)
                        }
                    })
                    setclass_ids(JSON.stringify(setYear))
                }
            } else {
                class_id.forEach((item) => {
                    if (item.value !== "") {
                        setYear.push(item.value)
                    }
                })
                setclass_ids(JSON.stringify(setYear))
            }

            // if (dormitory_name === "" && reason_ids === "" && rollcall_compare_status === "" && gender === "") {
            //     setfilterlimit(12)
            //     setfilterpage(1)
            // }
            // else {
            //     setclass_name(event.value)
            //     setclass_ids(JSON.stringify([event.value]))
            //     setfilterlimit()
            //     setfilterpage()
            // }
        }
        if (identifire === "dormitory") {
            let setDorm = [];
            if (event.length > 0) {
                let check_empty_value = event.find((event) => event.value === "")
                if (check_empty_value !== undefined) {
                    console.log("identifire========", check_empty_value);
                    dormitory_id.forEach((item) => {
                        if (item.value !== "") {
                            setDorm.push(item.value)
                        }
                    })
                    setdormitory_ids(JSON.stringify(setDorm))
                } else {
                    event.forEach((item) => {
                        if (item.value !== "") {
                            setDorm.push(item.value)
                        }
                    })
                    setdormitory_ids(JSON.stringify(setDorm))
                }
            }else{
                dormitory_id.forEach((item) => {
                    if (item.value !== "") {
                        setDorm.push(item.value)
                    }
                })
                setdormitory_ids(JSON.stringify(setDorm))
            }

            // if (class_name === "" && reason_ids === "" && rollcall_compare_status === "" && gender === "") {
            //     setfilterlimit(12)
            //     setfilterpage(1)
            // }
            // else {
            //     setdormitory_name(event.value)
            //     setdormitory_ids(JSON.stringify([event.value]));
            //     setfilterlimit()
            //     setfilterpage()
            // }
        }
    }


    const rolcallStatusHandler = (event) => {
        setStudent_Count([])
        setCheckedAll(false)
        setRollcall_compare_status(event.value)
        if (event.value === "") {
            if (class_name === "" && dormitory_name === "" && reason_ids === "" && gender === "") {
                setfilterlimit(12)
                setfilterpage(1)
            }
        } else {
            setfilterlimit()
            setfilterpage()
        }
    }

    const genderHandler = (event) => {
        setStudent_Count([])
        setCheckedAll(false)
        setgender(event.target.value)
        if (event.target.value === "") {
            if (class_name === "" && dormitory_name === "" && reason_ids === "" && rollcall_compare_status === "") {
                setfilterlimit(12)
                setfilterpage(1)
            }
        } else {
            setfilterlimit()
            setfilterpage()
        }
    }


    const filterLimitHandler = (filterlimit) => {
        setfilterlimit(filterlimit);
    }


    const SnapshotReturnStuLeave = (leave_data) => {
        props.onSnapshotReturnStuLeave(leave_data).then((response) => {
            if (response.success === true) {
                GetAllSnapshotStudents()
                setReturn_Approval_modal(false)
                dispatch(updateBooleanValue(true))
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        })
    }

    const hideReturnStudentModal = (value) => {
        setReturn_Approval_modal(value)
    }

    const showReturnApprovalModal = (value, data) => {
        setReturn_Approval_modal(value)
        setBoarderLeaveData(data)
    }

    const default_for_class = () => {
        const arr = [];
        console.log("default_for_class", class_id,class_ids);
        class_id.forEach((option) => {
            if (JSON.parse(class_ids).includes(option.value)) {
                arr.push(option);
            }
        });
        return arr;
    }

    const default_for_dormitory = () => {
        const arr = []
        dormitory_id.forEach((option) => {
            if (JSON.parse(dormitory_ids).includes(option.value)) {
                arr.push(option);
            }
        });
        return arr;
    }

    return (
        <>
            {add_pastrol_comment_modal &&
                <SnapshotPastoralCommentModal
                    student_ids={student_ids_for_commnet}
                    StudentList={StudentList}
                    pastoralComment={pastoralComment}
                    add_pastrol_comment_modal={add_pastrol_comment_modal}
                    CreateDiaryPastoralComments={(event) => CreateDiaryPastoralComments(event)}
                    add_pastrol_comment_modal_action={(modal_action) => showAddModalHandlePastrolComment(modal_action)}

                />
            }
            {add_medical_comment_modal &&
                <SnapshotMedicalCommentModal
                    student_ids={student_ids_for_commnet}
                    StudentList={StudentList}
                    medicalComment={medicalComment}
                    add_medical_comment_modal={add_medical_comment_modal}
                    CreateDiaryMedicalComments={(event) => CreateDiaryMedicalComments(event)}
                    add_medical_comment_modal_action={(modal_action) => showAddModalHandleMedicalComment(modal_action)}

                />
            }
            {return_approval_modal &&
                <ReturnApprovalModal
                    return_approval_modal={return_approval_modal}
                    boarerLeaveData={boarerLeaveData}
                    SnapshotReturnStuLeave={(leave_data) => SnapshotReturnStuLeave(leave_data)}
                    hideReturnStudentModal={(value) => hideReturnStudentModal(value)}
                />
            }
            <div className="d-flex flex-column flex-root header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed h-100">
                <div className="page d-flex flex-row flex-column-fluid">
                    <Sidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <TopBar callNotification={callNotification} />
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="post d-flex flex-column-fluid" id="kt_post">

                                <div id="kt_content_container" className="container-fluid">

                                    <ul className="nav nav-tabs home-tabs">
                                        <li className="nav-item me-6" >
                                            <Link to={`/${school_code}/snapshot`} className="nav-link active" type="button" aria-controls="process" aria-selected="true">
                                                Snapshot
                                            </Link>
                                        </li>
                                        <li className="nav-item me-6" >
                                            <Link to={`/${school_code}/rollcall-report`} className="nav-link" type="button" aria-controls="process" aria-selected="false"    >
                                                Roll Call Reports
                                            </Link>
                                        </li>
                                        <li className="nav-item me-6" >
                                            <Link to={`/${school_code}/conduct-rollcall`} className="nav-link" type="button" aria-controls="process" aria-selected="false">
                                                Conduct Roll Call
                                            </Link>
                                        </li>
                                    </ul>



                                    <div className="tab-content" id="myTabContent">
                                        <div className="card">
                                            <div className="card-header position-relative py-0 details-bg ">
                                                <h2 className="card-title text-gray-800  m-0">
                                                    Student Details <span className="d-none d-lg-block bullet h-20px w-1px bg-black mx-4"></span>
                                                    <span className="date-block">
                                                        <Cdate></Cdate>
                                                    </span>
                                                </h2>
                                            </div>
                                            <form className="make-strong">
                                                <div className="card-body px-6">
                                                    <div className="row">


                                                        <div className="col">
                                                            <label className="fw-bold mb-2 ">Select Attendance Status</label>
                                                            <Select
                                                                styles={customStyles}
                                                                className="react-bootstrap-typeahead tag-outer "
                                                                options={ReasonData}
                                                                value={ReasonData.filter(function (option) {
                                                                    return option.value === reason_ids;
                                                                })}
                                                                onChange={(event) => {
                                                                    handleReasonFilter(event)
                                                                }}
                                                            />
                                                        </div>

                                                        <div className='col'>
                                                            <div className='form-group'>
                                                                <label className='fw-bold mb-3'>Student Name</label>
                                                                <div className="position-relative">
                                                                    <span className="search-icon-block">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M19 19L13.0001 13M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                    </svg>
                                                                    </span>
                                                                    <input type="search" placeholder="Search..." className="form-control pe-10" value={search} onChange={(e) => setSearch(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col">
                                                            <label className="fw-bold mb-2 ">Student Year</label>
                                                            <Select
                                                                styles={customStyles}
                                                                className="react-bootstrap-typeahead tag-outer text-secondary"
                                                                options={class_id}
                                                                value={default_for_class()}
                                                                // value={class_id.filter(function (option) {
                                                                //     return option.value === class_name;
                                                                // })}
                                                                onChange={(event) => {
                                                                    Dormitory_and_class(event, "year")
                                                                }}
                                                                isMulti
                                                            />
                                                        </div>

                                                        <div className="col ">
                                                            <label className="fw-bold mb-2 ">Select Dormitory</label>
                                                            <Select
                                                                styles={customStyles}
                                                                className="react-bootstrap-typeahead tag-outer text-secondary"
                                                                value={default_for_dormitory()}
                                                                options={dormitory_id}
                                                                // value={dormitory_id.filter(function (option) {
                                                                //     return option.value === dormitory_name;
                                                                // })}
                                                                onChange={(event) => {
                                                                    Dormitory_and_class(event, "dormitory")
                                                                }}
                                                                isMulti
                                                            />

                                                        </div>

                                                        <div className="col">
                                                            <label className="fw-bold mb-2 ">Select Option</label>
                                                            <Select
                                                                styles={customStyles}
                                                                className="react-bootstrap-typeahead tag-outer "
                                                                options={compareFilter}
                                                                value={compareFilter.filter((option) => {
                                                                    return option.value === rollcall_compare_status
                                                                })}
                                                                onChange={(event) => {
                                                                    rolcallStatusHandler(event)
                                                                }}
                                                            />

                                                        </div>

                                                        <div className="col">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <label className="fw-bold mb-5">Gender</label>
                                                                    <div className="d-flex radio-btn">
                                                                        <label className="cursor-pointer d-flex align-items-center">
                                                                            <input
                                                                                type="radio"
                                                                                id="gender"
                                                                                name="gen"
                                                                                className="form-check-input me-2 cursor-pointer"
                                                                                value="male"
                                                                                onChange={(event) => {
                                                                                    genderHandler(event)
                                                                                }}
                                                                                checked={gender === 'male' ? "checked" : null}
                                                                                defaultChecked={female_per === false && male_per === true && both_per === false ? true : false}
                                                                                disabled={!male_per}
                                                                            />
                                                                            Male
                                                                        </label>

                                                                        <label className="cursor-pointer d-flex align-items-center">
                                                                            <input
                                                                                type="radio"
                                                                                id="gender1"
                                                                                name="gen"
                                                                                className="form-check-input me-2 cursor-pointer"
                                                                                value="female"
                                                                                checked={gender === 'female' ? "checked" : null}
                                                                                onChange={(event) => {
                                                                                    genderHandler(event)
                                                                                }}
                                                                                defaultChecked={female_per === true && male_per === false && both_per === false ? true : false}
                                                                                disabled={!female_per}
                                                                            />
                                                                            Female
                                                                        </label>

                                                                        <label className="cursor-pointer d-flex align-items-center">
                                                                            <input
                                                                                type="radio"
                                                                                id="gender2"
                                                                                name="gen"
                                                                                className="form-check-input me-2 cursor-pointer"
                                                                                value=""
                                                                                checked={gender === '' ? "checked" : null}
                                                                                onChange={(event) => {
                                                                                    genderHandler(event)
                                                                                }}
                                                                                defaultChecked={male_per === true && female_per === true && both_per === true ? true : false}
                                                                                disabled={!both_per}
                                                                            />
                                                                            Both
                                                                        </label>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>



                                        <div className="card mt-5">
                                            <div className="card-header align-items-center">

                                                <div className="d-flex justify-content-start align-items-center flex-wrap">
                                                    <h2 className="card-title text-gray-800  m-0">Select All</h2>
                                                    {/* Select All & Selection status  */}
                                                    <input className="form-check-input widget-9-check m-5 align-middle"
                                                        type="checkbox"
                                                        onChange={(event) => handleAllChecked(event)}
                                                        checked={checkedAll}
                                                    />
                                                    {console.log("options=======", selectlocation)}
                                                    <div className=" align-middle m-5 ms-0 w-260px">
                                                        <Select
                                                            placeholder="Change Selection to…"
                                                            styles={customStyles}
                                                            className="react-bootstrap-typeahead tag-outer "
                                                            options={selectlocation}
                                                            // value={reason_Name}
                                                            // value={selectlocation.filter(function (option) {
                                                            //     return option.label === reason_Name; })} 
                                                            onChange={(event) => statusSelectHandle(event)}
                                                        />
                                                    </div>

                                                    <div>
                                                        <button type="button" className="btn btn-outline-primary btn-sm me-5" onClick={(event) => {
                                                            AddMedicalpoup(event);
                                                        }} >
                                                            <span className="svg-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect fill="#3693d0" x="4" y="11" width="16" height="2" rx="1" />
                                                                    <rect fill="#3693d0" opacity="1" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1" />
                                                                </g>
                                                            </svg></span>Medical Comments
                                                        </button>
                                                        <button type="button" className="btn btn-outline-primary btn-sm" onClick={(event) => {
                                                            AddPastrolpoup(event);
                                                        }} >
                                                            <span className="svg-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect fill="#3693d0" x="4" y="11" width="16" height="2" rx="1" />
                                                                    <rect fill="#3693d0" opacity="1" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1" />
                                                                </g>
                                                            </svg></span> Pastoral Comments
                                                        </button>
                                                    </div>

                                                </div>

                                                <div className="student-count-wrap bg-white d-flex justify-content-end align-items-center font-medium text-center justify-content-center  d-flex align-items-center border border-dashed border-gray-300 rounded  px-4 py-2 gap-2  ">
                                                    <h5 className="text-gray-700 mb-0">Total Selected : {" "} </h5>
                                                    <h3 className="text-gray-700 mb-0">
                                                        {student_count.length}
                                                    </h3>
                                                </div>
                                            </div>



                                            <div className="card-body">
                                                <SnapshotItem
                                                    StudentListcount={StudentListcount}
                                                    data={StudentList}
                                                    GetEventHandler={(event) => GetEventHandler(event)}
                                                    // ChangeStatus={(event) => ChangeStatus(event)}
                                                    ChangeStatusPresent={(event) => ChangeStatusPresent(event)}
                                                    navigateToSnapshot={navigateToSnapshot}
                                                    ChangeStatus={(e) => ChangeStatus(e)}
                                                    ReasonData={ReasonData}
                                                    on_campus_location={on_campus_location}
                                                    filterlimit={filterlimit}
                                                    filterpage={filterpage}
                                                    propsfilterpage={(event) => setfilterpage(event)}
                                                    handleBlink={(e) => handleBlink(e)}
                                                    propsfilterlimit={(filterlimit) => {
                                                        filterLimitHandler(filterlimit)
                                                        // setfilterlimit(filterlimit);
                                                    }}
                                                    toggleChecked={toggleChecked}
                                                    reason_Name={reason_Name}
                                                    filteredData={gender}
                                                    statusDefaultCount={statusDefaultCount}
                                                    showReturnApprovalModal={(value, leave_data) => showReturnApprovalModal(value, leave_data)}
                                                />
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <div className="d-flex align-items-end my-5">
                                                        <button type="submit"
                                                            className="btn btn-sm btn-primary apply-btn min-150px addschool-button"
                                                            onClick={() => {
                                                                submitHandler();
                                                                setStudent_Count([])
                                                            }}
                                                            disabled={student_count.length > 0 && reason_Name !="" ? false : true}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>




                                            </div>
                                        </div>


                                        {/* <!--end::Container--> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {

    onExportStudentsCsv: userActions.ExportStudentsCsv,
    onUpdateStudentStatus: userActions.UpdateStudentStatus,
    onExportStudentsPdf: userActions.ExportStudentsPdf,
    onCreateStudentsConductRollCall: userActions.CreateStudentsConductRollCall,
    onExportStudentLocationHistoryPdf: userActions.ExportStudentLocationHistoryPdf,
    onSignInStudentReasonOrOncampus: userActions.SignInStudentReasonOrOncampus,
    onSignOutStudentReasonOrOncampus: userActions.SignOutStudentReasonOrOncampus,
    onUpdateSnapshotRollCallCompareStatus: userActions.UpdateSnapshotRollCallCompareStatus,
    onSnapshotSignInOrSignOutStudents: userActions.SnapshotSignInOrSignOutStudents,
    onCreateDiaryPastoralComments: userActions.CreateDiaryPastoralComments,
    onCreateDiaryMedicalComment: userActions.CreateDiaryMedicalComment,
    onSnapshotReturnStuLeave: userActions.SnapshotReturnStuLeave,
    onUpdateAdhocLeaveSignInOrSignOut: userActions.UpdateAdhocLeaveSignInOrSignOut,


};
export default connect(mapStateToProps, mapDispatchToProps)(Snapshot);