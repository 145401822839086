import React, { useEffect, useState } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";
import { updateBooleanValue } from "../../../reducers/Snapshot";
import { useDispatch } from "react-redux";

const DepartureApprovalModal = (props) => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false);
  const [leaveData, setLeaveData] = useState({})
  useEffect(() => {
    setShowModal(props.showDepartStudent)
    setLeaveData(props.archiveLeaveData)
  }, [props])
  

  let departure_date = new Date(props.archiveLeaveData.departure_students_data.planned_depart_date)
  const closeModal = () => {
    props.hideDepartStudentModal(false);
  };

  const handleSubmit = () => {
    const form_data = {
      border_leave_id: leaveData.border_leave_id,
      depart_status: "departed",
      border_leave_depart_stu_id: leaveData.departure_students_data.border_leave_depart_stu_id,
      student_id: leaveData.student_id
    }
    if(new Date() >= departure_date){
      props.DepartStuLeaveChange(form_data);
    }else{
      props.hideDepartStudentModal(false);
      dispatch(updateBooleanValue(true))
      toast.error("Your leave is not approved for this departure date", {
        position: toast.POSITION.TOP_CENTER,
    });
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Departure Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you wish to sign this student out now?</Modal.Body>
        <Modal.Footer>
          <Button className="btn-sm" variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button className="btn-sm px-10" variant="primary" onClick={handleSubmit} disabled={props.isLoading}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DepartureApprovalModal;