import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "../../Constant/sidebar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import DeleteConfirmRemove from "../../Utility/DeleteConfirmRemove";
import { Link } from "react-router-dom";
// import StudentSearch from "../StudentSearch";
import Chart from "react-google-charts";
import Dropdown from "react-bootstrap/Dropdown";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as userActions from "../../../actions/index";
import StudentItem from "./StudentItem";
import Select from "react-select";
import { CustomDeleteModal1, CustomDeleteModal2 } from "../../Utility/DeleteStudentModal";
import AddArchiveModal from "./Modal/AddArchiveModal";
import AddUnarchivedModal from "./Modal/AddUnarchivedModal";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

const permission_info = JSON.parse(localStorage.getItem("permissions"));

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
const StudentList = (props) => {

    const dispatch = useDispatch();
    const [male, setmale] = useState("");
    const [total, settotal] = useState("");
    const [female, setfemale] = useState("");
    const [filterlimit, setfilterlimit] = useState(50);
    const [filterpage, setfilterpage] = useState(1);
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersort, setfiltersort] = useState("student_first_name");
    const [filtersearch, setfiltersearch] = useState(null);
    const [StudentList, setStudentList] = useState([]);
    const [StudentListcount, setStudentListcount] = useState(0);
    const [is_student_activate, setis_student_activate] = useState(true);
    const [student_ages, setstudent_ages] = useState("");
    const [student_age] = useState([
        { label: "Select Age", value: "" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
        { label: "22", value: "22" },
        { label: "23", value: "23" },
        { label: "24", value: "24" },
        { label: "25", value: "25" },
    ]);
    const [dormitory_id, setdormitory_id] = useState([]);
    const [dormitory_ids, setdormitory_ids] = useState("");
    const [class_id, setclass_id] = useState([]);
    const [class_ids, setclass_ids] = useState("");
    const [gender, setgender] = useState('');
    const [current_local_time] = useState("date");

    const [confirm_modal, setconfirm_modal] = useState(false);
    const [editconfirmremovedata, seteditconfirmremovedata] = useState([]);


    const [showModal, setShowModal] = useState(false);
    const [showSecondModal, setShowSecondModal] = useState(false);
    const [confirmRemoveData, setConfirmRemoveData] = useState([]);
    const [userName, setUserName] = useState("")
    const [user_uuid, setuser_uuid] = useState("");
    const [userActivate, setUserActivate] = useState(false)
    const [add_unarchived_modal, setadd_unarchived_modal] = useState(false);
    const [add_archive_modal, setadd_archive_modal] = useState(false);

    const navigate = useNavigate();
    const permission_info = JSON.parse(localStorage.getItem("permissions"));

    // event, student_uuid, value, student_name 
    const AddArchivepoup = async (event, student_uuid, value, student_name) => {
        let student_uuids = student_uuid;
        setUserName(student_name)
        setuser_uuid(student_uuids)
        setUserActivate(value)
        localStorage.setItem('archive_data', student_uuids);
        setadd_archive_modal(true)
    };

    const AddUnarchivepoup = async (event, student_uuid, value, student_name) => {
        let student_uuids = student_uuid;
        setUserName(student_name)
        setuser_uuid(student_uuids)
        setUserActivate(value)
        localStorage.setItem('archive_data', student_uuids);
        setadd_unarchived_modal(true)
    }

    const showAddModalHandleCountry = async (event) => {
        setadd_archive_modal(false)
        setadd_unarchived_modal(false)
        localStorage.removeItem("archive_data");
    }

    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };
    const RemoveCourseModule = (event, course_data) => {
        seteditconfirmremovedata(course_data);
        setconfirm_modal(true);
    };

    const toggleModalOne = (event, course_data) => {
        setShowModal(!showModal)
        setConfirmRemoveData(course_data)
    };

    const toggleModalTwo = (e) => {
        setShowSecondModal(!showSecondModal)
    };

    const openTwoAndCloseOne = (e) => {
        setShowSecondModal(!showSecondModal)
        if (showModal) { setShowModal(false) };
    };

    const DeleteStudent = async () => {
        const payload = {
            student_id: confirmRemoveData.student_id,
            parent_id: confirmRemoveData.parent_id  !== null ? confirmRemoveData.parent_id : "",
            student_name:confirmRemoveData.student_first_name + " " +confirmRemoveData.student_last_name
        }
        const form_data = { duplicate_student_data: JSON.stringify([payload]) }
        props.onDeleteStudent(form_data).then((response) => {
            if (response.success === true) {
                setShowModal(false)
                setShowSecondModal(false)
                GetAllStudents();
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }







    const GetLoginUserPermissions = useCallback(async () => {
        dispatch(userActions.GetLoginUserPermissions()).then((response) => {
            if (response.success === true) {
            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch]);
    useEffect(() => {
        GetLoginUserPermissions();
    }, []);

    const GetAllStudents = useCallback(async () => {
        dispatch(userActions.GetAllStudents(filterpage, filterlimit, filtersort, filterorder, is_student_activate, filtersearch, student_ages, dormitory_ids, class_ids, gender)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

                    MemberId_level.push({
                        perpage: perpage,
                        country_id: arrayData[key].country_id,
                        name: arrayData[key].name,

                    });
                }
                setStudentList(arrayData);
                setStudentListcount(response.data.count);
            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate('/logout')

                }, 5000);
            }
            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch, filterpage, filterlimit, filtersort, filterorder, is_student_activate, filtersearch, dormitory_ids, student_ages, class_ids, gender]);



    const GetAllStudentCountForChart = useCallback(async () => {
        dispatch(userActions.GetAllStudentCountForChart(is_student_activate, filtersearch, dormitory_ids, student_ages, class_ids, gender)).then((response) => {
            if (response.success === true) {
                console.log("Response ", response)
                if (response.data[0]?.gender === "female") {

                    var female = JSON.parse(response.data[0]?.count ? response.data[0]?.count : 0);
                    var male = JSON.parse(response.data[1]?.count ? response.data[1]?.count : 0);
                    var total = male + female;
                    setfemale(female);
                    setmale(male);
                    settotal(total);
                }

                else if (response.data[0]?.gender === "male") {

                    male = JSON.parse(response.data[0]?.count ? response.data[0]?.count : 0);
                    female = JSON.parse(response.data[1]?.count ? response.data[1]?.count : 0);
                    total = male + female;
                    setfemale(female);
                    setmale(male);
                    settotal(total);

                }
                else if (response.data[0]?.gender === "female" && response.data[1]?.gender === "male") {

                    male = JSON.parse(response.data[1]?.count ? response.data[1]?.count : 0);
                    female = JSON.parse(response.data[0]?.count ? response.data[0]?.count : 0);
                    total = male + female;
                    settotal(total);
                    setmale(male);
                    setfemale(female);
                }
                else {


                    male = 0;
                    female = 0;
                    total = male + female;
                    settotal(total);
                    setmale(male);
                    setfemale(female);
                }

            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate('/logout')

                }, 5000);
            }
            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch, is_student_activate, filtersearch, dormitory_ids, student_ages, class_ids, gender]);






    const GetAllClassesDetailsStudent = useCallback(() => {
        dispatch(userActions.GetAllClassesDetailsStudent()).then((response) => {
            if (response.success === true) {
                let state_data = [];
                state_data.push({
                    label: "Class",
                    value: "",
                });
                const arrayData = response.data.rows;
                if(permission_info){
                    const permClassDormArray = permission_info?.perm_class_dorm;
                    const filteredPermDorm = permClassDormArray?.filter(item => item?.class_id !== null)
                    const allowedDormIds = filteredPermDorm?.map(item => item?.class_id);
                    const filteredData = arrayData?.filter(item => allowedDormIds?.includes(item?.class_id));
                    for (let key in filteredData) {
                        state_data.push({
                            label: filteredData[key].class_name,
                            value: filteredData[key].class_id,
                            class_id: filteredData[key].class_id,
                        });
                    }
                }else{
                    for (let key in arrayData) {
                        state_data.push({
                            label: arrayData[key].class_name,
                            value: arrayData[key].class_id,
                            class_id: arrayData[key].class_id,
                        });
                    }
                }
                setclass_id(state_data);
            } else {

            }
        });
    }, [dispatch]);



    const GetAllDormitoryStudent = useCallback(() => {
        dispatch(userActions.GetAllDormitoryStudent()).then((response) => {
            if (response.success === true) {
                let state_data = [];
                state_data.push({
                    label: "Select Dormitory",
                    value: "",
                });
                const arrayData = response.data.rows;
                if(permission_info){
                    const permClassDormArray = permission_info?.perm_class_dorm;
                    const filteredPermDorm = permClassDormArray.filter(item => item?.dormitory_id !== null)
                    const allowedDormIds = filteredPermDorm.map(item => item?.dormitory_id);
                    const filteredData = arrayData.filter(item => allowedDormIds.includes(item.dormitory_id));
                    for (let key in filteredData) {
                        state_data.push({
                            label: filteredData[key].dormitory_name,
                            value: filteredData[key].dormitory_id,
                            dormitory_id: filteredData[key].dormitory_id,
                        });
                    }
                 }else{
                     for (let key in arrayData) {
                        state_data.push({
                            label: arrayData[key].dormitory_name,
                            value: arrayData[key].dormitory_id,
                            dormitory_id: arrayData[key].dormitory_id,
                        });
                    }
                 }
                setdormitory_id(state_data);
            } else {
                //notify.show(response.message, "error");
            }
        });
    }, [dispatch]);


    useEffect(() => {
        GetAllStudents();
        GetAllStudentCountForChart();
        GetAllClassesDetailsStudent();
        GetAllDormitoryStudent();
    }, [filterpage, filterlimit, filtersort, filterorder, is_student_activate, filtersearch, dormitory_ids, student_ages, class_ids, gender]);


    const handleChange = async (event, student_uuid, value, student_name ) => {
        console.log(value,"::test")
        const Payload = {
            student_uuid: student_uuid,
            is_student_activate: value,
            student_name:student_name
        };

        props.onUpdateStudentStatus(Payload).then((response) => {
            if (response.success === true) {
                GetAllStudents();
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });

    }

    const ArchiveOrUnarchiveManager = async (form_data) => {
        props.onUpdateStudentStatus(form_data).then((response) => {
            if (response.success === true) {
                setadd_archive_modal(false)
                setadd_unarchived_modal(false)
                localStorage.removeItem("archive_data");
                GetAllStudents()
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
    }

    // const handleDelete = async (student_id, parent_id) => {
    //     const confirmed = window.confirm("Are you sure you want to delete this student?")

    //     if (confirmed) {
    //         const userInput = window.prompt("All data related to this student will be deleted. You can Deactivate student instead. If you are sure please type 'Delete' in this box")

    //         if (userInput === 'Delete') {


    //         const Payload = {
    //             student_id: student_id,
    //             parent_id: parent_id,
    //         };


    //         props.onDeleteStudent(Payload).then((response) => {
    //             if (response.success === true) {
    //                 GetAllStudents();
    //                 setfilterpage(1);
    //                 toast.success(response.message, {
    //                     position: toast.POSITION.TOP_CENTER,
    //                 });
    //             } else {
    //                 toast.error(response.message, {
    //                     position: toast.POSITION.TOP_CENTER,
    //                 });
    //             }
    //         });
    //     }
    //     }

    // }


    const ExportStudentsCsv = (event) => {
        // const csv_url = "https://localhost:8006/assets/media/demos/Student_sample_sheet.xlsx";
        // DownloadCsv(csv_url);        
        props.onExportStudentsCsv(filterpage, filterlimit, filtersort, filterorder, is_student_activate, filtersearch, gender, dormitory_ids, class_ids, student_ages,).then((response) => {
            if (response.success === true) {
                const csv_url = response.file;
                DownloadCsv(csv_url);
                // GetAllCourses();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };
    const DownloadCsv = (csv_url) => {
        const link = document.createElement("a");
        link.href = csv_url;
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }
    const ExportStudentsPdf = (event) => {
        // const csv_url = "https://localhost:8006/assets/media/demos/Student_sample_sheet.xlsx";
        // DownloadCsv(csv_url);        
        props.onExportStudentsPdf(filterpage, filterlimit, filtersort, filterorder, is_student_activate, filtersearch, gender, dormitory_ids, class_ids, student_ages, current_local_time).then((response) => {
            if (response.success === true) {
                const csv_url = response.file;
                DownloadPdf(csv_url);
                // GetAllCourses();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };
    const DownloadPdf = (csv_url) => {
        const link = document.createElement("a");
        link.href = csv_url;
        link.target = "_blank";
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }
    return (
        <>
            {confirm_modal && (
                <DeleteConfirmRemove
                    title={`Delete Student?`}
                    bodytitle="Are you sure you want to delete this Student?"
                    editconfirmremovedata={editconfirmremovedata}
                    confirm_modal={confirm_modal}
                    // confirmButtonText="Delete"
                    // ConfirmRemove={(event) => ConfirmRemove(event)}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                />
            )}
            <CustomDeleteModal1
                modalState={showModal}
                toggleModalOne={toggleModalOne}
                confirmRemoveData={confirmRemoveData}
                openTwoAndCloseOne={openTwoAndCloseOne}
            />
            <CustomDeleteModal2
                modalState={showSecondModal}
                toggleModalTwo={toggleModalTwo}
                confirmRemoveData={confirmRemoveData}
                DeleteStudent={DeleteStudent}
            />

            {add_archive_modal &&
                <AddArchiveModal
                    userUuid={user_uuid}
                    userName={userName}
                    userActivate={userActivate}
                    add_archive_modal={add_archive_modal}
                    ArchiveOrUnarchiveManager={(event) => ArchiveOrUnarchiveManager(event)}
                    add_archive_modal_action={(modal_action) => showAddModalHandleCountry(modal_action)}
                />
            }
            {add_unarchived_modal &&
                <AddUnarchivedModal
                    userUuid={user_uuid}
                    userName={userName}
                    userActivate={userActivate}
                    add_unarchived_modal={add_unarchived_modal}
                    ArchiveOrUnarchiveManager={(event) => ArchiveOrUnarchiveManager(event)}
                    add_unarchive_modal_action={(modal_action) => showAddModalHandleCountry(modal_action)}
                />
            }

            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="school-edit-form">
                                            <div className="" id="Snapshot">
                                             
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="card  tabs-outer">
                                                                <div className="card-header position-relative py-0">
                                                                    <h2 className="card-title  m-0">
                                                                        Students Details
                                                                    </h2>
                                                                    <div className="card-toolbar">
                                                                        {permission_info?.create_tempid !== false &&
                                                                            (<Link to={`/${school_code}/student/student-list/temp-id`} className="clear-btn fs-6 pointer btn btn-link btn-color-info btn-active-color-primary">
                                                                                Generate Student Temp ID
                                                                            </Link>)}
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="card-toolbar pb-4 mb-5">
                                                                        <div className="d-flex align-items-center justify-content-between export-out">
                                                                            {/* <!-- <h4 className="card-title text-gray-800 fw-bolder m-0">Schools</h4> --> */}
                                                                            <div className="d-flex align-items-center position-relative">
                                                                                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                        <path
                                                                                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                            fill="currentColor"
                                                                                        ></path>
                                                                                    </svg>
                                                                                </span>

                                                                                <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }}
                                                                                    onChange={(event) => {
                                                                                        setfiltersearch(event.target.value);
                                                                                        setfilterpage(1);
                                                                                    }}

                                                                                />
                                                                            </div>
                                                                            {/* <div className="d-flex align-items-center">
                                                                                <div className="checkbox-inline me-4">
                                                                                    <label className="checkbox cursor-pointer ">
                                                                                        <input className="form-check-input me-1" type="checkbox" name="Checkboxes3" />
                                                                                        <span className="mb-0">Select All</span>
                                                                                    </label>
                                                                                </div>
                                                                                <span className="btn btn-outline btn-outline btn-sm btn-outline-danger btn-active-light-danger del-btn">Delete</span>
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <StudentItem

                                                                                StudentListcount={StudentListcount}
                                                                                data={StudentList}
                                                                                filterlimit={filterlimit}
                                                                                filterpage={filterpage}
                                                                                // RemoveCourseModule={(event, data) => toggleModalOne(event, data)}
                                                                                propsfilterpage={(event) => setfilterpage(event)}

                                                                                AddArchivepoup={(event, student_uuid, value, student_name) => AddArchivepoup(event, student_uuid, value, student_name)}
                                                                                AddUnarchivepoup={(event, student_uuid, value, student_name) => AddUnarchivepoup(event, student_uuid, value, student_name)}
                                                                                
                                                                                handleChange={(event, student_uuid, value, student_name) => handleChange(event, student_uuid, value , student_name)}
                                                                                handleDelete={(event, data) => toggleModalOne(event, data)}
                                                                                propsfilterlimit={(filterlimit) => {
                                                                                    setfilterlimit(filterlimit);
                                                                                }}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="card mb-5">
                                                                {/* <!--begin::Header--> */}
                                                                <div className="card-header border-bottom border-bottom-1">
                                                                    <h3 className="card-title align-items-start flex-column">
                                                                        <span className="card-label fw-bold text-dark">Filters</span>
                                                                    </h3>
                                                                </div>

                                                                <div className="card-body p-6">
                                                                    <div className="d-flex flex-column">
                                                                        <h5>Sort By</h5>
                                                                        <div className="d-flex">
                                                                            <button className=

                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (filtersort === "student_first_name" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    if (filterorder === 'desc') {
                                                                                        setfilterorder('asc');
                                                                                    }
                                                                                    else {
                                                                                        setfilterorder('desc');
                                                                                    }
                                                                                    setfiltersort('student_first_name');
                                                                                    setfilterpage(1);

                                                                                }}

                                                                            >Name</button>

                                                                            <button className=

                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (filtersort === "class_id" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    if (filterorder === 'desc') {
                                                                                        setfilterorder('asc');
                                                                                    }
                                                                                    else {
                                                                                        setfilterorder('desc');
                                                                                    }
                                                                                    setfiltersort('class_id');
                                                                                    setfilterpage(1);

                                                                                }}

                                                                            >Class</button>

                                                                            <button className=

                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (filtersort === "student_id" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    if (filterorder === 'desc') {
                                                                                        setfilterorder('asc');
                                                                                    }
                                                                                    else {
                                                                                        setfilterorder('desc');
                                                                                    }
                                                                                    setfiltersort('student_id');
                                                                                    setfilterpage(1);

                                                                                }}

                                                                            >Student Id</button>

                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-column mt-6">
                                                                        <h5>Show</h5>
                                                                        <div className="d-flex align-items-center">
                                                                            <span className=
                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (is_student_activate === "" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    setis_student_activate('');
                                                                                    setfilterpage(1);

                                                                                }}

                                                                            >All</span>
                                                                            <span className=

                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (is_student_activate === true ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    setis_student_activate(true);
                                                                                    setfilterpage(1);

                                                                                }}
                                                                            >Active</span>
                                                                            <span className=
                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (is_student_activate === false ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    setis_student_activate(false);
                                                                                    setfilterpage(1);

                                                                                }}

                                                                            >Archived</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <StudentSearch /> */}
                                                            <div className="card  tabs-outer">
                                                                <form id="search-form" >
                                                                    <div className="card-header border-bottom border-bottom-1 align-items-center">
                                                                        <h3 className="card-title align-items-start flex-column">
                                                                            <span className="card-label fw-bold text-dark">Student Search</span>
                                                                        </h3>
                                                                        <button type="reset" className="clear-btn fs-6 pointer btn btn-link btn-color-info btn-active-color-primary"
                                                                            onClick={(event) => {
                                                                                setclass_ids("");
                                                                                setdormitory_ids("");
                                                                                setgender("");
                                                                                setstudent_ages("");
                                                                            }} >Clear Search</button>
                                                                    </div>
                                                                    <div className="card-body add-new-schools p-6">
                                                                        <div className="row">
                                                                            <div className="col-lg-12 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Class</label>
                                                                                <Select
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={class_id}
                                                                                    value={class_id.filter(function (option) {
                                                                                        return option.value === class_ids;
                                                                                    })}
                                                                                    onChange={(event) => {
                                                                                        setclass_ids(event.value);
                                                                                    }}
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-12 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Select Dormitory</label>
                                                                                <Select
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={dormitory_id}
                                                                                    value={dormitory_id.filter(function (option) {
                                                                                        return option.value === dormitory_ids;

                                                                                    })}
                                                                                    onChange={(event) => {
                                                                                        setdormitory_ids(event.value);
                                                                                    }}
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-12 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Gender</label>
                                                                                <div className="d-flex">
                                                                                    <label className="cursor-pointer">
                                                                                        <input type="radio" id="gender" name="gen" className="form-check-input me-2 cursor-pointer" value="male" onChange={(event) => { setgender(event.target.value); }} />
                                                                                        Male
                                                                                    </label>

                                                                                    <label className="ms-8 cursor-pointer">
                                                                                        <input type="radio" id="gender1" name="gen" className="form-check-input me-2 cursor-pointer" value="female" onChange={(event) => { setgender(event.target.value); }} />
                                                                                        Female
                                                                                    </label>

                                                                                    <label className="ms-8 cursor-pointer">
                                                                                        <input type="radio" id="gender2" name="gen" className="form-check-input me-2 cursor-pointer" value="" onChange={(event) => { setgender(event.target.value); }} defaultChecked />
                                                                                        All
                                                                                    </label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-lg-12 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Select Age</label>
                                                                                <Select
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={student_age}
                                                                                    value={student_age.filter(function (option) {
                                                                                        return option.value === student_ages;
                                                                                    })}
                                                                                    onChange={(event) => {
                                                                                        setstudent_ages(event.value);

                                                                                    }}
                                                                                />
                                                                            </div>

                                                                            {/* <div className="col-sm-12">
                                                                                <div className="d-flex justify-content-end">
                                                                                    <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px student-search">
                                                                                        Search
                                                                                    </button>
                                                                                </div>
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            <div className="card mt-5">
                                                                <div className="card-header border-bottom border-bottom-1 align-items-center">
                                                                    <h3 className="card-title align-items-start flex-column">
                                                                        <span className="card-label fw-bold text-dark">Total Students  :  {total}  </span>
                                                                    </h3>
                                                                    <Dropdown className="drop-down-btn-align">
                                                                        <Dropdown.Toggle variant="mute" style={{ border: "19px" }}>
                                                                            <span
                                                                                className="toggle_button_arrow btn btn-color-gray-400 btn-active-color-primary justify-content-between d-flex p-2 export-btn-out "
                                                                                data-kt-menu-trigger="click"
                                                                                data-kt-menu-placement="bottom-end"
                                                                                data-kt-menu-overflow="true"
                                                                            >
                                                                                Export
                                                                                <span className="menu-arrow ms-2">
                                                                                    <i className="fas fa-angle-down"></i>
                                                                                </span>
                                                                            </span>
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item>
                                                                                <div className="menu-item px-3">
                                                                                    <span className="menu-link px-3" onClick={(event) => ExportStudentsPdf(event)} >
                                                                                        <i className="bi bi-file-earmark-pdf me-2"></i> Export PDF
                                                                                    </span>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                            <div className="separator my-2"></div>

                                                                            {/* <Dropdown.Item>
                                                                                                                                                    <div className="menu-item px-3">
                                                                                                                                                        <span className="menu-link px-3">
                                                                                                                                                            <i className="bi bi-file-earmark-excel me-2"></i> Export Excel
                                                                                                                                                        </span>
                                                                                                                                                    </div>
                                                                                                                                                </Dropdown.Item> */}

                                                                            <Dropdown.Item>
                                                                                <div className="menu-item px-3">
                                                                                    <span className="menu-link px-3" onClick={(event) => ExportStudentsCsv(event)}> <i className="bi bi-file-earmark-excel me-2"></i> Export CSV</span>

                                                                                </div>
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                                <div className="card-body text-center py-5">
                                                                    <Chart
                                                                        width={"100%"}
                                                                        height={"300px"}
                                                                        chartType="PieChart"
                                                                        loader={<div>Loading Pie Chart</div>}
                                                                        data={[
                                                                            ["class", "Student"],
                                                                            [`Male  ${male}`, male],
                                                                            [`Female  ${female}`, female],
                                                                        ]}
                                                                        options={{
                                                                            title: "",
                                                                            is3D: true,
                                                                        }}
                                                                    />
                                                                </div>


                                                            </div>








                                                        </div>



                                                    </div>
                                                
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <Footer />
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {

    onExportStudentsCsv: userActions.ExportStudentsCsv,
    onUpdateStudentStatus: userActions.UpdateStudentStatus,
    onExportStudentsPdf: userActions.ExportStudentsPdf,
    onDeleteStudent: userActions.DeleteStudent,

};
export default connect(mapStateToProps, mapDispatchToProps)(StudentList);