import React from "react";

import Validations from "../Utility/Validations";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import { Navigate } from "react-router-dom";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from "react-select";
import { toast } from "react-toastify";

import * as userActions from "../../actions/index";
import { connect } from "react-redux";
import AvatarImageCropper from 'react-avatar-image-cropper';
import { Tooltip } from "@mui/material";


const permission_info = JSON.parse(localStorage.getItem("permissions"));
// const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

class ManagerAdd extends React.Component {
    constructor(props) {
        super(props);
        window.addEventListener('keydown', this.handleKeyPress);
        this.state = {
            school_domain: "",
            OpenImageModal: false,
            isLoading: false,
            hiddenpassword: true,
            manager_add_form: {
                avatar: {
                    label: "Profile Image",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/school-logo.png",
                },
                first_name: {
                    type: "text",
                    label: "First Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                last_name: {
                    type: "text",
                    label: "Last Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                password: {
                    type: "password",
                    label: "Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8, password: true },
                },
                contact_one: {
                    type: "number",
                    label: "Contact Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Contact Number",
                    validations: { required: true },
                },
                email: {
                    type: "email",
                    label: "Email *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },
                designation: {
                    type: "text",
                    label: "Designation  *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Designation",
                    validations: { required: true },
                },
                comment: {
                    type: "text",
                    label: "Comment",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "comment",
                    validations: { required: false },
                },
                country_host: {
                    type: "hidden",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                school_code: {
                    type: "hidden",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                school_detail_id: {
                    type: "hidden",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                country_code: {
                    type: "hidden",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: false },
                },
                role_id: {
                    type: "hidden",
                    label: "Role ID  *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },

                permission_data: {
                    type: "hidden",
                    label: "Permission Data  *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },
                role_name: {
                    type: "hidden",
                    label: "Role name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },

                snapshot_gender_both: {
                    type: "radio",
                    label: "Gender",
                    value: true,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },
                snapshot_gender_female: {
                    type: "radio",
                    label: "Gender",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },
                snapshot_gender_male: {
                    type: "radio",
                    label: "Gender",
                    value: false,
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },
                dorm_data: {
                    type: "select",
                    label: "Select Dormitory *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                    options: [],
                },
                class_data: {
                    type: "select",
                    label: "Select Class *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                    options: [],
                },
            },
            dormitory_id: {
                type: "select",
                label: "Select Dormitory *",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
                options: [],
            },
            class_id: {
                type: "select",
                label: "Select Class *",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: false },
                options: [],
            },
            all_role: {
                type: "select",
                label: "Select Class *",
                value: "",
                valid: true,
                error_msg: "",
                onBlur_out: false,
                placeholder: "",
                validations: { required: true },
                options: [],
            }
        };
        this.toggleShow = this.toggleShow.bind(this);
    }

    toggleShow() {
        this.setState({ hiddenpassword: !this.state.hiddenpassword });
    }

    GetLoginUserPermissions() {
        this.props.onGetLoginUserPermissions().then((response) => {
            if (response.success === true) {
            } else {
            }
        });
    }


    GetRoleAndPermissionList = async (form_data) => {
        this.props.onGetRoleAndPermissionList().then((response) => {
            let country_level = [];
            const update_all_role = this.state.all_role;
            if (response.success === true) {
                const arrayData = response.data;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key]?.role_name,
                        value: arrayData[key]?.role_id,
                        permission_data: {
                            // activate_deactivate_host: arrayData[key]?.roll_perm.activate_deactivate_host,
                            // add_dorm_comment: arrayData[key]?.roll_perm.add_dorm_comment,
                            //bss_role_perm_class_dorms:arrayData[key]?.roll_perm.bss_role_perm_class_dorms
                            
                            //created_by:arrayData[key]?.roll_perm.created_by
                            //created_date:arrayData[key]?.roll_perm.created_date
                            
                            // delete_class: arrayData[key]?.roll_perm.delete_class,
                            // delete_students: arrayData[key]?.roll_perm.delete_students,
                            // deleted_by: arrayData[key]?.roll_perm.deleted_by,
                            // deleted_date: arrayData[key]?.roll_perm.deleted_date,
                            // dis_male_female_shadow_snapshot: arrayData[key]?.roll_perm.dis_male_female_shadow_snapshot,
                            // dis_stu_avatar_onsnapshot: arrayData[key]?.roll_perm.dis_stu_avatar_onsnapshot,
                            
                            
                            // parents_approval_host: arrayData[key]?.roll_perm.parents_approval_host,
                            
                            // require_stu_access_pin: arrayData[key]?.roll_perm.require_stu_access_pin,
                            // role_id: arrayData[key]?.roll_perm.role_id,
                            // role_permission_id: arrayData[key]?.roll_perm.role_permission_id,
                            // role_permission_uuid: arrayData[key]?.roll_perm.role_permission_uuid,
                            
                            // snapshot_gender_both: arrayData[key]?.roll_perm.snapshot_gender_both,
                            // snapshot_gender_female: arrayData[key]?.roll_perm.snapshot_gender_female,
                            // snapshot_gender_male: arrayData[key]?.roll_perm.snapshot_gender_male,
                            // student_gender_all: arrayData[key]?.roll_perm.student_gender_all,
                            // student_gender_female: arrayData[key]?.roll_perm.student_gender_female,
                            // student_gender_male: arrayData[key]?.roll_perm.student_gender_male,
                            // student_kiosk_mode: arrayData[key]?.roll_perm.student_kiosk_mode,
                            
                            // updated_by: arrayData[key]?.roll_perm.updated_by,
                            // updated_date: arrayData[key]?.roll_perm.updated_date,
                            
                            // view_dorm_comment: arrayData[key]?.roll_perm.view_dorm_comment,
                            add_permission: arrayData[key]?.roll_perm.add_permission,

                            view_weekend_leave: arrayData[key]?.roll_perm.view_weekend_leave,
                            add_weekend_leave: arrayData[key]?.roll_perm.add_weekend_leave,
                            edit_weekend_leave: arrayData[key]?.roll_perm.edit_weekend_leave,
                            
                            view_medical_comment: arrayData[key]?.roll_perm.view_medical_comment,
                            add_medical_comment: arrayData[key]?.roll_perm.add_medical_comment,
                            view_pastoral_comment: arrayData[key]?.roll_perm.view_pastoral_comment,
                            add_pastoral_comment: arrayData[key]?.roll_perm.add_pastoral_comment,
                            
                            view_host: arrayData[key]?.roll_perm.view_host,
                            add_host: arrayData[key]?.roll_perm.add_host,
                            edit_host: arrayData[key]?.roll_perm.edit_host,
                            
                            // Web Permission =================================
                            
                            add_class: arrayData[key]?.roll_perm.add_class,
                            edit_class: arrayData[key]?.roll_perm.edit_class,
                            view_class: arrayData[key]?.roll_perm.view_class,
                            activate_deactivate_class: arrayData[key]?.roll_perm.activate_deactivate_class,
                            
                            add_dormitory: arrayData[key]?.roll_perm.add_dormitory,
                            edit_dormitory: arrayData[key]?.roll_perm.edit_dormitory,
                            view_dormitory: arrayData[key]?.roll_perm.view_dormitory,
                            activate_deactivate_dormitory: arrayData[key]?.roll_perm.activate_deactivate_dormitory,
                            
                            view_school_profile: arrayData[key]?.roll_perm.view_school_profile,
                            edit_school_profile: arrayData[key]?.roll_perm.edit_school_profile,

                            add_user: arrayData[key]?.roll_perm.add_user,
                            edit_user: arrayData[key]?.roll_perm.edit_user,
                            view_user: arrayData[key]?.roll_perm.view_user,
                            activate_deactivate_user: arrayData[key]?.roll_perm.activate_deactivate_user,

                            edit_permission: arrayData[key]?.roll_perm.edit_permission,
                            view_permission: arrayData[key]?.roll_perm.view_permission,
                            add_role: arrayData[key]?.roll_perm.add_role,
                            edit_role: arrayData[key]?.roll_perm.edit_role,
                            add_duplicate_role: arrayData[key]?.roll_perm.add_duplicate_role,
                            deactivate_role: arrayData[key].roll_perm.deactivate_role,
                            delete_role: arrayData[key].roll_perm.delete_role,
                            
                            view_snapshot: arrayData[key]?.roll_perm.view_snapshot,
                            
                            view_activities: arrayData[key].roll_perm.view_activities,
                            
                            // Students Permissions =================================
                            view_student: arrayData[key]?.roll_perm.view_student,
                            activate_deactivate_student: arrayData[key]?.roll_perm.activate_deactivate_student,
                            edit_student: arrayData[key]?.roll_perm.edit_student,
                            create_tempid: arrayData[key]?.roll_perm.create_tempid,
                            delete_student: arrayData[key]?.roll_perm.delete_student,
                            
                            add_student: arrayData[key]?.roll_perm.add_student,
                            
                            view_allergic_students: arrayData[key]?.roll_perm.view_allergic_students,
                            add_allergic_students: arrayData[key]?.roll_perm.add_allergic_students,
                            edit_allergic_students: arrayData[key]?.roll_perm.edit_allergic_students,
                            delete_allergic_students: arrayData[key]?.roll_perm.delete_allergic_students,
                            
                            add_flag_students: arrayData[key]?.roll_perm.add_flag_students,
                            view_flag_students: arrayData[key]?.roll_perm.view_flag_students,
                            edit_flag_students: arrayData[key]?.roll_perm.edit_flag_students,
                            delete_flag_students: arrayData[key]?.roll_perm.delete_flag_students,
                            // deactivate_flag_students: arrayData[key]?.roll_perm.deactivate_flag_students,

                            add_grounded_students: arrayData[key]?.roll_perm.add_grounded_students,
                            view_grounded_students: arrayData[key]?.roll_perm.view_grounded_students,
                            edit_grounded_students: arrayData[key]?.roll_perm.edit_grounded_students,
                            delete_grounded_students: arrayData[key]?.roll_perm.delete_grounded_students,
                            // deactivate_grounded_students: arrayData[key]?.roll_perm.deactivate_grounded_students,
                            
                            view_import_students: arrayData[key]?.roll_perm.view_import_students,
                            
                            view_duplicate_students: arrayData[key]?.roll_perm.view_duplicate_students,

                            view_change_dormitory: arrayData[key]?.roll_perm.view_change_dormitory,
                            
                            // Setting Permissions =================================

                            view_attendance_report: arrayData[key]?.roll_perm.view_attendance_report,

                            edit_message: arrayData[key]?.roll_perm.edit_message,
                            view_message: arrayData[key]?.roll_perm.view_message,

                            view_custom_links: arrayData[key]?.roll_perm.view_custom_links,

                            view_rollover_students: arrayData[key]?.roll_perm.view_rollover_students,

                            view_rollcall_session_time: arrayData[key]?.roll_perm.view_rollcall_session_time,

                            view_oncampus_details: arrayData[key]?.roll_perm.view_oncampus_details,

                            view_calendar: arrayData[key]?.roll_perm.view_calendar,
                            add_calendar: arrayData[key]?.roll_perm.add_calendar,
                            edit_calendar: arrayData[key]?.roll_perm.edit_calendar,

                            // Leave Permissions =================================

                            view_boarder_leaves: arrayData[key]?.roll_perm.view_boarder_leaves,
                            add_boarder_leaves: arrayData[key]?.roll_perm.add_boarder_leaves,
                            edit_boarder_leaves: arrayData[key]?.roll_perm.edit_boarder_leaves,

                            view_adhoc_leave: arrayData[key]?.roll_perm.view_adhoc_leave,
                            add_adhoc_leave: arrayData[key]?.roll_perm.add_adhoc_leave,
                            edit_adhoc_leave: arrayData[key]?.roll_perm.edit_adhoc_leave,

                            view_reports: arrayData[key]?.roll_perm.view_reports,

                            view_travel_mode: arrayData[key]?.roll_perm.view_travel_mode,
                            add_travel_mode: arrayData[key]?.roll_perm.add_travel_mode,
                            edit_travel_mode: arrayData[key]?.roll_perm.edit_travel_mode,
                            delete_travel_mode: arrayData[key]?.roll_perm.delete_travel_mode,
                            activate_deactivate_travel_mode: arrayData[key]?.roll_perm.activate_deactivate_travel_mode,

                            view_rejection_reason: arrayData[key]?.roll_perm.view_rejection_reason,
                            add_rejection_reason: arrayData[key]?.roll_perm.add_rejection_reason,
                            edit_rejection_reason: arrayData[key]?.roll_perm.edit_rejection_reason,
                            delete_rejection_reason: arrayData[key]?.roll_perm.delete_rejection_reason,
                            activate_deactivate_rejection_reason: arrayData[key]?.roll_perm.activate_deactivate_rejection_reason,

                            // dorm_data: arrayData[key]?.roll_perm.bss_role_perm_class_dorms.filter(item => item.roll_perm_dorm)
                            //     .map(item => ({
                            //         dormitory_name: item.roll_perm_dorm.dormitory_name,
                            //         dormitory_id: item.roll_perm_dorm.dormitory_id
                            //     })
                            //     ),
                            // class_data: arrayData[key]?.roll_perm.bss_role_perm_class_dorms.filter(item => item.roll_perm_class)
                            //     .map(item => ({
                            //         class_name: item.roll_perm_class.class_name,
                            //         class_id: item.roll_perm_class.class_id
                            //     })
                            //     )
                        }
                    })
                }
                update_all_role.options = country_level;
                this.setState({ all_role: update_all_role });
            }
            else {
            }
        });
    }

    componentDidMount() {
        this.GetRoleAndPermissionList();
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        const startPoint = user_info.email.lastIndexOf("@")
        const email_extansion = user_info.email.slice(startPoint, user_info.email.length);
        const update_manager_add_form = { ...this.state.manager_add_form };
        update_manager_add_form['country_code'].value = user_info.user_school.country_code;
        this.setState({ school_domain: email_extansion })
        this.setState({ manager_add_form: update_manager_add_form });
        this.GetLoginUserPermissions();
        this.GetAllDormitoryStudent()
        this.GetAllClassesList()
    }


    inputChangeHandlerimage = blob => {
        const src = window.URL.createObjectURL(blob);
        let url = URL.createObjectURL(blob);
        let file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type })
        const update_manager_add_form = { ...this.state.manager_add_form };
        const form_element = { ...update_manager_add_form["avatar"] };
        form_element.thumbnail_image = url;
        form_element.value = file;
        update_manager_add_form["avatar"] = form_element;
        this.setState({ manager_add_form: update_manager_add_form });
        this.setState({ OpenImageModal: !this.state.OpenImageModal })
    };



    inputChangeHandler(event, identifier, value) {
        const regex = /^[a-zA-Z]*$/;
        const update_manager_add_form = { ...this.state.manager_add_form };
        const form_element = { ...update_manager_add_form[identifier] };
        if (identifier === 'contact_one') {
            form_element.value = event;
        } else if (identifier === "email") {
            if (event.nativeEvent.data !== "@") {
                form_element.value = event.target.value.toLowerCase()
            }
        } else if(identifier === "first_name") {
            const newValue = event.target.value;
            if (regex.test(newValue) || newValue === '') {
              form_element.value = newValue;
            }
        }else if(identifier === "last_name") {
            const newValue = event.target.value;
            form_element.value = newValue.replace(/[^a-zA-Z ]/g, '');
        }else{
            form_element.value = event.target.value;
        }

        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        update_manager_add_form['country_host'].value = user_info.country_host;
        update_manager_add_form['school_code'].value = user_info.school_code;
        update_manager_add_form['school_detail_id'].value = user_info.school_detail_id;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_manager_add_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_manager_add_form) {
            is_form_valid = update_manager_add_form[identifier].valid && is_form_valid;
        }
        this.setState({
            manager_add_form: update_manager_add_form,
            is_form_valid: is_form_valid,
        });
    }
    handleChangeRole(data, identifier) {
        let update_all_role = this.state.all_role
        update_all_role.value = data.value
        this.setState({ all_role: update_all_role })
        const update_manager_add_form = { ...this.state.manager_add_form };
        update_manager_add_form['role_id'].value = data.value;
        update_manager_add_form['role_name'].value = data.label;
        update_manager_add_form['permission_data'].value = data.permission_data;
        this.setState({ manager_add_form: update_manager_add_form })
    }

    GetAllDormitoryStudent = async (form_data) => {
        this.props.onGetAllDormitoryStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select All",
                value: "all"
            })
            const update_dorm_data = this.state.manager_add_form;
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].dormitory_status === true) {
                        country_level.push({
                            label: arrayData[key].dormitory_name,
                            value: arrayData[key].dormitory_id,
                        });
                    }
                }
            }
            update_dorm_data["dorm_data"].options = country_level;
            update_dorm_data["dorm_data"].value = country_level.filter(val => val.value !== "all");
            this.setState({ manager_add_form: update_dorm_data })
        });
    }
    GetAllClassesList = async (form_data) => {
        this.props.onGetAllClassesList().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select All",
                value: "all"
            })
            const update_class_data = this.state.manager_add_form;
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_class_activate === true) {
                        country_level.push({
                            label: arrayData[key].class_name,
                            value: arrayData[key].class_id,
                        });
                    }
                }
            }
            update_class_data["class_data"].options = country_level;
            update_class_data["class_data"].value = country_level.filter(val => val.value !== "all");
            this.setState({ manager_add_form: update_class_data })
        });
    }

    inputChangeHandlerSnapshot(event, identifier) {
        const update_manager_add_form = { ...this.state.manager_add_form };
        const form_element = { ...update_manager_add_form[identifier] };
        if (identifier === "snapshot_gender_female") {
            form_element.value = event.target.value === 'false';
            update_manager_add_form['snapshot_gender_both'].value = false;
            update_manager_add_form['snapshot_gender_male'].value = false;
        } else if (identifier === "snapshot_gender_male") {
            form_element.value = event.target.value === 'false';
            update_manager_add_form['snapshot_gender_both'].value = false;
            update_manager_add_form['snapshot_gender_female'].value = false;
        } if (identifier === "snapshot_gender_both") {
            form_element.value = event.target.value === 'false';
            update_manager_add_form['snapshot_gender_female'].value = false;
            update_manager_add_form['snapshot_gender_male'].value = false;
        }
        update_manager_add_form[identifier] = form_element;
        this.setState({ manager_add_form: update_manager_add_form });
    }

    handleChangeClass(data, identifier) {
        const update_manager_add_form = { ...this.state.manager_add_form }
        if (data.length === 0 || data[data.length - 1].value === "all") {
            update_manager_add_form[identifier].value = update_manager_add_form.class_data.options.filter(val => val.value !== "all")
        } else {
            update_manager_add_form[identifier].value = data;
        }
        this.setState({ manager_add_form: update_manager_add_form })
    }

    handleChangeDorm(data, identifier) {
        const update_manager_add_form = { ...this.state.manager_add_form }
        if (data.length === 0 || data[data.length - 1].value === "all") {
            update_manager_add_form[identifier].value = update_manager_add_form.dorm_data.options.filter(val => val.value !== "all")
        } else {
            update_manager_add_form[identifier].value = data;
        }
        this.setState({ manager_add_form: update_manager_add_form })
    }


    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_manager_add_form = this.state.manager_add_form;
        for (let key in update_manager_add_form) {
            if (key === 'email') {
                let form_element = update_manager_add_form[key];
                const old_value = form_element.value;
                const email_Obj = form_element;
                email_Obj.value = email_Obj.value + this.state.school_domain;
                let validate_element = Validations(email_Obj);
                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;
                form_element.value = old_value;
                update_manager_add_form[key] = form_element;
            } else {
                let form_element = update_manager_add_form[key];
                let validate_element = Validations(form_element);
                is_form_valid = validate_element.valid === false ? false : is_form_valid;
                form_element.onBlur_out = true;
                form_element.valid = validate_element.valid;
                form_element.error_msg = validate_element.error_msg;
                update_manager_add_form[key] = form_element;
            }
        }
        this.setState({ manager_add_form: update_manager_add_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.manager_add_form) {
                if (key === 'email') {
                    const email = this.state.manager_add_form['email'].value + this.state.school_domain;
                    form_data[key] = email;
                } else if (key === 'permission_data') {
                    let permissions_data = this.state.manager_add_form['permission_data'].value;
                    let classes = this.state.manager_add_form.class_data.value;
                    let update_class_data = classes.map((item) => {
                        return ({
                            class_id: item.value,
                            class_name: item.label
                        })
                    })
                    let dorms = this.state.manager_add_form.dorm_data.value;
                    let update_dormitory_data = dorms.map((item) => {
                        return ({
                            dormitory_id: item.value,
                            dormitory_name: item.label,
                        })
                    })
                    permissions_data.dorm_data = update_dormitory_data;
                    permissions_data.class_data = update_class_data;
                    permissions_data.snapshot_gender_both = this.state.manager_add_form.snapshot_gender_both.value;
                    permissions_data.snapshot_gender_female = this.state.manager_add_form.snapshot_gender_female.value;
                    permissions_data.snapshot_gender_male = this.state.manager_add_form.snapshot_gender_male.value;
                    console.log("manager_form_data", permissions_data);
                    form_data['permission_data'] = JSON.stringify(permissions_data);
                } else {
                    form_data[key] = this.state.manager_add_form[key].value;
                }
            }
            let classes = this.state.manager_add_form.class_data.value;
            let update_class_data = classes.map((item) => {
                return ({
                    class_id: item,
                })
            })
            let dorms = this.state.manager_add_form.dorm_data.value;
            let update_dormitory_data = dorms.map((item) => {
                return ({
                    dormitory_id: item,
                })
            })
            form_data['dorm_data'] = update_dormitory_data;
            form_data['class_data'] = update_class_data;
            console.log("manager_form_data", form_data);
            this.setState({ isLoading: true })
            this.props.onCreateSchoolManager(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({ isLoading: false })
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/manager-list`,
                        });
                    }, 500);
                } else {
                    console.log("error");
                    this.setState({ isLoading: false })
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }
    };

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 27) {
            this.setState({ OpenImageModal: false })
        }
    };


    render() {
        const { manager_add_form } = this.state;
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        return (

            <React.Fragment>
                {this.state.OpenImageModal &&
                    <div className="upload-image-outer-div" >
                        <span style={{ float: "right" }} className="pointer m-5" onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}>
                            <i class="fas fa-times fa-2x" sixe="xl" style={{ color: "#ffffff", }}></i>
                        </span>
                       
                        <div className="upload-image-inner-div">
                            <AvatarImageCropper
                                rootStyle={{ borderRadius: "50%" }}
                                cancelBtnStyle={{ display: "none" }}
                                apply={this.inputChangeHandlerimage}
                                className={"image_crop_style"}
                                sliderConStyle={{ margin: "20px", backgroundColor: "none" }}
                                applyBtnStyle={{}}
                                isBack={true}
                            />
                        </div>
                        <div className="text-center mt-10">
                            <span className="text-white font-weight-bold">The image maximum size is 2MB. Please upload png/jpg/jpeg image</span>
                        </div>
                    </div>
                }



                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100">
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="school-edit-form ">
                                                <div className="" id="Snapshot">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h5 className="card-title  m-0">Add Manager</h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-4">
                                                                        <div className="fv-row">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="profile-image-out p-0">
                                                                                        <div className="d-flex">
                                                                                            <div className="col-sm-12">
                                                                                                <img alt="profile" src={manager_add_form?.avatar?.thumbnail_image} className="img-fluid" />

                                                                                                <div className=" h-100 flex-column align-items-center">

                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-sm btn-outline-primary px-10 rounded-50 "
                                                                                                        onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}
                                                                                                    >
                                                                                                        Upload Image
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8">

                                                                        <div className="row">
                                                                            <div className="col-lg-6 col-xxl-4  fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{manager_add_form.first_name.label} </label>
                                                                                <input
                                                                                    type={manager_add_form.first_name.type}
                                                                                    value={manager_add_form.first_name.value ? manager_add_form.first_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={manager_add_form.first_name.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "first_name")}
                                                                                    // onkeydown="return /[a-z]/i.test(event.key)"
                                                                                    
                                                                                />
                                                                                {!manager_add_form.first_name.valid && manager_add_form.first_name.onBlur_out ? <div className="error field-error">{manager_add_form.first_name.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6 col-xxl-4  fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{manager_add_form.last_name.label} </label>
                                                                                <input
                                                                                    type={manager_add_form.last_name.type}
                                                                                    autoComplete="off"
                                                                                    value={manager_add_form.last_name.value ? manager_add_form.last_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={manager_add_form.last_name.placeholder}

                                                                                    onChange={(event) => this.inputChangeHandler(event, "last_name")}
                                                                                />
                                                                                {!manager_add_form.last_name.valid && manager_add_form.last_name.onBlur_out ? <div className="error field-error">{manager_add_form.last_name.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6 col-xxl-4  fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{manager_add_form.email.label}</label>
                                                                                <div className="d-flex rounded-1" style={{ border: "1px solid #ccccccb8" }}>
                                                                                    <input
                                                                                        style={{ textAlign: "right", paddingRight: "3px", border: "0px" }}
                                                                                        type="text"
                                                                                        value={manager_add_form.email.value ? manager_add_form.email.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={manager_add_form.email.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                                        autocomplete="off"
                                                                                    />
                                                                                    <div className="h-100  top-0 fw-bold end-0 border border-start-0 rounded-1" style={{ backgroundColor: "#ecf7ff", padding: "7px 5px 8px 7px" }}>
                                                                                        {this.state.school_domain}
                                                                                    </div>
                                                                                </div>

                                                                                {!manager_add_form.email.valid && manager_add_form.email.onBlur_out ? <div className="error field-error">{manager_add_form.email.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6 col-xxl-4  fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Assign Password *</label>
                                                                                <div className="position-relative">
                                                                                    <input
                                                                                        type={this.state.hiddenpassword ? 'password' : 'text'}
                                                                                        value={manager_add_form.password.value ? manager_add_form.password.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={manager_add_form.password.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler(event, "password")}
                                                                                        autocomplete="off"
                                                                                    />
                                                                                    <span onClick={this.toggleShow} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">
                                                                                        {this.state.hiddenpassword ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}
                                                                                    </span>
                                                                                </div>
                                                                                {!manager_add_form.password.valid && manager_add_form.password.onBlur_out ? <div className="error field-error">{manager_add_form.password.error_msg}</div> : ""}
                                                                            </div>
                                                                            {console.log("nan----------------", isNaN("1"))}
                                                                            <div className="col-lg-6 col-xxl-4  fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{manager_add_form.contact_one.label}</label>
                                                                                <ReactPhoneInput
                                                                                    countryCodeEditable={false}
                                                                                    country={manager_add_form.country_code.value ? manager_add_form.country_code.value : ""}
                                                                                    value={manager_add_form.contact_one.value ? manager_add_form.contact_one.value : ""}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "contact_one")}
                                                                                    enableLongNumbers={15}
                                                                                />
                                                                                {!manager_add_form.contact_one.valid && manager_add_form.contact_one.onBlur_out ? (
                                                                                    <div className="error field-error">{manager_add_form.contact_one.error_msg}</div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>

                                                                            <div className="col-lg-6 col-xxl-4  fv-row mb-5">
                                                                                <label className="fw-bold mb-2">
                                                                                    {manager_add_form.designation.label}
                                                                                    <Tooltip title="This is the boarding staff member's actual designation within your school as their employed title reads. In some cases it will / can be the same as Role. Role is only unique to this system - the Admin links Role to Permissions and the Permissions can be changed by Admin, at any time." placement="top">
                                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                                    </Tooltip>
                                                                                </label>

                                                                                <input
                                                                                    type={manager_add_form.designation.type}
                                                                                    className="form-control"
                                                                                    placeholder={manager_add_form.designation.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "designation")}
                                                                                />

                                                                                {!manager_add_form.designation.valid && manager_add_form.designation.onBlur_out ? (
                                                                                    <div className="error field-error">{manager_add_form.designation.error_msg}</div>
                                                                                ) : (
                                                                                    ""
                                                                                )}

                                                                            </div>

                                                                            <div className="col-lg-4 col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">
                                                                                    Select Role *
                                                                                    <Tooltip title="If you have created the Roles and they still do not display here, please check if you have added Permissions to those Roles." placement="top">
                                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                                    </Tooltip>
                                                                                    <Tooltip title="Role refers to the predefined Roles that Admin has already assigned in this system to match the permissions and access to dormitory information." placement="top">
                                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                                    </Tooltip>
                                                                                </label>
                                                                                <label className="checkbox cursor-pointer w-100">
                                                                                    <Select
                                                                                        //    styles={customStyles}
                                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                                        options={this.state.all_role.options}
                                                                                        value={this.state.all_role.options.find(option =>
                                                                                            option.value === this.state.all_role.value)}
                                                                                        onChange={(newValue) => this.handleChangeRole(newValue, "role_name")}
                                                                                    />
                                                                                    {!manager_add_form.role_id.valid && manager_add_form.role_id.onBlur_out ? (<div className="error field-error">{manager_add_form.role_id.error_msg}</div>) : ("")}
                                                                                </label>
                                                                            </div>

                                                                            <div className="col-lg-8 col-xl-8 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{manager_add_form.comment.label}</label>
                                                                                <textarea
                                                                                    type={manager_add_form.comment.type}
                                                                                    className="form-control input_field autoresizing"
                                                                                    placeholder={manager_add_form.comment.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "comment")}
                                                                                />
                                                                                {!manager_add_form.comment.valid && manager_add_form.comment.onBlur_out ? <div className="error field-error">{manager_add_form.comment.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div>
                                                                                <input
                                                                                    type={manager_add_form.country_host.type}
                                                                                    value={user_info.country_host ? user_info.country_host : "1234"}
                                                                                    className="form-control"
                                                                                />
                                                                            </div>

                                                                            <div>
                                                                                <input
                                                                                    type={manager_add_form.school_code.type}
                                                                                    value={user_info.school_code ? user_info.school_code : "bssschool"}
                                                                                    className="form-control"
                                                                                />
                                                                            </div>

                                                                            <div>
                                                                                <input
                                                                                    type={manager_add_form.school_detail_id.type}
                                                                                    value={user_info.school_detail_id ? user_info.school_detail_id : "786786"}

                                                                                    className="form-control"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>




                                                            </div>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h5 className="card-title  m-0">Add Snapshot Permissions</h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="sec-row row">
                                                                            <div className="col-md-2">
                                                                                <label className="fw-bold mb-2">Manage Genders *</label>
                                                                            </div>
                                                                            <div className="col-md-10">
                                                                                <div className="d-flex gap-10">

                                                                                    <label className="cursor-pointer d-flex align-items-center">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="gender1"
                                                                                            name="gen"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value={manager_add_form.snapshot_gender_male.value}
                                                                                            checked={manager_add_form.snapshot_gender_male.value === true ? manager_add_form.snapshot_gender_male.value === true : null}
                                                                                            onChange={(event) => this.inputChangeHandlerSnapshot(event, "snapshot_gender_male")}
                                                                                        />
                                                                                        Male
                                                                                    </label>


                                                                                    <label className="cursor-pointer d-flex align-items-center">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="gender2"
                                                                                            name="gen"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value={manager_add_form.snapshot_gender_female.value}
                                                                                            checked={manager_add_form.snapshot_gender_female.value === true ? manager_add_form.snapshot_gender_female.value === true : null}
                                                                                            onChange={(event) => this.inputChangeHandlerSnapshot(event, "snapshot_gender_female")}
                                                                                        />
                                                                                        Female
                                                                                    </label>


                                                                                    <label className="cursor-pointer d-flex align-items-center">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="genderall"
                                                                                            name="gen"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value={manager_add_form.snapshot_gender_both.value}
                                                                                            checked={manager_add_form.snapshot_gender_both.value === true ? manager_add_form.snapshot_gender_both.value === true : null}
                                                                                            onChange={(event) => this.inputChangeHandlerSnapshot(event, "snapshot_gender_both")}
                                                                                        />
                                                                                        Both
                                                                                    </label>

                                                                                </div>
                                                                            </div>
                                                                        </div>





                                                                        <div className="row mt-5">
                                                                            <div className="col-6">
                                                                                <div className="checkbox-inline">

                                                                                    <label className="fw-bold mb-2">Select Class *</label>


                                                                                    <div className="checkbox cursor-pointer w-100">
                                                                                        <Select
                                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                                            options={manager_add_form.class_data.options}
                                                                                            // value={() => this.setClassValue()}
                                                                                            value={manager_add_form.class_data.value}
                                                                                            // value={manager_add_form.class_data.options.filter(option =>
                                                                                            //     manager_add_form.class_data.value.includes(option.value))}
                                                                                            onChange={(newValue) => this.handleChangeClass(newValue, "class_data")}
                                                                                            isMulti
                                                                                        />
                                                                                        {!manager_add_form.class_data.valid && manager_add_form.class_data.onBlur_out ? <div className="error field-error">{manager_add_form.class_data.error_msg}</div> : ""}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div className="checkbox-inline">
                                                                                    <label className="fw-bold mb-2">Select Dormitory *</label>
                                                                                    <Tooltip title="Whilst engaging with the initial Role setup - ‘Dormitory’ cannot be selected as dorms have not yet been created.  (if you are following the steps correctly) You will return to add Dormitory in a few steps time." placement="top">
                                                                                        <i className="fas fa-question-circle ms-2"></i>
                                                                                    </Tooltip>
                                                                                    <div className="checkbox cursor-pointer w-100">
                                                                                        <Select
                                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                                            options={manager_add_form.dorm_data.options}
                                                                                            // value={this.state.dormitory_id.options.filter(option =>
                                                                                            //     manager_add_form.dorm_data.value.includes(option.value))}
                                                                                            value={manager_add_form.dorm_data.value}
                                                                                            onChange={(newValue) => this.handleChangeDorm(newValue, "dorm_data")}
                                                                                            isMulti
                                                                                        />
                                                                                        {!manager_add_form.dorm_data.valid && manager_add_form.dorm_data.onBlur_out ? <div className="error field-error">{manager_add_form.dorm_data.error_msg}</div> : ""}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12 d-flex justify-content-end">
                                                                <div className="d-flex align-items-end mb-8 me-6">
                                                                    <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px addschool-button" disabled={this.state.isLoading}>
                                                                        Add Manager
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {

    onCreateSchoolManager: userActions.CreateSchoolManager,
    onGetAllStatesByCountryId: userActions.GetAllStatesByCountryId,
    onGetRoleAndPermissionList: userActions.GetRoleAndPermissionList,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesList: userActions.GetAllClassesList,
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagerAdd);

