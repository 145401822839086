import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");

/*** GetAllStudentsGrounded Action ***/

export const GetAllStudentsGroundedStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllStudentsGrounded_START,
    };
};

export const GetAllStudentsGroundedSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllStudentsGrounded_SUCCESS,
    };
};

export const GetAllStudentsGroundedFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllStudentsGrounded_FAIL,
    };
};
export const GetAllStudentsGrounded = (class_id,dormitory_id,student_age,gender, sort, order ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllStudentsGroundedStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllStudents`,
            params: {
                class_id:class_id,
                dormitory_id:dormitory_id,
                student_age:student_age,
                gender:gender,
                sort: sort, 
                order: order
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllStudentsGroundedSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllStudentsGroundedFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** CreateStudentGrounded Action ***/
export const CreateStudentGroundedStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateStudentGrounded_START,
    };
};
export const CreateStudentGroundedSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateStudentGrounded_SUCCESS,
    };
};
export const CreateStudentGroundedFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateStudentGrounded_FAIL,
    };
};
export const CreateStudentGrounded = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateStudentGroundedStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                school_detail_id: user_info.school_detail_id
            },
          
            url: `${actionType.environment_url}/api/bss-admin/CreateStudentGrounded`,
        })
            .then(function (response) {
                dispatch(CreateStudentGroundedSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateStudentGroundedFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetAllGroundedStudentsList Action ***/
export const GetAllGroundedStudentsListStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllGroundedStudentsList_START,
    };
};
export const GetAllGroundedStudentsListSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllGroundedStudentsList_SUCCESS,
    };
};
export const GetAllGroundedStudentsListFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllGroundedStudentsList_FAIL,
    };
};
export const GetAllGroundedStudentsList = (page, limit, sort, order, is_grounded_activate,search ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllGroundedStudentsListStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllGroundedStudents`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                is_grounded_activate:is_grounded_activate,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllGroundedStudentsListSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllGroundedStudentsListFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** DeactivateGrounded Action ***/

export const UpdateGroundedStudentStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateGroundedStudentStatus_START,
    };
};

export const UpdateGroundedStudentStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateGroundedStudentStatus_SUCCESS,
    };
};

export const UpdateGroundedStudentStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateGroundedStudentStatus_FAIL,
    };
};

export const UpdateGroundedStudentStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateGroundedStudentStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateGroundedStudentStatus`,
        })
            .then(function (response) {
                dispatch(UpdateGroundedStudentStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateGroundedStudentStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** DeleteStudentGrounded Action ***/

export const DeleteStudentGroundedStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteStudentGrounded_START,
    };
};
export const DeleteStudentGroundedSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteStudentGrounded_SUCCESS,
    };
};
export const DeleteStudentGroundedFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteStudentGrounded_FAIL,
    };
};
export const DeleteStudentGrounded = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(DeleteStudentGroundedStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                
            },
            url: `${actionType.environment_url}/api/bss-admin/DeleteStudentGrounded`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(DeleteStudentGroundedSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log("resposne fail", error);
                dispatch(DeleteStudentGroundedFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetGroundedStudentById Action ***/
export const GetGroundedStudentByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetGroundedStudentById_START,
    };
};
export const GetGroundedStudentByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetGroundedStudentById_SUCCESS,
    };
};
export const GetGroundedStudentByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetGroundedStudentById_FAIL,
    };
};
export const GetGroundedStudentById = (student_grounded_uuid) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetGroundedStudentByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetGroundedStudentById`,
            params: {
                student_grounded_uuid: student_grounded_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetGroundedStudentByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetGroundedStudentByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** UpdateStudentGroundedDetails Action ***/

export const UpdateStudentGroundedDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateStudentGroundedDetails_START,
    };
};
export const UpdateStudentGroundedDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateStudentGroundedDetails_SUCCESS,
    };
};
export const UpdateStudentGroundedDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateStudentGroundedDetails_FAIL,
    };
};

export const UpdateStudentGroundedDetails = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateStudentGroundedDetailsStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateStudentGroundedDetails`,
        })
            .then(function (response) {
                dispatch(UpdateStudentGroundedDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateStudentGroundedDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};
