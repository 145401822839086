import React, { useState } from "react"
import Footer from "../../Constant/Footer";
import Sidebar from "../../Constant/sidebar";
import TopBar from "../../Constant/TopBar";
import Select from "react-select";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import Validations from "../../Utility/Validations";
import { Tooltip } from "@mui/material";
import { Chart } from "react-google-charts";

const customStyles = {
	option: (provided, state) => ({
		...provided,
		"&:hover": {
			backgroundColor: state.isFocused ? "#e7f5fe" : "",
		},
		backgroundColor: state.isSelected ? "#4a8fb8" : "",
	}),
};

class RollCall extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rollCallDuration: {
				data: [
					["", "Time in min", { role: "style" }],
					["Maximum Roll Call Duration",60, "#009ef7"], // RGB value
					["Roll Call Duration set for Your School ", 0, "#50cd89"], // RGB value
					["First Warning for Remaining Time", 0, "#d9214e"], // English color name
					["Final Warning for Remaining Time", 0, "#ffc700"],
				],
			},


			rollcall_form: {

				meta_key: {
					type: "text",
					label: "Meta Key",
					value: "roll_call_schedule",
					valid: true,
					error_msg: "",
					onBlur_out: false,
					placeholder: "",
					validations: { required: false },
				},

				meta_value_one: {
					type: "select",
					label: "Total Roll Call Session Duration *",
					value: "",
					valid: true,
					error_msg: "",
					onBlur_out: false,
					placeholder: "City",
					validations: { required: true },
					options: [
						{ label: "Select Roll Call Time", value: "" },
						{ label: "5", value: "5" },
						{ label: "10", value: "10" },
						{ label: "15", value: "15" },
						{ label: "20", value: "20" },
						{ label: "25", value: "25" },
						{ label: "30", value: "30" },
						{ label: "35", value: "35" },
						{ label: "40", value: "40" },
						{ label: "45", value: "45" },
						{ label: "50", value: "50" },
						{ label: "55", value: "55" },
						{ label: "60", value: "60" },
					],
				},



				meta_value_two: {
					type: "number",
					label: "First Warning of Roll Call Time remaining *",
					value: "",
					valid: true,
					error_msg: "",
					onBlur_out: false,
					placeholder: "Please Enter First Warning in Minutes",
					validations: { required: true, max_length: 2 },
				},

				meta_value_three: {
					type: "number",
					label: "Final Warning of Roll Call Time remaining*",
					value: "",
					valid: true,
					error_msg: "",
					onBlur_out: false,
					placeholder: "Please Enter Second Warning in Minutes",
					validations: { required: true },
				},

				master_setting_uuid: {
					type: "text",
					label: "master setting uuid",
					value: "",
					valid: true,
					error_msg: "",
					onBlur_out: false,
					placeholder: "",
					validations: { required: false },
				},
			},
		};
	}


	maxLengthCheck = (object) => {
		if (object.target.value.length > object.target.maxLength) {
			object.target.value = object.target.value.slice(0, object.target.maxLength)
		}
	}

	componentDidMount() {

		this.GetRollCallDetails();

	}

	GetRollCallDetails() {
		this.props.onGetRollCallDetails(this.props.master_setting_uuid).then((response) => {
			if (response.success === true) {
				console.log("response of 11GETROLLCALLDETIAls", response.data);
				let rollcall_data = response.data[response.data.length-1];
				console.log("response of GETROLLCALLDETIAls", rollcall_data);

				console.log("rollcall", response);
				const update_rollcall_form = { ...this.state.rollcall_form };
				const update_diary_comment_data = { ...this.state.rollCallDuration }
				for (let key in update_rollcall_form) {
					let form_element = update_rollcall_form[key];
					if (rollcall_data[key]) {


						update_rollcall_form['meta_value_one'].value = rollcall_data?.meta_value_one;
						update_rollcall_form['meta_value_two'].value = rollcall_data?.meta_value_two;
						update_rollcall_form['meta_value_three'].value = rollcall_data.meta_value_three;
						update_rollcall_form['master_setting_uuid'].value = rollcall_data.master_setting_uuid;

						update_diary_comment_data['data'][2][1] = parseInt(rollcall_data?.meta_value_one);
						update_diary_comment_data['data'][3][1] = parseInt(rollcall_data?.meta_value_two);
						update_diary_comment_data['data'][4][1] = parseInt(rollcall_data.meta_value_three);
						this.setState({ data: update_diary_comment_data })


						this.setState({ rollcall_form: update_rollcall_form });
						update_rollcall_form[key] = form_element;
					}
				}
				this.setState({ rollcall_form: update_rollcall_form });
			} else {
			}
		});

	}

	inputChangeHandler(event, identifier) {
		const update_rollcall_form = { ...this.state.rollcall_form };
		const form_element = { ...update_rollcall_form[identifier] };
		form_element.value = event.target.value;
		// Check Validation
		let validate_element = Validations(form_element);
		form_element.valid = validate_element.valid;
		form_element.error_msg = validate_element.error_msg;

		update_rollcall_form[identifier] = form_element;
		let is_form_valid = true;
		for (let identifier in update_rollcall_form) {
			is_form_valid = update_rollcall_form[identifier].valid && is_form_valid;
		}
		this.setState({
			rollcall_form: update_rollcall_form,
			is_form_valid: is_form_valid,
		});

		//this code for graph /////////////////////

		const update_diary_comment_data = { ...this.state.rollCallDuration }
		if (identifier === "meta_value_two") {
			update_diary_comment_data['data'][3][1] = parseInt(event.target.value);
		} else {
			update_diary_comment_data['data'][4][1] = parseInt(event.target.value);
		}
		this.setState({ data: update_diary_comment_data })

	}



	handleChange(data, identifier, type) {

		const update_rollcall_form = { ...this.state.rollcall_form };
		update_rollcall_form[identifier].value = data.value;
		this.setState({ rollcall_form: update_rollcall_form });

		//this code for graph /////////////////////

		const update_diary_comment_data = { ...this.state.rollCallDuration }
		update_diary_comment_data['data'][2][1] = parseInt(data.value);
		this.setState({ data: update_diary_comment_data })
	}

	SubmitHandler = async (event) => {
		console.log("gfdgjdfkj");
		event.preventDefault();
		let is_form_valid = true;
		const update_rollcall_form = this.state.rollcall_form;
		for (let key in update_rollcall_form) {
			let form_element = update_rollcall_form[key];
			let validate_element = Validations(form_element);

			is_form_valid = validate_element.valid === false ? false : is_form_valid;
			form_element.onBlur_out = true;
			form_element.valid = validate_element.valid;
			form_element.error_msg = validate_element.error_msg;

			update_rollcall_form[key] = form_element;
		}
		this.setState({ rollcall_form: update_rollcall_form });
		if (is_form_valid) {
			const form_data = {};
			for (let key in this.state.rollcall_form) {
				form_data[key] = this.state.rollcall_form[key].value;
			}

			this.props.onCreateOrUpdateRollCall(form_data).then((response) => {
				if (response.success === true) {

					localStorage.setItem("bss_rollcall", JSON.stringify(response.data));

					toast.success(response.message, {
						position: toast.POSITION.TOP_CENTER,
					});
					// setTimeout(() => {
					// 	this.setState({
					// 		isAuthenticated: true,
					// 		redirect_url: "/settings/roll-call",
					// 	});
					// }, 1000);
				} else {
					console.log("error");
					toast.error(response.message, {
						position: toast.POSITION.TOP_CENTER,
					});
				}

			});

			console.log("form_data===", form_data);
		}
	};
	render() {
		const { rollcall_form } = this.state;
		const { rollCallDuration } = this.state

		console.log("rollcall_form===", rollcall_form);

		return (
			<>
				{this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
				<div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
					<div className="d-flex flex-column flex-root">
						<div className="page d-flex flex-row flex-column-fluid">
							<Sidebar />
							<div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
								<TopBar />
								<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
									<div className="post d-flex flex-column-fluid" id="kt_post">
										{/* <!--begin::Container--> */}
										<div id="kt_content_container" className="container-fluid">
											<div className="row gap-y-3">
												<div className="col-lg-12 col-xxl-6">
													<div className="card p-6 h-100">
														<div className="card-body d-flex align-items-center justify-content-center">
															<div className="w-100">
																<span className="time-icon-block mb-15"><img src="/assets/media/time-icon.png" /></span>
																<form onSubmit={this.SubmitHandler}>


																	<div className="">
																		<label className="fw-bold mb-2">{rollcall_form?.meta_value_one.label}</label>
																		<Tooltip title="School Admin defines the maximum duration of the Roll Call sessions in your school.  Sessions cannot be open ended.  Admin also defines at what point the “First Warning” is displayed.  Just to remind staff to complete the session.  Admin also defines when the “Second (Final) Warning” displays.  It stands to reason that “Total Duration” is highest number.  Then “First Warning” is a smaller number, followed by “Second (Final) Warning” being the lowest number." placement="top">
																			<i className="fas fa-question-circle ms-2"></i>
																		</Tooltip>
																	</div>
																	<Select
																		styles={customStyles}
																		className="react-bootstrap-typeahead tag-outer"
																		options={rollcall_form?.meta_value_one.options}
																		value={rollcall_form?.meta_value_one.options.filter(function (option) {
																			return option.value === rollcall_form?.meta_value_one.value;
																		})}
																		onChange={(newValue) => this.handleChange(newValue, "meta_value_one")}
																	/>

																	{!rollcall_form?.meta_value_one.valid && rollcall_form?.meta_value_one.onBlur_out ? <div className="error field-error">{rollcall_form?.meta_value_one.error_msg}</div> : ""}
																	<br />

																	<label className="fw-bold mb-2">{rollcall_form.meta_value_two.label}</label>

																	<input
																		type={rollcall_form.meta_value_two.type}
																		maxLength="2" onInput={this.maxLengthCheck}
																		max={rollcall_form?.meta_value_one.value - 1}
																		min={0}
																		value={rollcall_form.meta_value_two.value ? rollcall_form.meta_value_two.value : ""}
																		className="form-control"
																		placeholder={rollcall_form.meta_value_two.placeholder}
																		onChange={(event) => this.inputChangeHandler(event, "meta_value_two")}

																	/>

																	{!rollcall_form.meta_value_two.valid && rollcall_form.meta_value_two.onBlur_out ? <div className="error field-error">{rollcall_form.meta_value_two.error_msg}</div> : ""}

																	<br />

																	<label className="fw-bold mb-2">{rollcall_form.meta_value_three.label}</label>

																	<input
																		type={rollcall_form.meta_value_three.type}
																		max={rollcall_form.meta_value_two.value - 1}
																		min={0}
																		value={rollcall_form.meta_value_three.value ? rollcall_form.meta_value_three.value : ""}
																		className="form-control"
																		placeholder={rollcall_form.meta_value_three.placeholder}
																		onChange={(event) => this.inputChangeHandler(event, "meta_value_three")}
																		maxLength="2" onInput={this.maxLengthCheck}
																	/>

																	{!rollcall_form.meta_value_three.valid && rollcall_form.meta_value_three.onBlur_out ? <div className="error field-error">{rollcall_form.meta_value_three.error_msg}</div> : ""}

																	<input
																		type="hidden"
																		value={rollcall_form?.meta_key.value ? rollcall_form?.meta_key?.value : ""}
																		className="form-control"
																		placeholder={rollcall_form?.meta_key?.placeholder}
																		onChange={(event) => this.inputChangeHandler(event, "meta_key")}

																	/>
																	<br />
																	<div className="text-center">
																		<button type="submit" className="btn btn-primary btn-flex h-40px border-0 fw-bolder px-4 px-lg-6">Submit</button>
																	</div>
																</form>
															</div>
														</div>
													</div>
												</div>

												<div className="col-lg-12 col-xxl-6">
													<div className="card p-6  h-100">
														<div className="card-body d-flex align-items-center justify-content-center">
															<Chart chartType="ColumnChart" width="100%" height="400px" data={rollCallDuration.data} />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<Footer />
							</div>
						</div>
					</div>
				</div >


			</>
		)
	}
}
function mapStateToProps(state) {
	return {
		// error: state.students.error,
		// loader: state.students.loader,
		// isAuthenticated: state.students.isAuthenticated,
	};
}
const mapDispatchToProps = {

	onCreateOrUpdateRollCall: userActions.CreateOrUpdateRollCall,
	onGetRollCallDetails: userActions.GetRollCallDetails,


};
export default connect(mapStateToProps, mapDispatchToProps)(RollCall);


