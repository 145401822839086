import React, { useCallback, useEffect, useState } from 'react'
import SubItems from "./SubItems"
import * as userActions from "../../../../../../actions/index";
import { useDispatch } from 'react-redux';
import moment from 'moment';


function SchoolSubscriptionDetails({ type, id }) {
  const dispatch = useDispatch();
  const [subscriptionData, setSubscriptionData] = useState()
  const [filterpage, setfilterpage] = useState(1);
  const [filterlimit, setfilterlimit] = useState(10);
  const [SuperAdminSubscriptioncount, setSuperAdminSubscriptioncount] = useState(0);
  const [filtersearch, setfiltersearch] = useState(null);

  const GetSchoolGetPaymentDetails = useCallback(async () => {
    dispatch(userActions.GetSchoolGetPaymentDetailsById(filterpage, filterlimit, filtersearch, type, id)).then((response) => {
      if (response.success === true) {
        const arrayData = response.data.rows;
        console.log("arrayData=======", arrayData);
        let schoolSubscriptionData = [];
        const sumvalue = 1;
        for (let key in arrayData) {
          const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

          schoolSubscriptionData.push({
            perpage: perpage,
            // school_name: arrayData[key].school_name && arrayData[key].school_name.charAt(0).toUpperCase() + arrayData[key].school_name.slice(1),
            start_date: moment(arrayData[key].start_date).format('DD/MM/YYYY'),
            end_date: moment(arrayData[key].end_date).format('DD/MM/YYYY'),
            amount: arrayData[key].amount ? arrayData[key].amount : "-",
            created_date: moment(arrayData[key].created_date).format('DD/MM/YYYY'),
            // subscription_plan: arrayData[key].type === "demo" ? "-" : calculateYearsAndMonths(arrayData[key].start_date, arrayData[key].end_date),
            // type: arrayData[key].type === "demo" ? <span className="badge badge-light-info px-4 py-2">Trial</span> : <span className="badge badge-light-danger px-4 py-2">subscription</span>,
            // school_detail_id: arrayData[key].school_detail_id
          });
        }
        setSubscriptionData(schoolSubscriptionData)
        setSuperAdminSubscriptioncount(response.data.count);
      } else {
      }
    });
  }, [dispatch, filterpage, filterlimit, filtersearch]);

  useEffect(() => {
    GetSchoolGetPaymentDetails();
  }, [GetSchoolGetPaymentDetails, filterpage, filterlimit, filtersearch]);
  return (
    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
      <div className="post d-flex flex-column-fluid" id="kt_post">
        {/* <!--begin::Container--> */}
        <div id="kt_content_container" className="container-fluid" style={{ padding: "0px" }}>


          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade" id="Snapshot" role="tabpanel" aria-labelledby="Snapshot-tab"></div>

            <div className="tab-pane fade show active" id="Rollcall" role="tabpanel" aria-labelledby="Rollcall-tab">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card tabs-outer">
                    {/* <div className="card-header position-relative py-0">

                      <div className="d-flex align-items-center position-relative">
                        <span className="svg-icon svg-icon-1 position-absolute ms-4">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                        <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search "
                          onChange={(event) => {
                            setfiltersearch(event.target.value);
                            setfilterpage(1);
                          }}

                        />
                      </div>
                    </div> */}

                    <div className="card-body">
                      <div className="card-toolbar">
                        <div className="d-flex align-items-center justify-content-between">

                          {/* <!-- <h4 className="card-title text-gray-800 fw-bolder m-0">Schools</h4> --> */}


                          <div className="row">
                            <div className="col-sm-12">

                              {subscriptionData && <SubItems

                                SuperAdminSubscriptioncount={SuperAdminSubscriptioncount}
                                SuperAdminDashboard={subscriptionData}
                                data={subscriptionData}
                                propsfilterpage={(event) => setfilterpage(event)}
                                // // RemoveCourseModule={(event, data) => RemoveCourseModule(event, data)}

                                filterlimit={filterlimit}
                                filterpage={filterpage}
                                propsfilterlimit={(filterlimit) => {
                                  setfilterlimit(filterlimit);
                                }}
                              />}




                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>




              </div>
            </div>
          </div>
        </div>
        {/* <!--end::Container--> */}
      </div>
    </div>
  )
}

export default SchoolSubscriptionDetails