import { useEffect, useState } from "react";
import SideBarLeave from "../../Constant/SideBarLeave";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import IconButton from '@mui/material/IconButton';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { Tooltip } from "@mui/material";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Pagination from '@mui/material/Pagination';

import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
const HostConnectToStudentReport = (props) => {

    const [hostConnectedToStudentsReport, setHostConnectedToStudentsReport] = useState([])
    const [hostsApprovedBy, setHostApprovedBy] = useState("")
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState()
    const [is_host_approved, setIs_host_approved] = useState("")
    const [pdf_url, setPdf_url] = useState("")
    const [is_pdf, setIS_pdf] = useState(false);
    const [filterBySort, setFilterBySort] = useState("host_name")
    const [filterByorder, setFilterByorder] = useState(false)

    const row_limit = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 },
        { value: 25, label: 25 },
    ]


    useEffect(() => {
        HostConnectedToStudentsReport()
        hostApproved()
    }, [page, limit, is_host_approved, is_pdf, filterByorder, filterBySort])

    const HostConnectedToStudentsReport = () => {
        props.onHostConnectedToStudentsReport(page, limit, is_host_approved, is_pdf, filterByorder, filterBySort).then((response) => {
            if (response.success === true) {
                setHostConnectedToStudentsReport(response.data)
                setCount(response.totalCount)
                setPdf_url(response.file)
                if (is_pdf) {
                    setTimeout(() => {
                        DownloadPdf(response.file)
                    },1000)
                }
            } else {
            }
        });

    }

    const hostApproved = () => {
        const local_stoage_data = JSON.parse(window.localStorage.getItem('bss_authuser'));
        setHostApprovedBy(local_stoage_data.first_name + " " + local_stoage_data.last_name)
    }

    const handleChange = (event, value) => {
        setPage(value);
    };

    const changeLimit = (e) => {
        setLimit(parseInt(e.value));
    };

    const DownloadPdf = (pdf_url) => {
        const link = document.createElement("a");
        link.href = pdf_url;
        link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setIS_pdf(false);
    }

    const Capitalize = (text) => {
        return text.charAt(0).toUpperCase() + text.slice(1)
    }

    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SideBarLeave />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    {/* <!--begin::Container--> */}
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="Rollcall" role="tabpanel" aria-labelledby="Rollcall-tab">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="card  tabs-outer">
                                                            <div className="card-header">
                                                                <h2 className="card-title">Students of a Host</h2>
                                                                <div className="d-flex align-items-center">
                                                                    <p className="m-0 me-3">Download PDF</p>
                                                                    <IconButton
                                                                        // onClick={() => DownloadPdf()} 
                                                                        onClick={() => { setIS_pdf(true);}}>
                                                                        <i class="fas fa-file-pdf"></i>
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="card-toolbar">
                                                                    <MDBTable className='table-row-bordered'>
                                                                        <MDBTableHead>
                                                                            <tr>
                                                                                <th>S.No.</th>
                                                                                <th>Host Name <i className={`bi bi-filter cursor-pointer ${filterBySort === 'host_name' ? 'text-primary' : ''}`} onClick={() => {setFilterBySort('host_name'); setFilterByorder((prev) => !prev)}}></i></th>
                                                                                <th>Student Relation</th>
                                                                                <th>Student Name <i className={`bi bi-filter cursor-pointer ${filterBySort === 'student_first_name' ? 'text-primary' : ''}`} onClick={() => {setFilterBySort('student_first_name'); setFilterByorder((prev) => !prev)}}></i></th>
                                                                            </tr>
                                                                        </MDBTableHead>
                                                                        <MDBTableBody>
                                                                            {hostConnectedToStudentsReport.map((host, index) => {
                                                                                let parentAsHostMother = host.parent_id && host.parent_type == "mother";
                                                                                let parentAsHostFather = host.parent_id && host.parent_type == "father";
                                                                                let hostDefault = host.host_id;
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{hostDefault ? Capitalize(host.host_data.host_name) : (parentAsHostMother ? Capitalize(host.parent_host_data.mother_name) : Capitalize(host.parent_host_data.father_name))}</td>
                                                                                        <td>{host.host_relation && host.host_relation.charAt(0).toUpperCase() + host.host_relation.slice(1)}</td>
                                                                                        <td>{host.stu_data.student_name && host.stu_data.student_name.charAt(0).toUpperCase() + host.stu_data.student_name.slice(1)}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </MDBTableBody>

                                                                    </MDBTable>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <Select
                                                                        menuPlacement="top"
                                                                        className="basic-single"
                                                                        classNamePrefix="select"
                                                                        defaultValue={row_limit[1]}
                                                                        name="color"
                                                                        options={row_limit}
                                                                        onChange={changeLimit}
                                                                    />
                                                                    <Stack spacing={1} >
                                                                        <Pagination
                                                                            count={Math.ceil(count / limit)}
                                                                            page={page}
                                                                            onChange={handleChange}
                                                                            color="primary"
                                                                        />
                                                                    </Stack>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}



function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}

const mapDispatchToProps = {
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesList: userActions.GetAllClassesList,
    onHostConnectedToStudentsReport: userActions.HostConnectedToStudentsReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(HostConnectToStudentReport);