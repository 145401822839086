import React, { useEffect, useState } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";

const ArchiveUnarchiveLeaveModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [leave_data, setLeaveData] = useState({})
  useEffect(() => {
    setShowModal(props.showArchiveModal)
    setLeaveData(props.archiveLeaveData)
  }, [props])

  const closeModal = () => {
    props.hideArchiveModal(false)
  };

  const handleArchive = () => {
    const form_data = {
      border_leave_id: leave_data.border_leave_id,
      is_leave_archived: !props.leaveChecked
    }
    props.ArchiveOrUnarchiveBorderLeave(form_data);
  };
  let conditionBaseMessage = !props.leaveChecked ? "Archive" : "UnArchive"
  return (
    <>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{conditionBaseMessage} Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to {conditionBaseMessage} this leave?</Modal.Body>
        <Modal.Footer>
          <Button className="btn-sm" variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button className="btn-sm px-10" variant="primary" onClick={handleArchive}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ArchiveUnarchiveLeaveModal;
