import React from "react";
import { Navigate } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";

import Validations from "../../Utility/Validations";
import Footer from "../../Constant/Footer";
import SuperAdminTopbar from "../topbar";
import SuperAdminSideMenu from "../Sidemenu";
import { toast } from "react-toastify";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import Tooltip from '@mui/material/Tooltip';

import * as actionType from "../../../constants/actionTypes";

import * as userActions from "../../../actions/index";

import AvatarImageCropper from 'react-avatar-image-cropper';
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

class SchoolEdit extends React.Component {
    constructor(props) {
        super(props);
        window.addEventListener('keydown', this.handleKeyPress);
        this.state = {
            current_password_hidden: true,
            new_password__hidden: true,
            confirm_new_password__hidden: true,
            OpenImageModal: false,
            identifiers: "",
            school_form: {
                school_name: {
                    type: "text",
                    label: "School Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "School Name",
                    validations: { required: true },
                },

                school_detail_uuid: {
                    type: "text",
                    label: "school_detail_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

                user_uuid: {
                    type: "text",
                    label: "school_detail_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

                actual_school_code: {
                    type: "text",
                    label: "School Code *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "School Code",
                    validations: { required: false },
                },

                school_code: {
                    type: "text",
                    label: "School Code *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "School Code",
                    validations: { required: true },
                },

                weekend_day_from: {
                    type: "select",
                    label: "Weekend Start Day *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select weekend day",
                    validations: { required: true },
                    options: [
                        { label: "From Day", value: "" },
                        { label: "Sunday", value: "Sunday" },
                        { label: "Monday", value: "Monday" },
                        { label: "Tuesday", value: "Tuesday" },
                        { label: "Wednesday", value: "Wednesday" },
                        { label: "Thursday", value: "Thursday" },
                        { label: "Friday", value: "Friday" },
                        { label: "Saturday", value: "Saturday" },
                    ],
                },

                weekend_day_to: {
                    type: "select",
                    label: "Weekend Finish Day *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select weekend day",
                    validations: { required: true },
                    options: [
                        { label: "To Day", value: "" },
                        { label: "Sunday", value: "Sunday" },
                        { label: "Monday", value: "Monday" },
                        { label: "Tuesday", value: "Tuesday" },
                        { label: "Wednesday", value: "Wednesday" },
                        { label: "Thursday", value: "Thursday" },
                        { label: "Friday", value: "Friday" },
                        { label: "Saturday", value: "Saturday" },
                    ],
                },

                cut_off_day: {
                    type: "select",
                    label: "Cut off Day *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select Cut off Day",
                    validations: { required: true },
                    options: [
                        { label: "Cut off Day", value: "" },
                        { label: "Sunday", value: "Sunday" },
                        { label: "Monday", value: "Monday" },
                        { label: "Tuesday", value: "Tuesday" },
                        { label: "Wednesday", value: "Wednesday" },
                        { label: "Thursday", value: "Thursday" },
                        { label: "Friday", value: "Friday" },
                        { label: "Saturday", value: "Saturday" },
                    ],
                },

                country: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: true },
                    disabled: true
                },

                country_id: {
                    type: "select",
                    label: "Country / Region *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select weekend day",
                    validations: { required: true },
                    options: [],
                    disabled: true
                },

                state_id: {
                    type: "select",
                    label: "State *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "State",
                    validations: { required: true },
                    options: [{ label: "State *", value: "" },],
                },

                state: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: true },
                },

                city_id: {
                    type: "select",
                    label: "City *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [
                        { label: "City *", value: "" },
                    ],
                },

                city: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: true },
                },

                school_address: {
                    type: "text",
                    label: "Physical Address *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "",
                    validations: { required: true },
                },

                zipcode: {
                    type: "text",
                    label: "Physical Address *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "1234",
                    validations: { required: true },
                },

                country_host: {
                    type: "text",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: true },
                },

                highest_class: {
                    type: "select",
                    label: "Highest Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "City",
                    validations: { required: true },
                    options: [
                        { label: "Highest Year", value: "" },
                        { label: "1st Year", value: 1 },
                        { label: "2nd Year", value: 2 },
                        { label: "3rd Year", value: 3 },
                        { label: "4th Year", value: 4 },
                        { label: "5th Year", value: 5 },
                        { label: "6th Year", value: 6 },
                        { label: "7th Year", value: 7 },
                        { label: "8th Year", value: 8 },
                        { label: "9th Year", value: 9 },
                        { label: "10th Year", value: 10 },
                        { label: "11th Year", value: 11 },
                        { label: "12th Year", value: 12 },
                        { label: "13th Year", value: 13 },
                        { label: "14th Year", value: 14 },
                    ],
                },

                domain_name: {
                    type: "text",
                    label: "Domain Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: true },
                },

                weekend_time_from: {
                    type: "time",
                    label: "Select weekend time from",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "08:08",
                    validations: { required: true },



                },

                weekend_time_to: {
                    type: "time",
                    label: "Select weekend time to",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "08:08",
                    validations: { required: true },
                },

                cut_off_time: {
                    type: "time",
                    label: "Select cutoff",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "08:08",
                    validations: { required: true },
                },

                session_start_month: {
                    type: "select",
                    label: "Session Start Month *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [
                        { label: "Select Session Start Month", value: "" },
                        { label: "January", value: "January" },
                        { label: "February", value: "February" },
                        { label: "March", value: "March" },
                        { label: "April", value: "April" },
                        { label: "May", value: "May" },
                        { label: "June", value: "June" },
                        { label: "July", value: "July" },
                        { label: "August", value: "August" },
                        { label: "September", value: "September" },
                        { label: "October", value: "October" },
                        { label: "November", value: "November" },
                        { label: "December", value: "December" },
                    ],
                },

                session_end_month: {
                    type: "select",
                    label: "Session End Month *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [
                        { label: "Select Session End Month", value: "" },
                        { label: "January", value: "January" },
                        { label: "February", value: "February" },
                        { label: "March", value: "March" },
                        { label: "April", value: "April" },
                        { label: "May", value: "May" },
                        { label: "June", value: "June" },
                        { label: "July", value: "July" },
                        { label: "August", value: "August" },
                        { label: "September", value: "September" },
                        { label: "October", value: "October" },
                        { label: "November", value: "November" },
                        { label: "December", value: "December" },
                    ],
                },

                session_start_year: {
                    type: "select",
                    label: "Session Start Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [],
                },

                session_end_year: {
                    type: "select",
                    label: "Session End Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [],
                },

                timezone: {
                    type: "select",
                    label: "Session End Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Time Zone",
                    validations: { required: true },
                    options: [{ label: "Time Zone *", value: "" },],
                },
                other_contact_1_name: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_1_position: {
                    type: "text",
                    label: "Position",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_1_email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_1_contact: {
                    type: "tel",
                    label: "Contact",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_name: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_position: {
                    type: "text",
                    label: "Position",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_contact: {
                    type: "tel",
                    label: "Contact",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },

                country_code: {
                    type: "hidden",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: false },
                },
                school_logo: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/school-logo.png",
                },

            },

            boarding_head_form: {
                first_name: {
                    type: "text",
                    label: "First Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "First Name *",
                    validations: { required: true },
                },

                last_name: {
                    type: "text",
                    label: "Last Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: true },
                },

                email: {
                    type: "email",
                    label: "Primary E-mail",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: true, email: true },
                },

                contact_one: {
                    type: "number",
                    label: "Primary Contact Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: true, number: true, min_length_phone: 7 },
                },

                contact_two: {
                    type: "number",
                    label: "Alternate Contact Number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: false, number: true },
                },

                school_detail_uuid: {
                    type: "text",
                    label: "school_detail_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

                user_uuid: {
                    type: "text",
                    label: "school_detail_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

                school_code: {
                    type: "text",
                    label: "School Code *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "School Code",
                    validations: { required: false },
                },


                avatar: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: true },
                    thumbnail_image: "/assets/media/school-logo.png",
                },


            },

            update_manager_password: {
                current_password: {
                    type: "text",
                    label: "Current Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                new_password: {
                    type: "password",
                    label: "New Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    // onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8, password: true },
                },

                confirm_new_password: {
                    type: "password",
                    label: "Confirm New Password",
                    value: "",
                    valid: true,
                    error_msg: "",
                    // onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, min_length: 8, password: true },
                },
                user_uuid: {
                    type: "text",
                    label: "user_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                school_code: {
                    type: "text",
                    label: "School_code",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                country_host: {
                    type: "text",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                }
            },
        };
    }


    componentDidMount() {
        this.GetAllSelectedCountries();
        this.GetSchoolById();
        let maxOffset = 10;
        let thisYear = (new Date()).getFullYear();
        let allYears = [];
        for (let x = 0; x < maxOffset; x++) {
            allYears.push({
                label: thisYear + x,
                value: thisYear + x,
            })
        }
        const update_school_form = { ...this.state.school_form };
        update_school_form['session_start_year'].options = allYears;
        update_school_form['session_end_year'].options = allYears;
    }
    GetSchoolById() {
        this.props.onGetSchoolById(this.props.school_detail_uuid).then((response) => {
            if (response.success === true) {
                let school_data = response.data;
                let boarding_head_form = response.data;
                const update_school_form = { ...this.state.school_form };
                const update_boarding_head_form = { ...this.state.boarding_head_form };
                const update_account_setting = { ...this.state.update_manager_password }

                for (let key in update_school_form) {
                    let form_element = update_school_form[key];
                    if (school_data[key]) {
                        if (key === 'country_id') {
                            this.GetAllStatesByCountryId(school_data[key])
                            this.GetAllTimezonesByCountryCode(school_data[key]);
                        }
                        if (key === 'state_id') {
                            this.GetAllCitiesByStateId(school_data[key]);
                        }
                        if (key === 'school_logo' && school_data[key] !== null && school_data[key] !== '') {
                            form_element.thumbnail_image = actionType.environment_url + '/' + school_data[key];
                        }

                        if (key === 'session_start_year') {
                            form_element.value = parseInt(school_data[key]);
                        }
                        else if (key === 'session_end_year') {
                            form_element.value = parseInt(school_data[key]);
                        }
                        else {
                            form_element.value = school_data[key];
                        }

                        update_school_form[key] = form_element;
                    }
                }

                if (boarding_head_form.admin_user[0]) {
                    if (boarding_head_form.admin_user[0].avatar !== null && boarding_head_form.admin_user[0].avatar !== '') {
                        update_boarding_head_form['avatar'].thumbnail_image = actionType.environment_url + '/' + boarding_head_form.admin_user[0].avatar;
                        update_boarding_head_form['avatar'].value = boarding_head_form.admin_user[0].avatar;
                    }
                    update_boarding_head_form['first_name'].value = boarding_head_form.admin_user[0].first_name;
                    update_boarding_head_form['last_name'].value = boarding_head_form.admin_user[0].last_name;
                    update_boarding_head_form['contact_one'].value = boarding_head_form.admin_user[0].contact_one;
                    update_boarding_head_form['contact_two'].value = boarding_head_form.admin_user[0].contact_two;
                    update_boarding_head_form['email'].value = boarding_head_form.admin_user[0].email;
                    update_boarding_head_form['user_uuid'].value = boarding_head_form.admin_user[0].user_uuid;
                    update_boarding_head_form['school_detail_uuid'].value = boarding_head_form.school_detail_uuid;
                    update_boarding_head_form['school_code'].value = boarding_head_form.school_code;

                }
                update_account_setting['user_uuid'].value = response.data.admin_user[0].user_uuid;
                update_account_setting['current_password'].value = response.data.admin_user[0].original_password;
                update_account_setting['school_code'].value = response.data.admin_user[0].school_code;
                update_account_setting['country_host'].value = response.data.admin_user[0].country_host;
                this.setState({ update_manager_password: update_account_setting })
                this.setState({ school_form: update_boarding_head_form });
                this.setState({ school_form: update_school_form });
            } else {
            }
        });

    }
    inputChangeHandlerimage = (blob, identifiers) => {
        const src = window.URL.createObjectURL(blob);
        let url = URL.createObjectURL(blob);
        let file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type })
        if (identifiers === "avatar") {
            const update_boarding_head_form = { ...this.state.boarding_head_form };
            update_boarding_head_form[identifiers].thumbnail_image = url;
            update_boarding_head_form[identifiers].value = file;
            this.setState({ boarding_head_form: update_boarding_head_form });
        } else {
            const update_school_form = { ...this.state.school_form };
            update_school_form[identifiers].thumbnail_image = url;
            update_school_form[identifiers].value = file;
            this.setState({ school_form: update_school_form });
        }
        this.setState({ OpenImageModal: !this.state.OpenImageModal })
    };

    setIdentifiers = (value) => {
        this.setState({ OpenImageModal: !this.state.OpenImageModal })
        this.setState({ identifiers: value })
    }

    inputChangeHandler(event, identifier) {
        const update_school_form = { ...this.state.school_form };
        const form_element = { ...update_school_form[identifier] };
        // form_element.value = event.target.value;

        if (identifier === 'contact_one') {

            form_element.value = event;
        }
        else if (identifier === 'contact_two') {

            form_element.value = event;
        }
        else if (identifier === "other_contact_1_contact") {
            form_element.value = event;
        } else if (identifier === "other_contact_2_contact") {
            form_element.value = event;
        }
        else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_school_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_school_form) {
            is_form_valid = update_school_form[identifier].valid && is_form_valid;
        }
        this.setState({
            school_form: update_school_form,
            is_form_valid: is_form_valid,
        });
    }

    inputChangeHandler1(event, identifier) {
        const update_boarding_head_form = { ...this.state.boarding_head_form };
        const form_element = { ...update_boarding_head_form[identifier] };
        // form_element.value = event.target.value;

        if (identifier === 'contact_one') {

            form_element.value = event;
        }
        else if (identifier === 'contact_two') {

            form_element.value = event;
        }
        else {
            form_element.value = event.target.value;
        }

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_boarding_head_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_boarding_head_form) {
            is_form_valid = update_boarding_head_form[identifier].valid && is_form_valid;
        }
        this.setState({
            boarding_head_form: update_boarding_head_form,
            is_form_valid: is_form_valid,
        });
    }

    handleChange(data, identifier, type) {
        const update_school_form = { ...this.state.school_form };
        if (identifier === 'country_id') {
            this.GetAllStatesByCountryId(data.value);
            update_school_form['country'].value = data.label;
            update_school_form['country_code'].value = data.country_code.toLowerCase();
            this.GetAllTimezonesByCountryCode(data.country_code);
        }
        else if (identifier === 'state_id') {
            update_school_form['state'].value = data.label;
            this.GetAllCitiesByStateId(data.value);
        }
        else if (identifier === 'city_id') {
            update_school_form['city'].value = data.label;

        }
        update_school_form[identifier].value = data.value;
        this.setState({ school_form: update_school_form });
    }
    GetAllSelectedCountries = async (form_data) => {
        this.props.onGetAllSelectedCountries().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Country",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {

                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].name,
                        value: arrayData[key].country_id,
                        country_code: arrayData[key].country_code,
                    });
                }
            }
            update_school_form['country_id'].options = country_level;
            this.setState({ school_form: update_school_form });
        });
    }
    GetAllStatesByCountryId = async (CountryID) => {
        this.props.onGetAllStatesByCountryId(CountryID).then((response) => {
            let state_level = [];
            state_level.push({
                label: "State",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {

                const arrayData = response.data.rows;

                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        state_level.push({
                            label: arrayData[key].state_name,
                            value: arrayData[key].state_id,
                        });
                    }
                }
            }
            update_school_form['state_id'].options = state_level;
            this.setState({ school_form: update_school_form });
        });
    }
    GetAllCitiesByStateId = async (state_id) => {
        this.props.onGetAllCitiesByStateId(state_id).then((response) => {
            let City_level = [];
            City_level.push({
                label: "City",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {

                const arrayData = response.data.rows;

                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        City_level.push({
                            label: arrayData[key].city_name,
                            value: arrayData[key].city_id,
                        });
                    }
                }
            }
            update_school_form['city_id'].options = City_level;
            this.setState({ school_form: update_school_form });
        });
    }
    GetAllTimezonesByCountryCode = async (country_code) => {
        userActions.GetAllTimezonesByCountryCode(country_code).then((response) => {
            let state_level = [];
            state_level.push({
                label: "Time Zone",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {
                const arrayData = response.data;
                for (let key in arrayData) {
                    state_level.push({
                        label: arrayData[key].timezone,
                        value: arrayData[key].timezone,
                    });
                }
            }
            update_school_form['timezone'].options = state_level;
            this.setState({ school_form: update_school_form });
        });
    }
    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_school_form = this.state.school_form;
        for (let key in update_school_form) {
            let form_element = update_school_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_school_form[key] = form_element;
        }
        this.setState({ school_form: update_school_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.school_form) {
                form_data[key] = this.state.school_form[key].value;
            }
            this.props.onUpdateSchool(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/superadmin/school",
                        });
                    }, 500);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }

    };
    SubmitHandlerBoarding = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_boarding_head_form = this.state.boarding_head_form;
        for (let key in update_boarding_head_form) {
            let form_element = update_boarding_head_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_boarding_head_form[key] = form_element;
        }
        this.setState({ boarding_head_form: update_boarding_head_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.boarding_head_form) {

                form_data[key] = this.state.boarding_head_form[key].value;
            }
            this.props.onUpdateSchoolUser(form_data).then((response) => {
                if (response.success === true) {
                    console.log("hhh", form_data);
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: "/superadmin/school",
                        });
                    }, 500);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }
    };

    toggleShow(identifier) {
        if (identifier === "current_password") { this.setState({ current_password_hidden: !this.state.current_password_hidden }) };
        if (identifier === "new_password") { this.setState({ new_password__hidden: !this.state.new_password__hidden }) };
        if (identifier === "confirm_new_password") { this.setState({ confirm_new_password__hidden: !this.state.confirm_new_password__hidden }) };
    }

    passwordChangeHandler(event, identifier) {
        const update_account_setting = { ...this.state.update_manager_password }
        const form_element = { ...update_account_setting[identifier] };
        form_element.value = event.target.value;
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        update_account_setting[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_account_setting) {
            is_form_valid = update_account_setting[identifier].valid && is_form_valid;
        }
        this.setState({
            update_manager_password: update_account_setting,
        })
    }

    SubmitManagerPassword = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_account_setting = { ...this.state.update_manager_password }
        for (let key in update_account_setting) {
            let form_element = update_account_setting[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_account_setting[key] = form_element;
        }
        this.setState({ update_account_setting: update_account_setting });
        if (is_form_valid) {
            const update_account_setting = { ...this.state.update_manager_password }
            const form_data = {};
            form_data['password'] = update_account_setting['new_password'].value
            form_data['confirm_password'] = update_account_setting['confirm_new_password'].value
            form_data['user_uuid'] = update_account_setting['user_uuid'].value
            form_data['country_host'] = update_account_setting['country_host'].value
            form_data['school_code'] = update_account_setting['school_code'].value
            this.props.onUpdateSchoolUserPassword(form_data).then((response) => {
                if (response.success === true) {
                    // toast.success(response.message, {
                    //     position: toast.POSITION.TOP_CENTER,
                    // });
                    localStorage.setItem("toastMessage", response.message);
                    // setTimeout(() => {
                    this.setState({
                        isAuthenticated: true,
                        redirect_url: "/school",
                    });
                    // }, 500);
                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });

        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 27) {
            this.setState({ OpenImageModal: false })
        }
    };

    render() {
        const { school_form } = this.state;
        const { boarding_head_form } = this.state;
        const { update_manager_password } = this.state;
        return (
            <React.Fragment>
                {this.state.OpenImageModal &&
                    <div className="upload-image-outer-div" >
                        <span style={{ float: "right" }} className="pointer m-5" onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}>
                            <i class="fas fa-times fa-2x" style={{ color: "#ffffff", }}></i>
                        </span>

                        <div className="upload-image-inner-div">
                            <AvatarImageCropper
                                rootStyle={{ borderRadius: "50%" }}
                                cancelBtnStyle={{ display: "none" }}
                                apply={(blob) => this.inputChangeHandlerimage(blob, this.state.identifiers)}
                                className={"image_crop_style"}
                                sliderConStyle={{ margin: "20px", backgroundColor: "none" }}
                                applyBtnStyle={{}}
                                isBack={true} />
                        </div>
                        <div className="text-center mt-10">
                            <span className="text-white font-weight-bold">The image maximum size is 2MB. Please upload png/jpg/jpeg image</span>
                        </div>
                    </div>
                }

                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}


                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <SuperAdminSideMenu />
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <SuperAdminTopbar />
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        {/* <!--begin::Container--> */}
                                        <div id="kt_content_container" className="container-fluid">
                                            <ul className="nav nav-tabs home-tabs fontweight" id="myTab" role="tablist">
                                                <li className="nav-item me-6" role="presentation">
                                                    <button className="nav-link active" id="StudentProfile-tab" data-bs-toggle="tab" data-bs-target="#StudentProfile" type="button" role="tab" aria-controls="home" aria-selected="true">
                                                        School Profile
                                                    </button>
                                                </li>

                                                <li className="nav-item me-6" role="presentation">
                                                    <button className="nav-link" id="BoardingDetail-tab" data-bs-toggle="tab" data-bs-target="#BoardingDetail" type="button" role="tab" aria-controls="process" aria-selected="false">
                                                        School Admin Details
                                                    </button>
                                                </li>
                                            </ul>



                                            <div className="tab-content" id="myTabContent">


                                                <div className="tab-pane fade show active" id="StudentProfile" role="tabpanel" aria-labelledby="StudentProfile-tab">
                                                    <form onSubmit={this.SubmitHandler}>


                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                {/* <!--begin::Card title--> */}
                                                                <h3 className="card-title text-gray-800 fw-bolder m-0">School Profile</h3>
                                                                {/* <div className="card-toolbar">
                                                                    <span className="view-id ms-4" >ID</span>
                                                                </div> */}
                                                            </div>
                                                            <div className="card-body">

                                                                <div className="row">

                                                                    <div className="col-lg-6 fv-row mb-5">
                                                                        <div className="row">
                                                                            <div className="col-sm-8">
                                                                                <h4 className="fw-bold mb-2 ">School Logo</h4>
                                                                                {/* <label className="fw-bold mb-2">Upload file</label> */}
                                                                                <div className="profile-image-out p-0 p-0">
                                                                                    <img alt="profile" src={school_form.school_logo.thumbnail_image} className="img-fluid" />
                                                                                    <div className="d-flex justify-content-center h-100 flex-column align-items-center">
                                                                                        <br />
                                                                                        <br />
                                                                                        {/* <p className="text-center">Please attach jpg or png file</p> */}
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-sm btn-outline-primary px-6 rounded-50 "
                                                                                            onClick={() => this.setIdentifiers("school_logo")}
                                                                                        >
                                                                                            Upload Logo
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-lg-6">
                                                                        <div className="row">

                                                                            <div className="col-lg-6 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.school_name.label} </label>

                                                                                <input
                                                                                    type={school_form.school_name.type}
                                                                                    value={school_form.school_name.value ? school_form.school_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={school_form.school_name.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "school_name")}
                                                                                />

                                                                                {!school_form.school_name.valid && school_form.school_name.onBlur_out ? <div className="error field-error">{school_form.school_name.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.actual_school_code.label}</label>
                                                                                <input
                                                                                    type={school_form.actual_school_code.type}
                                                                                    className="form-control"
                                                                                    value={school_form.actual_school_code.value ? school_form.actual_school_code.value : ""}
                                                                                    placeholder={school_form.actual_school_code.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "actual_school_code")}
                                                                                    readOnly
                                                                                />

                                                                                {!school_form.actual_school_code.valid && school_form.actual_school_code.onBlur_out ? <div className="error field-error">{school_form.actual_school_code.error_msg}</div> : ""}
                                                                            </div>



                                                                            <div className="col-lg-6 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Zip Code *</label>
                                                                                <input
                                                                                    type={school_form.zipcode.type}
                                                                                    value={school_form.zipcode.value ? school_form.zipcode.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={school_form.zipcode.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "zipcode")}
                                                                                />

                                                                                {!school_form.zipcode.valid && school_form.zipcode.onBlur_out ? <div className="error field-error">{school_form.zipcode.error_msg}</div> : ""}
                                                                            </div>



                                                                            <div className="col-lg-6 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.country_id.label}</label>

                                                                                <input type="text" name="fname" className="form-control" readOnly
                                                                                    value={school_form.country.value ? school_form.country.value : ""}

                                                                                />



                                                                                {!school_form.country_id.valid && school_form.country_id.onBlur_out ? <div className="error field-error">{school_form.country.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.state_id.label}</label>

                                                                                <Select
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={school_form.state_id.options}
                                                                                    value={school_form.state_id.options.filter(function (option) {
                                                                                        return option.value === school_form.state_id.value;
                                                                                    })}
                                                                                    onChange={(newValue) => this.handleChange(newValue, "state_id", "weekend_day from value")}
                                                                                />

                                                                                {!school_form.state_id.valid && school_form.state_id.onBlur_out ? <div className="error field-error">{school_form.state.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{school_form.city_id.label}</label>
                                                                                <Select
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={school_form.city_id.options}
                                                                                    value={school_form.city_id.options.filter(function (option) {
                                                                                        return option.value === school_form.city_id.value;
                                                                                    })}
                                                                                    onChange={(newValue) => this.handleChange(newValue, "city_id", "weekend_day from value")}
                                                                                />

                                                                                {!school_form.city_id.valid && school_form.city_id.onBlur_out ? <div className="error field-error">{school_form.city.error_msg}</div> : ""}
                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">Physical Address *</label>
                                                                        <input
                                                                            type={school_form.school_address.type}
                                                                            value={school_form.school_address.value ? school_form.school_address.value : ""}
                                                                            className="form-control"
                                                                            placeholder={school_form.school_address.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "school_address")}
                                                                        />

                                                                        {!school_form.school_address.valid && school_form.school_address.onBlur_out ? (
                                                                            <div className="error field-error">{school_form.school_address.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>




                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">Time Zone *
                                                                        </label>
                                                                        <input type="text" name="fname" className="form-control" readOnly
                                                                            value={school_form.timezone.value ? school_form.timezone.value : ""}

                                                                        />


                                                                        {!school_form.timezone.valid && school_form.timezone.onBlur_out ? <div className="error field-error">{school_form.city.error_msg}</div> : ""}

                                                                    </div>


                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.highest_class.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.highest_class.options}
                                                                            value={school_form.highest_class.options.filter(function (option) {
                                                                                return option.value === school_form.highest_class.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "highest_class")}
                                                                        />

                                                                        {!school_form.highest_class.valid && school_form.highest_class.onBlur_out ? <div className="error field-error">{school_form.highest_class.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row mb-5np">
                                                                        <label className="fw-bold mb-2">School Email Domain *</label>
                                                                        <input type="text" name="fname" className="form-control" readOnly
                                                                            value={school_form.domain_name.value ? school_form.domain_name.value : ""}
                                                                        />
                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <h6 className="mb-5">Other School Contact 1</h6>
                                                                    <hr className=""></hr>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_1_name.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_1_name.type}
                                                                            styles={customStyles}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_1_name.value ? school_form.other_contact_1_name.value : ""}
                                                                            onChange={(newValue) => this.inputChangeHandler(newValue, "other_contact_1_name")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_1_position.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_1_position.type}
                                                                            styles={customStyles}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_1_position.value ? school_form.other_contact_1_position.value : ""}
                                                                            onChange={(newValue) => this.inputChangeHandler(newValue, "other_contact_1_position")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_1_contact.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            value={school_form.other_contact_1_contact.value ? school_form.other_contact_1_contact.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "other_contact_1_contact")}
                                                                            enableLongNumbers={true}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_1_email.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_1_email.type}
                                                                            styles={customStyles}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_1_email.value ? school_form.other_contact_1_email.value : ""}
                                                                            onChange={(newValue) => this.inputChangeHandler(newValue, "other_contact_1_email")}
                                                                        />
                                                                    </div>
                                                                </div>



                                                                <div className="row">
                                                                    <h6 className="mb-5">Other School Contact 2</h6>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_2_name.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_2_name.type}
                                                                            styles={customStyles}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_2_name.value ? school_form.other_contact_2_name.value : ""}
                                                                            onChange={(newValue) => this.inputChangeHandler(newValue, "other_contact_2_name")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_2_position.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_2_position.type}
                                                                            styles={customStyles}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_2_position.value ? school_form.other_contact_2_position.value : ""}
                                                                            onChange={(newValue) => this.inputChangeHandler(newValue, "other_contact_2_position")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_2_contact.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            value={school_form.other_contact_2_contact.value ? school_form.other_contact_2_contact.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "other_contact_2_contact")}
                                                                            enableLongNumbers={true}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_2_email.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_2_email.type}
                                                                            styles={customStyles}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_2_email.value ? school_form.other_contact_2_email.value : ""}
                                                                            onChange={(newValue) => this.inputChangeHandler(newValue, "other_contact_2_email")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h3 className="card-title text-gray-800 fw-bolder m-0">Weekend Details</h3>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2 d-flex">{school_form.weekend_day_from.label}
                                                                            <Tooltip title="Define “Weekend Start” & “Weekend Finish” on this School Leave system" placement="top">
                                                                                <i className="fas fa-question-circle ms-2"></i>
                                                                            </Tooltip>
                                                                        </label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            name={"weekend_day_from"}
                                                                            className="react-bootstrap-typeahead tag-outer "
                                                                            options={school_form.weekend_day_from.options}
                                                                            value={school_form.weekend_day_from.options.filter(function (option) {
                                                                                return option.value === school_form.weekend_day_from.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "weekend_day_from", "weekend_day from value")}
                                                                        />

                                                                        {!school_form.weekend_day_from.valid && school_form.weekend_day_from.onBlur_out ? <div className="error field-error">{school_form.weekend_day_from.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">Weekend start Time *</label>
                                                                        <input type="time" name="fname" className="form-control time-input"
                                                                            placeholder="13:30"
                                                                            value={school_form.weekend_time_from.value ? school_form.weekend_time_from.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "weekend_time_from")}
                                                                        />
                                                                        {!school_form.weekend_time_from.valid && school_form.weekend_time_from.onBlur_out ? <div className="error field-error">{school_form.weekend_time_from.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.weekend_day_to.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.weekend_day_to.options}
                                                                            value={school_form.weekend_day_to.options.filter(function (option) {
                                                                                return option.value === school_form.weekend_day_to.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "weekend_day_to", "weekend_day to value")}
                                                                        />

                                                                        {!school_form.weekend_day_to.valid && school_form.weekend_day_to.onBlur_out ? <div className="error field-error">{school_form.weekend_day_to.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">Weekend Finish Time *</label>
                                                                        <input type="time" name="fname" className="form-control time-input"
                                                                            value={school_form.weekend_time_to.value ? school_form.weekend_time_to.value : ""}
                                                                            placeholder="13:30"
                                                                            onChange={(event) => this.inputChangeHandler(event, "weekend_time_to")}
                                                                        />
                                                                        {!school_form.weekend_time_to.valid && school_form.weekend_time_to.onBlur_out ? <div className="error field-error">{school_form.weekend_time_to.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-5">
                                                                        <label className="fw-bold mb-2 d-flex">{school_form.cut_off_day.label}

                                                                            <Tooltip title="For Boarders to submit their Leave Applications" placement="top">
                                                                                <i className="fas fa-question-circle ms-2"></i>
                                                                            </Tooltip>
                                                                        </label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.cut_off_day.options}
                                                                            value={school_form.cut_off_day.options.filter(function (option) {
                                                                                return option.value === school_form.cut_off_day.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "cut_off_day", "weekend_day to value")}
                                                                        />

                                                                        {!school_form.cut_off_day.valid && school_form.cut_off_day.onBlur_out ? <div className="error field-error">{school_form.cut_off_day.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-5">
                                                                        <label className="fw-bold mb-2 d-flex">Cut off time *

                                                                            <Tooltip title="For Boarders to submit their Leave Applications" placement="top">
                                                                                <i className="fas fa-question-circle ms-2"></i>
                                                                            </Tooltip>
                                                                        </label>

                                                                        <input type="time" name="fname" className="form-control time-input"
                                                                            value={school_form.cut_off_time.value ? school_form.cut_off_time.value : ""}
                                                                            placeholder="13:30"
                                                                            onChange={(event) => this.inputChangeHandler(event, "cut_off_time")}
                                                                        />
                                                                        {!school_form.cut_off_time.valid && school_form.cut_off_time.onBlur_out ? <div className="error field-error">{school_form.cut_off_time.error_msg}</div> : ""}
                                                                    </div>



                                                                    <div className="col-lg-6 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.session_start_month.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.session_start_month.options}
                                                                            value={school_form.session_start_month.options.filter(function (option) {
                                                                                return option.value === school_form.session_start_month.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "session_start_month")}
                                                                        />

                                                                        {!school_form.session_start_month.valid && school_form.session_start_month.onBlur_out ? <div className="error field-error">{school_form.session_start_month.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.session_start_year.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.session_start_year.options}
                                                                            value={school_form.session_start_year.options.filter(function (option) {
                                                                                return option.value === school_form.session_start_year.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "session_start_year")}
                                                                        />

                                                                        {!school_form.session_start_year.valid && school_form.session_start_year.onBlur_out ? <div className="error field-error">{school_form.session_start_year.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.session_end_month.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.session_end_month.options}
                                                                            value={school_form.session_end_month.options.filter(function (option) {
                                                                                return option.value === school_form.session_end_month.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "session_end_month")}
                                                                        />

                                                                        {!school_form.session_end_month.valid && school_form.session_end_month.onBlur_out ? <div className="error field-error">{school_form.session_end_month.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.session_end_year.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.session_end_year.options}
                                                                            value={school_form.session_end_year.options.filter(function (option) {
                                                                                return option.value === school_form.session_end_year.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "session_end_year")}
                                                                        />

                                                                        {!school_form.session_end_year.valid && school_form.session_end_year.onBlur_out ? <div className="error field-error">{school_form.session_end_year.error_msg}</div> : ""}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>







                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px">
                                                                    Update School
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>


                                                <div className="tab-pane fade" id="BoardingDetail" role="tabpanel" aria-labelledby="BoardingDetail-tab">
                                                    <div>
                                                        <form onSubmit={this.SubmitHandlerBoarding}>
                                                            <div className="card mb-5 tabs-outer">
                                                                <div className="card-header position-relative py-0 details-bg d-flex align-items-center">
                                                                    <h3 className="card-title text-gray-800 fw-bolder m-0">School Admin Details</h3>
                                                                </div>

                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-lg-6 fv-row">
                                                                            <h4 className="fw-bold mb-2">School Admin Image

                                                                                <Tooltip title="Note: When adding an image or “Choose file” .jpg files tend to work best. Also to ‘fit’ the image, it is best to work with a square image, or at least the background should be square." placement="top">
                                                                                    <i className="fas fa-question-circle ms-2"></i>
                                                                                </Tooltip>

                                                                            </h4>

                                                                            <div className="row">
                                                                                <div className="col-sm-12 mb-5">
                                                                                    {/* <label className="fw-bold mb-2">Upload Image</label> */}
                                                                                    <div className="profile-image-out p-0 p-0">
                                                                                        <div className="row">
                                                                                            <div className="col-sm-8">
                                                                                                <img alt="profile" src={boarding_head_form.avatar.thumbnail_image} className="img-fluid" />
                                                                                                <br />
                                                                                                <br />
                                                                                                <div className="justify-content-center h-100 flex-column align-items-center">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-sm btn-outline-primary px-6 rounded-50 "
                                                                                                        onClick={() => this.setIdentifiers("avatar")}
                                                                                                    >
                                                                                                        Upload Image
                                                                                                    </button>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-6">
                                                                            <div className="row">
                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{boarding_head_form.first_name.label} </label>

                                                                                    <input
                                                                                        type={boarding_head_form.first_name.type}
                                                                                        value={boarding_head_form.first_name.value ? boarding_head_form.first_name.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={boarding_head_form.first_name.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler1(event, "first_name")}
                                                                                    />

                                                                                    {!boarding_head_form.first_name.valid && boarding_head_form.first_name.onBlur_out ? <div className="error field-error">{boarding_head_form.first_name.error_msg}</div> : ""}
                                                                                </div>


                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{boarding_head_form.last_name.label} </label>

                                                                                    <input
                                                                                        type={boarding_head_form.last_name.type}
                                                                                        value={boarding_head_form.last_name.value ? boarding_head_form.last_name.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={boarding_head_form.last_name.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler1(event, "last_name")}
                                                                                    />

                                                                                    {!boarding_head_form.last_name.valid && boarding_head_form.last_name.onBlur_out ? <div className="error field-error">{boarding_head_form.last_name.error_msg}</div> : ""}
                                                                                </div>


                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{boarding_head_form.contact_one.label}</label>
                                                                                    <ReactPhoneInput

                                                                                        value={boarding_head_form.contact_one.value ? boarding_head_form.contact_one.value : ""}
                                                                                        onChange={(event) => this.inputChangeHandler1(event, "contact_one")}
                                                                                        enableLongNumbers={true}

                                                                                    />

                                                                                    {!boarding_head_form.contact_one.valid && boarding_head_form.contact_one.onBlur_out ? <div className="error field-error">{boarding_head_form.contact_one.error_msg}</div> : ""}
                                                                                </div>

                                                                                <div className="col-lg-6 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{boarding_head_form.contact_two.label}</label>
                                                                                    <ReactPhoneInput

                                                                                        value={boarding_head_form.contact_two.value ? boarding_head_form.contact_two.value : ""}
                                                                                        onChange={(event) => this.inputChangeHandler1(event, "contact_two")}
                                                                                        enableLongNumbers={true}

                                                                                    />
                                                                                </div>

                                                                                <div className="col-lg-12 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">Primary Email * (Will be used for Admin Login)</label>
                                                                                    <input
                                                                                        type={boarding_head_form.email.type}
                                                                                        value={boarding_head_form.email.value ? boarding_head_form.email.value : ""}
                                                                                        className="form-control"
                                                                                        placeholder={boarding_head_form.email.placeholder}
                                                                                        onChange={(event) => this.inputChangeHandler1(event, "email")}
                                                                                        readOnly
                                                                                    />
                                                                                    {!boarding_head_form.email.valid && boarding_head_form.email.onBlur_out ? <div className="error field-error">{boarding_head_form.email.error_msg}</div> : ""}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 d-flex justify-content-end">
                                                                        <div className="d-flex align-items-end">
                                                                            <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px">
                                                                                Update Contact Detail
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                        </form>
                                                    </div>


                                                    <form onSubmit={this.SubmitManagerPassword}>
                                                        <div className="card">
                                                            <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
                                                                <h2 className="card-title m-0">Change Password</h2>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-4 mb-5">
                                                                        <label className="fw-bold mb-2">{update_manager_password.current_password.label} </label>
                                                                        <div className="position-relative">
                                                                            <input
                                                                                type={this.state.current_password_hidden ? 'password' : 'text'}
                                                                                value={update_manager_password.current_password.value ? update_manager_password.current_password.value : ""}
                                                                                className="form-control"
                                                                            />
                                                                            <span onClick={() => this.toggleShow("current_password")} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">
                                                                                {this.state.current_password_hidden ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}
                                                                            </span>
                                                                        </div>
                                                                        {!update_manager_password.current_password.valid && update_manager_password.current_password.onBlur_out ? (
                                                                            <div className="error field-error">{update_manager_password.current_password.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>

                                                                    <div className="col-lg-4 mb-5">
                                                                        <label className="fw-bold mb-2">{update_manager_password.new_password.label} </label>
                                                                        <div className="position-relative">
                                                                            <input
                                                                                type={this.state.new_password__hidden ? 'password' : 'text'}
                                                                                value={update_manager_password.new_password.value ? update_manager_password.new_password.value : ""}
                                                                                className="form-control"
                                                                                placeholder={update_manager_password.new_password.placeholder}
                                                                                onChange={(event) => this.passwordChangeHandler(event, "new_password")}
                                                                            />
                                                                            <span onClick={() => this.toggleShow("new_password")} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">
                                                                                {this.state.new_password__hidden ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}
                                                                            </span>
                                                                        </div>
                                                                        {!update_manager_password.new_password.valid ?
                                                                            (<div className="error field-error">{update_manager_password.new_password.error_msg}</div>)
                                                                            : ("")
                                                                        }
                                                                    </div>
                                                                    <div className="col-lg-4 mb-5">
                                                                        <label className="fw-bold mb-2">{update_manager_password.confirm_new_password.label} </label>
                                                                        <div className="position-relative">
                                                                            <input
                                                                                type={this.state.confirm_new_password__hidden ? 'password' : 'text'}
                                                                                value={update_manager_password.confirm_new_password.value ? update_manager_password.confirm_new_password.value : ""}
                                                                                className="form-control"
                                                                                placeholder={update_manager_password.confirm_new_password.placeholder}
                                                                                onChange={(event) => this.passwordChangeHandler(event, "confirm_new_password")}
                                                                            />
                                                                            <span onClick={() => this.toggleShow("confirm_new_password")} className="btn btn-icon position-absolute translate-middle top-50 end-0" data-kt-password-meter-control="visibility">
                                                                                {this.state.confirm_new_password__hidden ? <i className="bi bi-eye-slash fs-2"></i> : <i className="bi bi-eye fs-2"></i>}
                                                                            </span>
                                                                        </div>
                                                                        {!update_manager_password.confirm_new_password.valid ?
                                                                            (<div className="error field-error">{update_manager_password.confirm_new_password.error_msg}</div>)
                                                                            : ("")
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex justify-content-end">
                                                                    <button id="kt_password_submit" type="submit" className="btn btn-sm btn-primary apply-btn min-150px" >Update Password</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>






                                            </div>
                                        </div>
                                        {/* <!--end::Container--> */}
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div >

            </React.Fragment >
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetAllSelectedCountries: userActions.GetAllSelectedCountries,
    onGetAllStatesByCountryId: userActions.GetAllStatesByCountryId,
    onGetAllCitiesByStateId: userActions.GetAllCitiesByStateId,
    onUpdateSchool: userActions.UpdateSchool,
    onUpdateSchoolUser: userActions.UpdateSchoolUser,
    onGetSchoolById: userActions.GetSchoolById,
    onUpdateSchoolUserPassword: userActions.UpdateSchoolUserPassword,
};
export default connect(mapStateToProps, mapDispatchToProps)(SchoolEdit);