import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import Moment from "moment";
import { logout } from "../../actions";
import { Tooltip } from "@mui/material";
import CapitalFirstLetter from "../Utility/CapitalFirstLetter"


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info.school_code;

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const ManagerItem = (props) => {
    console.log("===============================",props);
    const [perpagedata, setperpagedata] = useState(10);
    const [columns] = useState([
        {
            text: "",
            dataField: "Actions",
            formatter: (cellContent, row, index, extraData) => {
                return (
                    <>
                        <div key={"repeat_" + row.country_id}>
                            <div className="card-1 p-6 mb-4 rounded bg-light-info">
                                <div className="d-flex flex-stack mb-3">
                                    <h3 className="mb-0 text-primary superadmin-headings"><Link to={`/${school_code}/manager-list/manager-profile/${row.user_uuid}`}>{CapitalFirstLetter(row.first_name) + " " + row.last_name}</Link> </h3>
                                    <div className="d-flex align-items-center">
                                        {row.is_user_activate === true ? (
                                            <span className="badge badge-light-success px-4 py-2">Active</span>
                                        ) : (
                                            <span className="badge badge-light-danger px-4 py-2">Inactive</span>
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex text-secondary align-items-center mb-3 flex-wrap">
                                    <div className="d-flex align-items-center">
                                        <Tooltip title="Boarding School Designation" placement="top">
                                            <i className="bi bi-person-square me-2 fs-6"></i>
                                        </Tooltip>
                                        <p className="mb-0 heding-element me-4 ">{row.designation} </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                     
                                        <Tooltip title="Manager email address" placement="top">
                                        <i className="bi bi-envelope me-2 fs-6"></i>
                                        </Tooltip>
                                        <p className="mb-0 heding-element me-4">{row.email} </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      
                                        <Tooltip title="Manager phone number" placement="top">
                                        <i className="fas fa-mobile-alt me-2 fs-6"></i>
                                        </Tooltip>
                                        <p className="mb-0 heding-element me-4">+{row.contact_one} </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                       
                                        <Tooltip title="Role assigned to manager" placement="top">
                                        <i class="fas fa-cog me-2 fs-6"></i>Role
                                        </Tooltip>
                                        <spna className="mx-1"> : </spna>
                                        <p className="mb-0 heding-element me-4">{row.role_data[0].role_name} </p>
                                    </div>
                                </div>
                                <p className="d-flex">Joining date {Moment(row.created_date).format("MMMM Do YYYY")} {row.is_user_activate === false ? <p className="d-flex mb-0                                          "> <span className="ps-2 pe-2 d-flex">|</span> Relieved date {Moment(row.last_archived_date).format("MMMM Do YYYY")}</p> : null} </p>
                                <div className="d-flex text-secondary align-items-center">
                                    <div className="d-flex align-items-center me-4">
                                        {permission_info?.edit_user !== false &&
                                            (<Link to={`/${school_code}/manager-list/edit-manager/${row.user_uuid}`}><i className="far fa-edit me-2 fs-6"></i>Edit Manager
                                            </Link>)}
                                    </div>
                                    {row && row.is_user_activate === true ? (
                                        (permission_info?.activate_deactivate_user !== false) &&
                                            (<div className="d-flex align-items-center"

                                                onClick={(event) => {
                                                    props.AddArchivepoup(event, row.first_name + " " + row.last_name, row.user_uuid,row.is_user_activate);
                                                }}
                                            >
                                                <span className="deactivate-button1" id={row.user_uuid} >
                                                    <i className="bi bi-bell-slash me-2 fs-6"></i>Archive Manager
                                                </span>
                                            </div>)
                                    ) : (
                                        (permission_info?.activate_deactivate_user !== false) &&
                                            (<div className="d-flex align-items-center"
                                            onClick={(event) => {
                                                props.AddUnarchivepoup(event, row.first_name + " " + row.last_name, row.user_uuid ,row.is_user_activate);
                                            }}
                                            >
                                                <span className="deactivate-button1" >

                                                    <i className="bi bi-bell me-2 fs-6"></i>Unarchive Manager
                                                </span>
                                            </div>)
                                    )}
                                </div>

                            </div>
                        </div>
                    </>
                );
            },
        },
    ]);
    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };
    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive common-table superadmin_table">
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={data}
                            keyField="student_uuid"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            sizePerPageList={sizePerPage}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-bordered align-middle custom-table-style table-fields"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>

                    <div className="d-flex flex-stack justify-content-between flex-wrap pt-3 pagination-main">

                        {totalSize > 0 && (
                            <div className="pagination_limt">
                                <select id='select' name='group' className='form-control input-sm form-select form-select-sm form-select-solid' size='1' value={perpagedata}
                                    onChange={(event) => {
                                        props.propsfilterpage(1);
                                        setperpagedata(event.target.value);
                                        props.propsfilterlimit(parseInt(event.target.value));
                                    }}
                                >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={250}>250</option>
                                    <option value={500}>500</option>
                                </select>
                                {(parseInt(sizePerPage) * parseInt(page)) > totalSize ? (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{totalSize} of {totalSize} records</div>
                                ) : (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{parseInt(sizePerPage) * parseInt(page)} of {totalSize} records</div>
                                )}
                            </div>
                        )}

                        <div className="pagination_page">
                            <PaginationListStandalone {...paginationProps} />
                        </div>
                    </div>
                </>
            )}
        </PaginationProvider>
    );
    return (
        <React.Fragment>
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-12">
        
                            <div className="table-responsive">
                                <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                                    <div className="table-responsive">
                                        <div className="mt-5 bg-white radious-style radious-users">
                                            {/* <Spin spinning={props.loading}> */}
                                            <RemotePagination
                                                data={props.data}
                                                columns={columns}
                                                page={props.filterpage}
                                                totalSize={props.ManagerListcount}
                                                sizePerPage={props.filterlimit}
                                                order={props.filterorder}
                                                onTableChange={handleTableChange}
                                            />
                                            {/* </Spin> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>
        </React.Fragment>
    );
};
export default ManagerItem;