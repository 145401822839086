import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import ReactPhoneInput from 'react-phone-input-2'
import { useNavigate } from "react-router-dom";
import Validations from "../../Utility/Validations";


const user_data = JSON.parse(localStorage.getItem("bss_authuser"))
const countary_code = user_data?.user_school?.country_code;

const AddStudentHostModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [addStudentHost, setAddStudentHost] = useState({
    host_address: {
      type: "text",
      label: "Host's Address *",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "Host's Address",
      validations: { required: true },
    },
    host_name: {
      type: "text",
      label: "Host Name *",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "Host Name",
      validations: { required: true },
    },
    host_relation: {
      type: "text",
      label: "Host Relation *",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "Host Relation",
      validations: { required: true },
    },
    is_host_approved: {
      type: "text",
      label: "Automatically approved as Host",
      value: true,
      valid: true,
      error_msg: "",
      validations: { required: false },
    },
    student_host_comment: {
      type: "text",
      label: "Student Host Comment",
      value: "",
      valid: true,
      error_msg: "Student Host Comment",
      validations: { required: false },
    },
    host_email: {
      type: "email",
      label: "Host's Email *",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "Host's Email",
      validations: { required: true, email: true },
    },
    country_code: {
      type: "hidden",
      label: "country_host",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "",
      validations: { required: false },
    },
    host_contact: {
      type: "text",
      label: "Host's Mobile Number *",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "Host's Mobile Number",
      validations: { required: true },
    },
    student_id: {
      type: "hidden",
      label: "student id",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "student id",
      validations: { required: false },
    },
    student_name: {
      type: "text",
      label: "Student Name",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "student id",
      validations: { required: false },
    },
  })

  useEffect(() => {
    if (props?.hostData?.host_name) {
      setAddStudentHost((prevState) => ({
        ...prevState,
        host_name: {
          ...prevState.host_name,
          value: props.hostData.host_name,
        },
        host_contact: {
          ...prevState.host_contact,
          value: props.hostData.host_contact
        },
        host_relation: {
          ...prevState.host_relation,
          value: props?.hostData?.stu_host?.host_relation
        },
        host_address: {
          ...prevState.host_address,
          value: props?.hostData?.host_address
        },
        student_host_comment: {
          ...prevState.student_host_comment,
          value: props?.hostData?.stu_host?.student_host_comment
        }
      }));
    }
  }, [props.hostData]);

  useEffect(() => {
    setShowModal(props.showAddHostModal)
    let student_data = props.applyBorderLeaveForm;
    const updateAddStudentHost = { ...addStudentHost }
    updateAddStudentHost['student_id'].value = student_data.student_id.value;
    updateAddStudentHost['student_name'].value = student_data.student_name.value;
    updateAddStudentHost['country_code'].value = countary_code;
    setAddStudentHost(updateAddStudentHost)
  }, [])

  const closeModal = () => {
    props.hideAddHostModal(false)
    props.setHostData()
  };

  const inputChangeHandler = (e, identifier) => {
    if(identifier === "host_email"){
      props?.GetStudentHostByHostEmail(e);
    }
    const update_student_host = { ...addStudentHost };
    update_student_host[identifier].value = e.target.value;
    setAddStudentHost(update_student_host)
  }

  const inputPhoneHandler = (e, identifier) => {
    const update_student_host = { ...addStudentHost };
    update_student_host[identifier].value = e;
    setAddStudentHost(update_student_host)
  }


  const SubmitHandlerHost = (event) => {
    event.preventDefault();
    let is_form_valid = true;
    const update_student_host = { ...addStudentHost };
    for (let key in update_student_host) {
      let form_element = update_student_host[key];
      let validate_element = Validations(form_element);
      is_form_valid = validate_element.valid === false ? false : is_form_valid;
      form_element.onBlur_out = true;
      form_element.valid = validate_element.valid;
      form_element.error_msg = validate_element.error_msg;
      update_student_host[key] = form_element;
    }
    setAddStudentHost(update_student_host)

    if (is_form_valid) {
      const form_data = {
        host_data: []
      };
      form_data['student_id'] = addStudentHost.student_id.value;
      const hostItem = {};
      for (let key in addStudentHost) {
        hostItem[key] = addStudentHost[key].value;
      }
      form_data['host_data'].push(hostItem);
      form_data['host_data'] = JSON.stringify(form_data['host_data'])
      props.AddOrUpdateStudentHost(form_data, addStudentHost.student_id.value)
    }
  }

  return (
    <div>
      <Modal size="lg" show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add New Host
          </Modal.Title>
        </Modal.Header>
       
          <form>
          <Modal.Body className="show-grid">
            <div className='row'>
            <div className="col-md-6 mb-4">
              <label className="form-label">Student Name</label>
              <input
                type={addStudentHost.student_name.type}
                value={addStudentHost.student_name.value}
                className="form-control"
                readOnly
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label">{addStudentHost.host_name.label}</label>
              <input
                type={addStudentHost.host_name.type}
                className="form-control"
                value={addStudentHost.host_name.value}
                disabled={!!props?.hostData?.host_name?.length}
                onChange={(e) => inputChangeHandler(e, "host_name")}
              />
              {!addStudentHost.host_name.valid && addStudentHost.host_name.onBlur_out ? <div className="error field-error">{addStudentHost.host_name.error_msg}</div> : ""}
            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label">{addStudentHost.host_email.label}</label>
              <input
                type={addStudentHost.host_email.type}
                className="form-control"
                onChange={(e) => inputChangeHandler(e, "host_email")}
              />
              {!addStudentHost.host_email.valid && addStudentHost.host_email.onBlur_out ? <div className="error field-error">{addStudentHost.host_email.error_msg}</div> : ""}

            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label">{addStudentHost.host_contact.label}</label>
              <ReactPhoneInput
                countryCodeEditable={false}
                country={addStudentHost.country_code.value ? addStudentHost.country_code.value : ""}
                value={addStudentHost.host_contact.value }
                disabled={!!props?.hostData?.host_contact?.length}
                onChange={(event) => inputPhoneHandler(event, "host_contact")}
                enableLongNumbers={true}
                autoComplete="off"
              />
              {!addStudentHost.host_contact.valid && addStudentHost.host_contact.onBlur_out ? <div className="error field-error">{addStudentHost.host_contact.error_msg}</div> : ""}

            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label">{addStudentHost.host_relation.label}</label>
              <input
                type={addStudentHost.host_relation.type}
                className="form-control"
                value={addStudentHost.host_relation.value }
                disabled={!!props?.hostData?.stu_host?.host_relation?.length}
                onChange={(e) => inputChangeHandler(e, "host_relation")}
              />
              {!addStudentHost.host_relation.valid && addStudentHost.host_relation.onBlur_out ? <div className="error field-error">{addStudentHost.host_relation.error_msg}</div> : ""}

            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label">{addStudentHost.host_address.label}</label>
              <input
                type={addStudentHost.host_address.type}
                className="form-control"
                value={ addStudentHost.host_address.value }
                disabled={!!props?.hostData?.host_address?.length}
                onChange={(e) => inputChangeHandler(e, "host_address")}
              />
              {!addStudentHost.host_address.valid && addStudentHost.host_address.onBlur_out ? <div className="error field-error">{addStudentHost.host_address.error_msg}</div> : ""}

            </div>
            {/* <div className="col-md-12">
              <label className="form-label">{addStudentHost.student_host_comment.label}</label>
              <input
                type={addStudentHost.student_host_comment.type}
                className="form-control"
                value={ addStudentHost.student_host_comment.value }
                disabled={!!props?.hostData?.stu_host?.student_host_comment?.length}
                onChange={(e) => inputChangeHandler(e, "student_host_comment")}
              />
            </div> */}
            </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type='button'
                class="btn btn-primary btn-sm"
                onClick={(e) => SubmitHandlerHost(e)}
              >
                Save Changes
              </button>
            </Modal.Footer>
          </form>
     
      </Modal>
    </div>
  )
}

export default AddStudentHostModal;