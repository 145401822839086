import React, { useEffect, useState } from 'react'
import Footer from "../../../Constant/Footer";
import SuperAdminTopbar from "../../topbar";
import SidemenuSchoolDetails from "../../SidemenuSchoolDetails";
import { connect } from 'react-redux';
import * as userActions from "../../../../actions/index";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SchoolActivities from './SchoolActivities';
import moment from 'moment';
import SchoolPayments from "./Payments/SchoolPayments"
import { Avatar, Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import SuperAdminTopMenu from "../../Sidemenu";
import CapitalFirstLetter from "../../../Utility/CapitalFirstLetter"
function SchoolDetails(props) {
  let { id } = useParams();
  const [schoolData, setSchoolData] = useState()
  const [logData, setLogsData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState();
  const [search, setSearch] = useState("");

  const GetSchoolDetailById = async () => {
    try {
      console.log(id, "testinguseffectcalling34567");
      const response = await props.OnGetSchoolDetailById(id);

      if (response.success === true) {
        console.log(response, "::response23456");
        setSchoolData(response.data);
        return response.data; // Return the school data for further use
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error("Error fetching school details:", error);
    }
  };

  const OnGetSchoolActivityDetails = async (schoolCode, countryHost, timezone) => {
    setIsLoading(true);
    try {
      const response = await props.OnGetSchoolActivityDetails(schoolCode, countryHost, page, limit, search);
      setCount(response?.count)
      if (response.success === true) {
        let filterLogArray = []
        const Alldata = response.data;
        for (let key in Alldata) {
          const date = moment(Alldata[key]?.created_date)?.tz(timezone)?.format('MMMM Do YYYY, h:mm a');
          // let arr = mangerAvatar.find((avatarItem) => {
          //   return avatarItem.user_id === Alldata[key].user_id
          // })
          // let newSentence = "Snapshot" === Alldata[key].html_info.slice(-13, -5) ? Alldata[key].html_info.replace(`href="/snapshot"`, `href="/${schoolCode}/snapshot"`) : Alldata[key].html_info;
          let newSentence = Alldata[key].html_info;
          filterLogArray.push({
            ...Alldata[key],
            html_info: newSentence,
            created_date: date,
            // thumbnail_image: arr === undefined ? "" : arr.thumbnail_image
          })
        }
        setIsLoading(false)
        setLogsData(filterLogArray);
      } else {
        setIsLoading(false)
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setIsLoading(false)
      console.error("Error fetching school activity details:", error);
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      const schoolDetails = await GetSchoolDetailById();
      if (schoolDetails) {
        const { school_code, country_host, timezone } = schoolDetails;
        if (timezone) {
          await OnGetSchoolActivityDetails(school_code, country_host, timezone);
        } else {
          console.error("Error: Timezone is undefined.");
        }
      }
    };

    fetchDetails();
  }, [id, page, limit, search]); // Dependency array to rerun when `id` changes

  console.log("https://bssnew.dikonia.in/" + schoolData?.school_logo,"::schoolData34567")
  return (
    <>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <SuperAdminTopMenu />
            {/* <SidemenuSchoolDetails schoolData={schoolData} /> */}
            <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
              <SuperAdminTopbar></SuperAdminTopbar>
              <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="post d-flex flex-column-fluid" id="kt_post">
                  {/* <!--begin::Container--> */}
                  <div id="kt_content_container" className="container-fluid">
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <ul className="nav nav-tabs home-tabs fontweight" id="myTab" role="tablist">
                        <li className="nav-item me-6" role="presentation">
                          <button className="nav-link active" id="school_information-tab" data-bs-toggle="tab" data-bs-target="#school_information" type="button" role="tab" aria-controls="process" aria-selected="false">
                            School Information
                          </button>
                        </li>
                        <li className="nav-item me-6" role="presentation">
                          <button className="nav-link" id="BoardingDetail-tab" data-bs-toggle="tab" data-bs-target="#BoardingDetail" type="button" role="tab" aria-controls="process" aria-selected="false">
                            Payments
                          </button>
                        </li>
                        <li className="nav-item me-6" role="presentation">
                          <button className="nav-link" id="StudentProfile-tab" data-bs-toggle="tab" data-bs-target="#StudentProfile" type="button" role="tab" aria-controls="home" aria-selected="true">
                            School Activities
                          </button>
                        </li>
                      </ul>
                      <Box sx={{display:"flex", alignItems:"center", gap:"10px", marginRight:"7px"}}>
                        <Typography>{CapitalFirstLetter(schoolData?.school_name)}</Typography>
                        <Avatar alt={CapitalFirstLetter(schoolData?.school_name)} src={"https://bssnew.dikonia.in/" + schoolData?.school_logo} />
                      </Box>
                    </Box>

                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="school_information" role="tabpanel" aria-labelledby="school_information-tab">
                        <SidemenuSchoolDetails schoolData={schoolData} />
                      </div>
                      <div className="tab-pane fade" id="BoardingDetail" role="tabpanel" aria-labelledby="BoardingDetail-tab">
                        <SchoolPayments schoolData={schoolData} />
                      </div>
                      <div className="tab-pane fade" id="StudentProfile" role="tabpanel" aria-labelledby="StudentProfile-tab">
                        <SchoolActivities logData={logData} count={count} setSearch={setSearch} page={page} setPage={setPage} limit={limit} setLimit={setLimit} />
                      </div>
                    </div>
                  </div>
                  {/* <!--end::Container--> */}
                </div>
              </div>
              <Footer></Footer>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

// export default SchoolDetails

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  OnGetSchoolDetailById: userActions.GetSchoolDetailById,
  OnGetSchoolActivityDetails: userActions.GetSchoolActivityDetails,
};
export default connect(mapStateToProps, mapDispatchToProps)(SchoolDetails);