import React, { useEffect, useState } from "react";
import Select from "react-select";
import Validations from "../../Utility/Validations";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ImportCsvModal from "./ImportCsvModal";
import ApprovedCsvModal from "./ImportCsvModal"
import { Modal } from "react-bootstrap";
import { filterBreakpointKeys } from "@mui/system/Unstable_Grid/traverseBreakpoints";
import { TailSpin } from 'react-loader-spinner'


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;
const StudentImport = (props) => {
    const [showLoader, setShowLoader] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showModal2, setShowModal2] = useState(false)
    const [errorArray, setErrorArray] = useState([])
    const [dataArray, setDataArray] = useState([])
    const [filterCsv, setFilterCsv] = useState([])
    const [confirmData, setCofirmData] = useState([])
    const [understand, setUnderstand] = useState(false)
    const [filterCsvData, setFilterCsvData] = useState([])
    const [csv_student_details, setCsv_student_details] = useState([])
    const [countryPhoneCode, setCountryPhoneCode] = useState()
    const [schoolCountaryName, setSchoolCountaryNmae] = useState('')
    const [country_phone_list, setCountry_phone_list] = useState([])

    const [import_student_form, setImport_student_form] = useState({

        class_id: {
            type: "select",
            label: "Select class *",
            value: "",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "",
            validations: { required: true },
            options: [],
        },

        class_name: {
            type: "text",
            label: "Select class *",
            value: "",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "",
            validations: { required: true },
            options: [],
        },

        dormitory_name: {
            type: "text",
            label: "Select Domitory *",
            value: "",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "",
            validations: { required: true },
        },

        dormitory_id: {
            type: "select",
            label: "Select Dormitory *",
            value: "",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "",
            validations: { required: true },
            options: [],
        },

        students_csv: {
            type: "file",
            label: "File",
            value: '',
            valid: true,
            error_msg: "",
            validations: { required: false },
            thumbnail_image: "/assets/media/school-logo.png",
        },

        school_name: {
            type: "hidden",
            label: "",
            value: "",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "",
            validations: { required: true },

        },

        school_detail_id: {
            type: "hidden",
            label: "",
            value: "",
            valid: true,
            error_msg: "",
            onBlur_out: false,
            placeholder: "",
            validations: { required: true },


        },
    }
    )

    const navigate = useNavigate();

    useEffect(() => {
        GetAllDormitoryImport();
        GetAllClassesDetailsImport();
        GetAllCountriesForImportStudents();
    }, [])

    const GetAllDormitoryImport = async (form_data) => {
        props.onGetAllDormitoryImport().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Dormitory",
                value: "",
            });
            const update_import_student_form = { ...import_student_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].dormitory_status === true) {
                        country_level.push({
                            label: arrayData[key].dormitory_name,
                            value: arrayData[key].dormitory_id,
                            // dormitory_id_name: arrayData[key].dormitory_id_name,  
                        });
                    }
                }
            }
            update_import_student_form['dormitory_id'].options = country_level;
            setImport_student_form(update_import_student_form);
        });
    }


    const GetAllClassesDetailsImport = async (form_data) => {
        props.onGetAllClassesDetailsImport().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Class",
                value: "",
            });
            const update_import_student_form = { ...import_student_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_class_activate === true) {
                        country_level.push({
                            label: arrayData[key].class_name,
                            value: arrayData[key].class_id,
                            class_name: arrayData[key].class_name,
                        });
                    }
                }
            }
            update_import_student_form['class_id'].options = country_level;
            setImport_student_form(update_import_student_form);
        });
    }

    const GetImportStudentDetails = async (event, identifier) => {
        var file = event.target.files[0];
        let payload = { students_csv: file }
        props.onGetImportStudentDetails(payload).then((response) => {
            console.log("response============", response);
            let arrayData = []
            if (response.success === true) {
                setShowModal(!showModal)
                // const errors = response.error;
                const impStuData = response.data;
                for (let key in impStuData) {
                    arrayData.push(impStuData[key])
                }
                // const errorData = errors.reduce((acc, value) => {
                //     let val = value.slice(-5)
                //     if (!acc[val]) {
                //         acc[val] = []
                //         acc[val].push(value)
                //     } else {
                //         acc[val].push(value)
                //     }
                //     return acc
                // }, {})
                // const filterErrorArray = []
                // for (let key in errorData) {
                //     filterErrorArray.push(errorData[key])
                // }
                // setErrorArray(filterErrorArray)
                console.log(arrayData,response,"::ASdasd")
                setCsv_student_details(arrayData)
            }else{
                showModalHandler(false)
                toast.error(response.message || response.error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }
    const GetAllCountriesForImportStudents = async () => {
        const local_country_id = JSON.parse(localStorage.getItem("bss_authuser")).user_school.country_id;
        props.onGetAllCountriesForImportStudents().then((response) => {
            if (response.success === true) {
                const countryData = response.data.rows;
                let country_list = []
                for (let key in countryData) {
                    country_list.push({ name: countryData[key].name, phone_code: countryData[key].phone_code })
                }
                const filterCountryData = countryData.filter(item => item.country_id === local_country_id)
                console.log("GetAllCountriesForImportStudents", filterCountryData);
                setCountryPhoneCode(filterCountryData[0].phone_code)
                setSchoolCountaryNmae(filterCountryData[0].name)
                setCountry_phone_list(country_list)
            }
        });
    }


    const handleChange = (data, identifier, type) => {
        const update_import_student_form = { ...import_student_form };
        if (identifier === 'dormitory_id') {
            update_import_student_form['dormitory_name'].value = data.label;
        }
        if (identifier === 'class_id') {
            update_import_student_form['class_name'].value = data.label;
        }
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        update_import_student_form['school_name'].value = user_info.user_school.school_name;
        update_import_student_form['school_detail_id'].value = user_info.school_detail_id;
        update_import_student_form[identifier].value = data.value;
        setImport_student_form(update_import_student_form);
    }




    const SubmitHandlerData = async (data) => {
        let is_form_valid = true;
        const update_import_student_form = import_student_form;
        for (let key in update_import_student_form) {
            let form_element = update_import_student_form[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_import_student_form[key] = form_element;
        }
        setImport_student_form(update_import_student_form);
        if (is_form_valid) {
            const form_data = {};
            let country_code_error = [];

            for (let key in import_student_form) {
                if (key !== "students_csv") {
                    form_data[key] = import_student_form[key].value;
                }
            }
            const csv_data_phone_code = filterCsvData.map((item) => {
                let father_phone_code = countryPhoneCode;
                let father_countary_name = schoolCountaryName;
                console.log("countryPhoneCode------", countryPhoneCode);
                if (item.father_country.toLowerCase() === "america" || item.father_country.toLowerCase() === "usa" || item.father_country.toLowerCase() === "united states" || item.father_country.toLowerCase() === "canada") {
                    father_phone_code = 1
                } else if (item.father_country.toLowerCase() === "united kingdom" || item.father_country.toLowerCase() === "uk" || item.father_country.toLowerCase() === "england" || item.father_country.toLowerCase() === "britian") {
                    father_phone_code = 44
                } else if (item.father_country === "") {
                    father_phone_code = countryPhoneCode
                } else {
                    const result = country_phone_list.find((obj) => {
                        return obj.name.toLowerCase() === item.father_country.toLowerCase()
                    });
                    console.log("result.phone_code", result);
                    if (result == undefined) {
                        // country_code_error.push(item.father_country)
                        father_phone_code = countryPhoneCode
                    } else {
                        father_phone_code = result.phone_code

                    }
                }

                let mother_phone_code = countryPhoneCode
                let mother_countary_name = schoolCountaryName;
                if (item.mother_country.toLowerCase() === "america" || item.mother_country.toLowerCase() === "usa" || item.mother_country.toLowerCase() === "united states" || item.mother_country.toLowerCase() === "canada") {
                    mother_phone_code = 1
                } else if (item.mother_country.toLowerCase() === "united kingdom" || item.mother_country.toLowerCase() === "uk" || item.mother_country.toLowerCase() === "england" || item.father_country.toLowerCase() === "britian") {
                    mother_phone_code = 44
                } else if (item.mother_country === "") {
                    mother_phone_code = countryPhoneCode
                } else {
                    const result = country_phone_list.find((obj) => {
                        return obj.name.toLowerCase() === item.mother_country.toLowerCase()
                    });
                    if (result == undefined) {
                        // country_code_error.push(item.mother_country)
                        mother_phone_code = countryPhoneCode
                    } else {
                        mother_phone_code = result.phone_code
                    }
                }
                return (
                    {
                        ...item,
                        father_country: father_phone_code == countryPhoneCode ? schoolCountaryName : item.father_country,
                        mother_country: mother_phone_code == countryPhoneCode ? schoolCountaryName : item.mother_country,
                        student_phone: countryPhoneCode + item.student_phone,
                        father_home_phone: father_phone_code + item.father_home_phone,
                        father_mobile_cell: father_phone_code + item.father_mobile_cell,
                        father_work_phone: father_phone_code + item.father_work_phone,
                        mother_mobile_cell: mother_phone_code + item.mother_mobile_cell,
                        mother_home_phone: mother_phone_code + item.mother_home_phone,
                        mother_work_phone: mother_phone_code + item.mother_work_phone,
                    }
                )
            })
            console.log("csv_data_phone_code", csv_data_phone_code);
            form_data['csv_student_details'] = JSON.stringify(csv_data_phone_code)
            if (understand === true) {
                // if (country_code_error.length === 0) {
                setShowLoader(true)
                props.onImportStudentsCsv(form_data).then((response) => {
                    if (response.success === true) {
                        setShowModal2(false)
                        setShowLoader(false)
                        console.log("response.message", response.message);
                        toast.success(response.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    } else {
                        setShowLoader(false)
                        toast.error(response.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                });
                // } else {
                //     toast.error(`(${country_code_error.country.join(' , ')}) These countries don't exist`, {
                //         position: toast.POSITION.TOP_CENTER,
                //     });
                // }
            } else {
                toast.error("To proceed with importing students, please tick the box.", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } else {
            toast.error("Select Dormitory and Year first", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };


    const showModalHandler = (value) => {
        setShowModal(value)
        const file = document.querySelector('.CSV_file');
        file.value = '';
    }

    const openSecondModal = (data) => {
        if (showModal) { setShowModal(false) };
        setShowModal2(true)
        const filterData = []
        data.forEach((item) => {
            filterData.push(item.valid)
        })
        setErrorArray(filterData)
        setFilterCsvData(data)
        const file = document.querySelector('.CSV_file');
        file.value = '';
    };
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const cancleApproveModal = () => {
        setShowModal2(false)
    }

    console.log(import_student_form?.class_id?.value && import_student_form?.dormitory_id?.value,":34567:fhgvbasdf" )
    return (
        <>
            {showLoader &&
                <div style={{ backdropFilter: "blur(10px)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "99999999", position: "fixed", top: 0, left: 0, right: 0, bottom: 0, background: "#b8b8b82b" }}>
                    <div >
                        <TailSpin
                            visible={showLoader}
                            height="100"
                            width="100"
                            color="#3693d0"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>

                </div>
            }
            {showModal && <ImportCsvModal
                showModal={showModal}
                csv_student_details={csv_student_details}
                setCsv_student_details={setCsv_student_details}
                import_student_form={import_student_form}
                showModalHandler={(value) => showModalHandler(value)}
                openSecondModal={openSecondModal}
                onGetImportStudentDetails = {props.onGetImportStudentDetails}
                onGetReviewAgainImportStudents= {props.onGetReviewAgainImportStudents}
            />}

            {showModal2 === true &&
                <div style={{ position: "fixed", width: "100%", minHeight: "100%", zIndex: "999", backgroundColor: "#0000097a", left: 0, top: 0 }}>
                    <div className="position-relative py-0 details-bg">
                        <div className="row">
                            <div className="col-8 m-auto">
                                <div className="card my-5 tabs-outer">
                                    <div className="card-header position-relative py-0 details-bg">
                                        <h2 className="card-title  m-0">Import Student Alert</h2>
                                    </div>
                                    <div>
                                        <div className="card-body position-relative py-5">
                                            {errorArray.includes(false) ?
                                                <div className="">
                                                    <div className="d-flex">
                                                        <spn className="me-4"><i class="fas fa-exclamation-triangle fa-lg" style={{ color: "#fa0000a3" }}></i></spn>
                                                        <span><h4>1 of 4 possible errors have likely occurred:-</h4></span>
                                                    </div>

                                                    <p>
                                                        1)  Insufficient data has been supplied in this .csv file.  Please check .csv file for content or format errors and re-upload it. Names marked in RED indicate incomplete ESSENTIAL information. (Not all information is essential)
                                                    </p>
                                                    <p>
                                                        2)  The student has identifying data that has been duplicated.  This may be Cell No, email address or Name.
                                                    </p>
                                                    <p>
                                                        3)  You may have not followed EXACT word format instructions.  Eg Date format or spelling format, including correct use of Capital Letters.
                                                    </p>
                                                    <p>
                                                        4)  You may have simply not selected “Class” or “Dormitory” from the selection options to associate with these students.
                                                    </p>


                                                    {/* <p className="m-4">Insufficient data has been supplied in this .csv file for the system to assist in managing one or more students.  Please check .csv file and re-upload it. The entries marked in RED are the ones having incomplete information.</p> */}
                                                </div>
                                                :

                                                <div>
                                                    <p className="text-secondary">For this import minimal information has been supplied, for full functionality additional information would need to be added at some point.  This may be Parent Contact Details or other information that has been omitted from the CSV file data, at this stage.  </p>
                                                    <div className="my-5">
                                                        <label className="checkbox cursor-pointer ">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input me-1"
                                                                value={understand}
                                                                checked={understand}
                                                                onChange={(e) => setUnderstand(e.target.checked)}
                                                            />
                                                            <span className="mb-0">
                                                                I understand
                                                            </span>
                                                        </label>
                                                    </div>

                                                </div>
                                            }



                                        </div>
                                        <div className="card-footer py-5 px-10">
                                            <div className="d-flex justify-content-end">
                                                {
                                                    errorArray.includes(false) ? "" :
                                                        <button className="btn btn-sm btn-primary apply-btn min-150px me-5" onClick={() => SubmitHandlerData()}>Submit</button>
                                                }

                                                <button className="btn btn-sm btn-secondary apply-btn min-150px" onClick={cancleApproveModal}>Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }








            <div className="card tabs-outer">
                <div className="card-header position-relative py-0 details-bg">
                    <h2 className="card-title  m-0">Import Student</h2>
                </div>

                <div className="card-body add-new-schools p-6">
                    <div className="row">
                        <div className="col-lg-6 fv-row mb-5">
                            <label className="fw-bold mb-2">{import_student_form.class_id.label}</label>
                            <Select
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer"
                                options={import_student_form.class_id.options}
                                value={import_student_form.class_id.options.filter(function (option) {
                                    return option.value === import_student_form.class_id.value;
                                })}
                                onChange={(newValue) => handleChange(newValue, "class_id")}
                            />
                            {!import_student_form.class_id.valid && import_student_form.class_id.onBlur_out ? <div className="error field-error">{import_student_form.class_id.error_msg}</div> : ""}
                        </div>

                        <div className="col-lg-6 fv-row mb-5">
                            <label className="fw-bold mb-2">{import_student_form.dormitory_id.label}</label>
                            <Select
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer"
                                options={import_student_form.dormitory_id.options}
                                value={import_student_form.dormitory_id.options.filter(function (option) {
                                    return option.value === import_student_form.dormitory_id.value;
                                })}
                                onChange={(newValue) => handleChange(newValue, "dormitory_id")}
                            />
                            {!import_student_form.dormitory_id.valid && import_student_form.dormitory_id.onBlur_out ? <div className="error field-error">{import_student_form.dormitory_id.error_msg}</div> : ""}
                        </div>

                        <div>
                            <input
                                type={import_student_form.school_name.type}
                                value={user_info.user_school.school_name ? user_info.user_school.school_name : "1234"}
                                className="form-control"
                            />
                        </div>

                        <div>
                            <input
                                type={import_student_form.school_detail_id.type}
                                value={user_info.school_detail_id ? user_info.school_detail_id : "1234"}
                            />
                        </div>

                        <div className="col-lg-6 fv-row mb-5">

                            <label className="fw-bold mb-2 w-100 " style={{textTransform:"none"}}>.csv File only</label>
                            <label className="btn btn-sm btn-outline-primary rounded-50 ">

                                <input hidden=""
                                    type="file"
                                    accept=".csv,"
                                    onChange={(event) => GetImportStudentDetails(event, "students_csv")}
                                    className="CSV_file"
                                    disabled={!(import_student_form?.class_id?.value && import_student_form?.dormitory_id?.value)}
                                />
                            </label>
                            {!import_student_form.students_csv.valid && import_student_form.students_csv.onBlur_out ? <div className="error field-error">{import_student_form.students_csv.error_msg}</div> : ""}
                        </div>
                    </div>
                    <div className="d-flex">
                        <h4>* </h4>
                        <span className="fw-bold ">&#160;Please note that by default, the parent's country would be the same as the country where the school is situated. You can change it manually from the student's profile.</span>
                    </div>
                </div>
            </div>
        </>
    );

}

function mapStateToProps(state) {
    return {

    };
}
const mapDispatchToProps = {
    onImportStudentsCsv: userActions.ImportStudentsCsv,
    onGetAllDormitoryImport: userActions.GetAllDormitoryImport,
    onGetAllClassesDetailsImport: userActions.GetAllClassesDetailsImport,
    onGetImportStudentDetails: userActions.GetImportStudentDetails,
    onGetReviewAgainImportStudents: userActions.GetReviewAgainImportStudents,
    onGetAllSelectedCountries: userActions.GetAllSelectedCountries,
    onGetAllCountriesForImportStudents: userActions.GetAllCountriesForImportStudents,
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentImport);

