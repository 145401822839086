import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";


const ApplyBorderLeaveModal = (props) => {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    setShowModal(props.showApplyLeaveModal)
  }, [])

  const closeModal = () => {
    props.hideLeaveModal(false)
  };

  const updateChildState = () => {
    props.ApplyBorderLeave(props.leaveData)
  };

  return (
    <div>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Leave Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to submit this Leave Application?</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 text-center">
            <Button type="button" onClick={closeModal} className="btn btn-secondary btn-sm me-2">Close to edit leave</Button>
            <Button type='submit' className=" btn-sm" onClick={updateChildState} disabled={props.isLoading}>Submit</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ApplyBorderLeaveModal;
