import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** CreateSchool Action ***/
export const CreateSchoolStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateSchool_START,
    };
};
export const CreateSchoolSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateSchool_SUCCESS,
    };
};
export const CreateSchoolFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateSchool_FAIL,
    };
};
export const CreateSchool = (form_data) => {
    return (dispatch) => {
        dispatch(CreateSchoolStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/CreateSchool`,
        })
            .then(function (response) {
                dispatch(CreateSchoolSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateSchoolFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllSchool Action ***/
export const GetAllSchoolStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllSchool_START,
    };
};
export const GetAllSchoolSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllSchool_SUCCESS,
    };
};
export const GetAllSchoolFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllSchool_FAIL,
    };
};
export const GetAllSchool = (page, limit, sort, order, search, is_school_activate,type) => {
    return (dispatch) => {
        dispatch(GetAllSchoolStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetAllSchool`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                is_school_activate: is_school_activate,
                type:type
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllSchoolSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllSchoolFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetSchoolDetailById Action ***/
export const GetSchoolDetailByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetSchoolDetailById_START,
    };
};
export const GetSchoolDetailByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetSchoolDetailById_SUCCESS,
    };
};
export const GetSchoolDetailByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetSchoolDetailById_FAIL,
    };
};
export const GetSchoolDetailById = (id) => {
    return (dispatch) => {
        dispatch(GetSchoolDetailByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetSchoolDetailById`,
            params: {
                id: id,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetSchoolDetailByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetSchoolDetailByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetSchoolActivityDetails Action ***/
export const GetSchoolActivityDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetSchoolActivityDetails_START,
    };
};
export const GetSchoolActivityDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetSchoolActivityDetails_SUCCESS,
    };
};
export const GetSchoolActivityDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetSchoolActivityDetails_FAIL,
    };
};
export const GetSchoolActivityDetails = (school_code, country_host, page, limit, search) => {
    return (dispatch) => {
        dispatch(GetSchoolActivityDetailsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetActivityDetail`,
            params: {
                school_code: school_code,
                country_host: country_host,
                page: page,
                limit: limit, 
                search: search 
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetSchoolActivityDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetSchoolActivityDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetSchoolGetPaymentDetail Action ***/
export const GetSchoolGetPaymentDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetSchoolGetPaymentDetails_START,
    };
};
export const GetSchoolGetPaymentDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetSchoolGetPaymentDetails_SUCCESS,
    };
};
export const GetSchoolGetPaymentDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetSchoolGetPaymentDetails_FAIL,
    };
};
export const GetSchoolGetPaymentDetails = (page, limit, filtersearch) => {
    return (dispatch) => {
        dispatch(GetSchoolGetPaymentDetailsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetPaymentDetail`,
            params: {
                page: page,
                limit: limit, 
                search: filtersearch
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetSchoolGetPaymentDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetSchoolGetPaymentDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** GetSchoolGetPaymentDetailsById Action ***/
export const GetSchoolGetPaymentDetailsByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetSchoolGetPaymentDetailsById_START,
    };
};
export const GetSchoolGetPaymentDetailsByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetSchoolGetPaymentDetailsById_SUCCESS,
    };
};
export const GetSchoolGetPaymentDetailsByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetSchoolGetPaymentDetailsById_FAIL,
    };
};
export const GetSchoolGetPaymentDetailsById = (page, limit, filtersearch, type, id) => {
    return (dispatch) => {
        dispatch(GetSchoolGetPaymentDetailsByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetPaymentDetailById`,
            params: {
                id: id,
                type: type,
                page: page,
                limit: limit, 
                // search: filtersearch
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetSchoolGetPaymentDetailsByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetSchoolGetPaymentDetailsByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetSchoolById Action ***/
export const GetSchoolByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetSchoolById_START,
    };
};
export const GetSchoolByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetSchoolById_SUCCESS,
    };
};
export const GetSchoolByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetSchoolById_FAIL,
    };
};
export const GetSchoolById = (school_detail_uuid) => {
    return (dispatch) => {
        dispatch(GetSchoolByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/GetSchoolById`,
            params: {
                school_detail_uuid: school_detail_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetSchoolByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetSchoolByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** UpdateSchool Action ***/
export const UpdateSchoolStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateSchool_START,
    };
};
export const UpdateSchoolSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateSchool_SUCCESS,
    };
};
export const UpdateSchoolFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateSchool_FAIL,
    };
};

export const UpdateSchool = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateSchoolStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/UpdateSchool`,
        })
            .then(function (response) {
                dispatch(UpdateSchoolSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateSchoolFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** DeactivateSchool Action ***/

export const DeactivateSchoolStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeactivateSchool_START,
    };
};

export const DeactivateSchoolSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeactivateSchool_SUCCESS,
    };
};

export const DeactivateSchoolFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeactivateSchool_FAIL,
    };
};

export const DeactivateSchool = (form_data) => {
    return (dispatch) => {
        dispatch(DeactivateSchoolStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/DeactivateSchool`,
        })
            .then(function (response) {
                dispatch(DeactivateSchoolSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(DeactivateSchoolFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** DeleteSchool Action ***/

export const DeleteSchoolAndDatabaseStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteSchoolAndDatabase_START,
    };
};
export const DeleteSchoolAndDatabaseSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteSchoolAndDatabase_SUCCESS,
    };
};
export const DeleteSchoolAndDatabaseFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteSchoolAndDatabase_FAIL,
    };
};
export const DeleteSchoolAndDatabase = (form_data) => {
    return (dispatch) => {
        dispatch(DeleteSchoolAndDatabaseStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/DeleteSchoolAndDatabase`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(DeleteSchoolAndDatabaseSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log("resposne fail", error);
                dispatch(DeleteSchoolAndDatabaseFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** UpdateSchool Action ***/
export const UpdateSchoolUserStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateSchoolUser_START,
    };
};
export const UpdateSchoolUserSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateSchoolUser_SUCCESS,
    };
};
export const UpdateSchoolUserFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateSchoolUser_FAIL,
    };
};

export const UpdateSchoolUser = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateSchoolUserStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/UpdateSchoolUser`,
        })
            .then(function (response) {
                dispatch(UpdateSchoolUserSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateSchoolUserFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** LoginAsSchoolAdmin Action ***/
export const LoginAsSchoolAdminStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.LoginAsSchoolAdmin_START,
    };
};
export const LoginAsSchoolAdminSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.LoginAsSchoolAdmin_SUCCESS,
    };
};
export const LoginAsSchoolAdminFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.LoginAsSchoolAdmin_FAIL,
    };
};

export const LoginAsSchoolAdmin = (form_data) => {
    return (dispatch) => {
        dispatch(LoginAsSchoolAdminStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,

            },
            url: `${actionType.environment_url}/api/superadmin/LoginAsSchoolAdmin`,
        })
            .then(function (response) {
                dispatch(LoginAsSchoolAdminSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(LoginAsSchoolAdminFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** UpdateManagerPassword ***/

export const UpdateSchoolUserPasswordStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateSchoolUserPassword_START,
    };
};

export const UpdateSchoolUserPasswordSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateSchoolUserPassword_SUCCESS,
    };
};

export const UpdateSchoolUserPasswordFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateSchoolUserPassword_FAIL,
    };
};

export const UpdateSchoolUserPassword = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateSchoolUserPasswordStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/superadmin/UpdateSchoolUserPassword`,
        })
            .then(function (response) {
                dispatch(UpdateSchoolUserPasswordSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateSchoolUserPasswordFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** /UpdateSchoolSubscription ***/
export const UpdateSchoolSubscriptionStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateSchoolSubscription_START,
    };
};
export const UpdateSchoolSubscriptionSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateSchoolSubscription_SUCCESS,
    };
};
export const UpdateSchoolSubscriptionFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateSchoolSubscription_FAIL,
    };
};

export const UpdateSchoolSubscription = (form_data) => {
    return (dispatch) => {
        dispatch(UpdateSchoolSubscriptionStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
            },
            url: `${actionType.environment_url}/api/superadmin/UpdateSchoolSubscription`,
        })
            .then(function (response) {
                dispatch(UpdateSchoolSubscriptionSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateSchoolSubscriptionFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



