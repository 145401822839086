import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** CreateStudentGeneric Action ***/
export const CreateStudentGenericStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateStudentGeneric_START,
    };
};
export const CreateStudentGenericSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateStudentGeneric_SUCCESS,
    };
};
export const CreateStudentGenericFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateStudentGeneric_FAIL,
    };
};
export const CreateStudentGeneric = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateStudentGenericStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                school_detail_id: user_info.school_detail_id
            },
          
            url: `${actionType.environment_url}/api/bss-admin/CreateStudentGeneric`,
        })
            .then(function (response) {
                dispatch(CreateStudentGenericSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateStudentGenericFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetAllStudentsGeneric Action ***/

export const GetAllStudentsGenericStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllStudentsGeneric_START,
    };
};

export const GetAllStudentsGenericSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllStudentsGeneric_SUCCESS,
    };
};

export const GetAllStudentsGenericFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllStudentsGeneric_FAIL,
    };
};
export const GetAllStudentsGeneric = (class_id,dormitory_id,student_age,gender, sort, order ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllStudentsGenericStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllStudents`,
            params: {
                class_id:class_id,
                dormitory_id:dormitory_id,
                student_age:student_age,
                gender:gender,
                sort: sort, 
                order: order
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllStudentsGenericSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllStudentsGenericFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetAllStudentsGenericList Action ***/
export const GetAllStudentsGenericListStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllStudentsGenericList_START,
    };
};
export const GetAllStudentsGenericListSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllStudentsGenericList_SUCCESS,
    };
};
export const GetAllStudentsGenericListFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllStudentsGenericList_FAIL,
    };
};
export const GetAllStudentsGenericList = (page, limit, sort, order, is_generic_activate,search ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllStudentsGenericListStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllStudentsGeneric`,
            params: {
                page: page,
                limit: limit,
                sort: sort,
                order: order,
                search: search,
                is_generic_activate:is_generic_activate,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllStudentsGenericListSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllStudentsGenericListFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** DeleteStudentGeneric Action ***/

export const DeleteStudentGenericStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.DeleteStudentGeneric_START,
    };
};
export const DeleteStudentGenericSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.DeleteStudentGeneric_SUCCESS,
    };
};
export const DeleteStudentGenericFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.DeleteStudentGeneric_FAIL,
    };
};
export const DeleteStudentGeneric = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(DeleteStudentGenericStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "delete",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                
            },
            url: `${actionType.environment_url}/api/bss-admin/DeleteStudentGeneric`,
        })
            .then(function (response) {
                console.log("resposne", response.data);
                dispatch(DeleteStudentGenericSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                console.log("resposne fail", error);
                dispatch(DeleteStudentGenericFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** DeactivateGeneric Action ***/

export const UpdateStudentGenericStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateStudentGenericStatus_START,
    };
};

export const UpdateStudentGenericStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateStudentGenericStatus_SUCCESS,
    };
};

export const UpdateStudentGenericStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateStudentGenericStatus_FAIL,
    };
};

export const UpdateStudentGenericStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateStudentGenericStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateStudentGenericStatus`,
        })
            .then(function (response) {
                dispatch(UpdateStudentGenericStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateStudentGenericStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetStudentGenericById Action ***/

export const GetStudentGenericByIdStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStudentGenericById_START,
    };
};
export const GetStudentGenericByIdSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStudentGenericById_SUCCESS,
    };
};
export const GetStudentGenericByIdFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStudentGenericById_FAIL,
    };
};
export const GetStudentGenericById = (student_generic_uuid) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

   
    return (dispatch) => {
        dispatch(GetStudentGenericByIdStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetStudentGenericById`,
            params: {
                student_generic_uuid: student_generic_uuid,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStudentGenericByIdSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStudentGenericByIdFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** UpdateStudentGeneric Action ***/

export const UpdateStudentGenericStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateStudentGeneric_START,
    };
};
export const UpdateStudentGenericSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateStudentGeneric_SUCCESS,
    };
};
export const UpdateStudentGenericFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateStudentGeneric_FAIL,
    };
};

export const UpdateStudentGeneric = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

    return (dispatch) => {
        dispatch(UpdateStudentGenericStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateStudentGeneric`,
        })
            .then(function (response) {
                dispatch(UpdateStudentGenericSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateStudentGenericFail("Something went wrong, Please try again."));
                return error;
            });
    };
};