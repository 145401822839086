import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import Footer from "../../Constant/Footer";
import SuperAdminTopbar from "../topbar";
import SuperAdminTopMenu from "../Sidemenu";
import { toast } from "react-toastify";
import ExtendTrialMdal from "./Modal/ExtentTrialModal";
import StartSubscriptionModal from "./Modal/StartSubscriptionModal";

import * as userActions from "../../../actions/index";

import SchoolItems from "./SchoolItems";

import DeleteConfirmRemove from "../../Utility/DeleteConfirmRemove";
import { CustomDeleteModal1, CustomDeleteModal2 } from "../../Utility/DeleteSchool";
import moment from "moment";

const SuperAdminSchoolList = (props) => {
    const dispatch = useDispatch();

    const [filterlimit, setfilterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filterorder, setfilterorder] = useState("desc");
    const [filtersort, setfiltersort] = useState("school_detail_id");
    const [filtersearch, setfiltersearch] = useState(null);

    const [SchoolList, setSchoolList] = useState([]);
    const [SchoolListcount, setSchoolListcount] = useState(0);
    const [is_school_activate, setis_school_activate] = useState(true);

    const [confirm_modal, setconfirm_modal] = useState(false);
    const [editconfirmremovedata, seteditconfirmremovedata] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [showSecondModal, setShowSecondModal] = useState(false);
    const [confirmRemoveData, setConfirmRemoveData] = useState([]);

    const [extendShow, setExtendShow] = useState(false)
    const [extendData, setExtendData] = useState({})
    const [subscriptionData, setSubscriptionData] = useState({})
    const [subscriptionShow, setSubscriptionShow] = useState(false)
    const [filterType, setfilterType] = useState("")
    const [amountError, setAmountError] = useState()

    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };
    const RemoveCourseModule = (event, course_data) => {
        seteditconfirmremovedata(course_data);
        setconfirm_modal(true);
    };

    const ConfirmRemove = (form_data) => {
        console.log('form_data===', form_data)
        setconfirm_modal(false);
        props.onDeleteSchoolAndDatabase(form_data).then((response) => {
            if (response.success === true) {
                GetAllSchool();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                // setadd_course_modal(false);
                // setedit_course_modal(false);
                setconfirm_modal(false);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };
    const GetAllSchool = useCallback(async () => {
        dispatch(userActions.GetAllSchool(filterpage, filterlimit, filtersort, filterorder, filtersearch, is_school_activate, filterType)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                // let MemberId_level = [];
                // const sumvalue = 1;
                // for (let key in arrayData) {
                //     const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

                //     MemberId_level.push({
                //         perpage: perpage,
                //         country_id: arrayData[key].country_id,
                //         name: arrayData[key].name,

                //     });
                // }
                setSchoolList(arrayData);
                setSchoolListcount(response.data.count);
            } else {
            }
        });
    }, [dispatch, filterpage, filterlimit, filtersort, filterorder, filtersearch, is_school_activate, filterType]);

    useEffect(() => {
        GetAllSchool();
    }, [GetAllSchool, filterpage, filterlimit, filtersort, filterorder, filtersearch, is_school_activate, filterType]);

    useEffect(() => {
        const toastMessage = localStorage.getItem("toastMessage");
        if (toastMessage) {
            toast.success(toastMessage, {
                position: toast.POSITION.TOP_CENTER,
            });
            // Clear the saved message so it doesn't reappear on refresh
            localStorage.removeItem("toastMessage");
        }
    },[])

    const handleChange = async (event, school_detail_uuid, value) => {
        console.log("hello")
        const Payload = {
            school_detail_uuid: school_detail_uuid,
            is_school_activate: value,
        };
        console.log('Payload===', Payload)
        props.onDeactivateSchool(Payload).then((response) => {
            if (response.success === true) {
                GetAllSchool();
                setis_school_activate(true);
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });

    }

    const handleChangeLoginAsSchoolAdmin = async (event, user_uuid, school_code, subscriptionData) => {
        const Payload = {
            user_uuid: user_uuid,
        };
        props.onLoginAsSchoolAdmin(Payload).then((response) => {
            if (response.success === true) {
                localStorage.setItem("bss_authtoken", response.token);
                localStorage.setItem("bss_authuser", JSON.stringify(response.data));
                setTimeout(() => {
                    window.location.href = `/${school_code}/dashboard`;

                }, 500);
                // window.location.href = '/dashboard';
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }

    const toggleModalOne = (event, course_data) => {
        setShowModal(!showModal)
        setConfirmRemoveData(course_data)
    };
    const openTwoAndCloseOne = (e) => {
        setShowSecondModal(!showSecondModal)
        if (showModal) { setShowModal(false) };
    };
    const toggleModalTwo = (e) => {
        setShowSecondModal(!showSecondModal)
    };
    const DeleteSchool = async () => {
        console.log("confirmRemoveData", confirmRemoveData)
        const payload = {
            school_code: confirmRemoveData.school_code,
            school_detail_id: confirmRemoveData.school_detail_id,
        }
        props.onDeleteSchoolAndDatabase(payload).then((response) => {
            if (response.success === true) {
                setShowModal(false)
                setShowSecondModal(false)
                GetAllSchool();
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
    }

    const extendTrial = (event, data) => {
        setExtendShow(!extendShow)
        setExtendData(data.subscriptionData)
    }
    const toggleExtendModal = (event) => {
        setExtendShow(!extendShow)
    };
    const startSubscription = (event, data) => {
        setSubscriptionShow(!subscriptionShow)
        setSubscriptionData(data.subscriptionData)
    }
    const toggleSubscriptionModal = (event) => {
        setSubscriptionShow(!subscriptionShow)
        setAmountError()
    };


    const UpdateSchoolSubscription = async (event, payload) => {
        props.onUpdateSchoolSubscription(payload).then((response) => {
            if (response.success === true) {
                GetAllSchool()
                setExtendShow(false)
                setSubscriptionShow(false)
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
    }
    return (
        <>

            <ExtendTrialMdal
                extendShow={extendShow}
                subscriptionData={extendData}
                toggleExtendModal={toggleExtendModal}
                UpdateSchoolSubscription={(event, data) => UpdateSchoolSubscription(event, data)}
            />

            <StartSubscriptionModal
                subscriptionShow={subscriptionShow}
                subscriptionData={subscriptionData}
                toggleSubscriptionModal={toggleSubscriptionModal}
                UpdateSchoolSubscription={(event, data) => UpdateSchoolSubscription(event, data)}
                amountError={amountError}
                setAmountError={setAmountError}
            />

            {confirm_modal && (
                <DeleteConfirmRemove
                    title={`Delete School?`}
                    bodytitle="Are you sure you want to delete this School?"
                    editconfirmremovedata={editconfirmremovedata}
                    confirm_modal={confirm_modal}
                    confirmButtonText="Delete"
                    ConfirmRemove={(event) => ConfirmRemove(event)}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                />
            )}
            <CustomDeleteModal1
                modalState={showModal}
                toggleModalOne={toggleModalOne}
                confirmRemoveData={confirmRemoveData}
                openTwoAndCloseOne={openTwoAndCloseOne}
            />
            <CustomDeleteModal2
                modalState={showSecondModal}
                toggleModalTwo={toggleModalTwo}
                confirmRemoveData={confirmRemoveData}
                DeleteSchool={DeleteSchool}
            />

            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SuperAdminTopMenu />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <SuperAdminTopbar></SuperAdminTopbar>
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    {/* <!--begin::Container--> */}
                                    <div id="kt_content_container" className="container-fluid">


                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade" id="Snapshot" role="tabpanel" aria-labelledby="Snapshot-tab"></div>

                                            <div className="tab-pane fade show active" id="Rollcall" role="tabpanel" aria-labelledby="Rollcall-tab">
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <div className="card tabs-outer">
                                                            <div className="card-header position-relative py-0">
                                                                <h5 className="card-title text-gray-800 fw-bolder m-0">School Details</h5>
                                                                <div className="card-toolbar">


                                                                    <Link to="/superadmin/add-school" className="btn btn-sm btn-primary apply-btn add-btns addschool-button">Add School</Link>
                                                                </div>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="card-toolbar">
                                                                    <div className="d-flex align-items-center justify-content-between">

                                                                        {/* <!-- <h4 className="card-title text-gray-800 fw-bolder m-0">Schools</h4> --> */}

                                                                        <div className="d-flex align-items-center position-relative">
                                                                            <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                    <path
                                                                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                        fill="currentColor"
                                                                                    ></path>
                                                                                </svg>
                                                                            </span>

                                                                            <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }}
                                                                                onChange={(event) => {
                                                                                    setfiltersearch(event.target.value);
                                                                                    setfilterpage(1);
                                                                                }}

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <SchoolItems

                                                                                SchoolListcount={SchoolListcount}
                                                                                data={SchoolList}
                                                                                filterlimit={filterlimit}
                                                                                filterpage={filterpage}
                                                                                RemoveCourseModule={(event, data) => RemoveCourseModule(event, data)}
                                                                                propsfilterpage={(event) => setfilterpage(event)}

                                                                                handleChange={(event, school_detail_uuid, value) => handleChange(event, school_detail_uuid, value)}

                                                                                handleChangeLoginAsSchoolAdmin={(event, user_uuid, school_code, subscriptionData) => handleChangeLoginAsSchoolAdmin(event, user_uuid, school_code, subscriptionData)}
                                                                                propsfilterlimit={(filterlimit) => {
                                                                                    setfilterlimit(filterlimit);
                                                                                }}

                                                                                handleDelete={(event, data) => toggleModalOne(event, data)}
                                                                                extendTrial={(event, data) => extendTrial(event, data)}
                                                                                startSubscription={(event, data) => startSubscription(event, data)}

                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-sm-4">
                                                        <div className="card ">
                                                            {/* <!--begin::Header--> */}
                                                            <div className="card-header border-bottom border-bottom-1">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label fw-bold text-dark">Filters</span>
                                                                </h3>
                                                            </div>

                                                            <div className="card-body p-6">
                                                                <div className="d-flex flex-column">
                                                                    <h5>Sort By</h5>
                                                                    <div className="d-flex">
                                                                        <button className=

                                                                            {"filter-badge me-2 filter-button " +
                                                                                (filtersort === "school_name" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                if (filterorder === 'desc') {
                                                                                    setfilterorder('asc');
                                                                                }
                                                                                else {
                                                                                    setfilterorder('desc');
                                                                                }
                                                                                setfiltersort('school_name');
                                                                                setfilterpage(1);

                                                                            }}

                                                                        >Name</button>
                                                                        <span className=
                                                                            {"filter-badge me-2 filter-button " +
                                                                                (filtersort === "country" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                if (filterorder === 'desc') {
                                                                                    setfilterorder('asc');
                                                                                }
                                                                                else {
                                                                                    setfilterorder('desc');
                                                                                }
                                                                                setfiltersort('country');
                                                                                setfilterpage(1);

                                                                            }}

                                                                        >Location</span>

                                                                    </div>
                                                                </div>

                                                                <div className="d-flex flex-column mt-6 ">
                                                                    <h5>Show</h5>
                                                                    <div className="d-flex align-items-center">
                                                                        <span className=
                                                                            {"filter-badge me-2 filter-button " +
                                                                                (is_school_activate === "" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                setis_school_activate('');
                                                                                setfilterpage(1);

                                                                            }}

                                                                        >All</span>
                                                                        <span className=

                                                                            {"filter-badge me-2 filter-button " +
                                                                                (is_school_activate === true ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                setis_school_activate(true);
                                                                                setfilterpage(1);

                                                                            }}
                                                                        >Active</span>
                                                                        <span className=
                                                                            {"filter-badge me-2 filter-button " +
                                                                                (is_school_activate === false ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                setis_school_activate(false);
                                                                                setfilterpage(1);

                                                                            }}

                                                                        >Inactive</span>


                                                                    </div>
                                                                    <div className="d-fles mt-4 pt-4 border-top">
                                                                        <span className=
                                                                            {"filter-badge me-2 filter-button " +
                                                                                (filterType === "" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                setfilterType("");
                                                                            }}

                                                                        >Both</span>

                                                                        <span className=
                                                                            {"filter-badge me-2 filter-button " +
                                                                                (filterType === "demo" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                setfilterType("demo");
                                                                            }}

                                                                        >Trial</span>

                                                                        <span className=
                                                                            {"filter-badge me-2 filter-button " +
                                                                                (filterType === "subscription" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                setfilterType("subscription");
                                                                            }}

                                                                        >Subscribed</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--end::Container--> */}
                                </div>
                            </div>
                            <Footer></Footer>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {

    onLoginAsSchoolAdmin: userActions.LoginAsSchoolAdmin,
    onDeactivateSchool: userActions.DeactivateSchool,
    onDeleteSchoolAndDatabase: userActions.DeleteSchoolAndDatabase,
    onUpdateSchoolSubscription: userActions.UpdateSchoolSubscription
    // onGetAllCitiesByStateId: userActions.GetAllCitiesByStateId,
    // onCreateNewCity: userActions.CreateNewCity, 
    // onGetAllStatesByCountryId: userActions.GetAllStatesByCountryId,
};
export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminSchoolList);