import React, { useEffect, useState } from "react";
import Footer from "../../Constant/Footer";
import SideBarLeave from "../../Constant/SideBarLeave";
import TopBar from "../../Constant/TopBar";
import * as userActions from "../../../actions/index";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import Select from 'react-select';
import moment from "moment";
import AdhocLeaveSignInSignOutModal from "../AdhocLeaveModals/AdhocLeaveSignInSignOutModal";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import SwapVertIcon from '@mui/icons-material/SwapVert';





const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};
const SignAdhocLeaveOutIn = (props) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const [count, setCount] = useState()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(20)
    const [sort, setSort] = useState("student_first_name")
    const [order, setOrder] = useState("asc")
    const [dormitory_id, setDormitory_id] = useState("")
    const [class_id, setClass_id] = useState("")
    const [gender, setGender] = useState("")
    const [from_date, setFrom_date] = useState()
    const [to_date, setTo_date] = useState()
    const [search, setSearch] = useState()
    const [adhoc_leave_day, setAdhoc_leave_day] = useState("")

    const [class_ids, setClass_ids] = useState([])
    const [dormitory_ids, setDormitory_ids] = useState([])
    const [adhoc_leave_days, setAdhoc_leave_days] = useState([])

    const [tableHeaderDays, setTableHeaderDays] = useState([])
    const [tableHeaderDate, setTableHeaderDate] = useState([])
    const [dateForFormat, setDateForFormat] = useState([])

    const [tableData, setTableData] = useState([])
    const [confirm_modal, setConfirm_modal] = useState(false)

    const [status_data, setStatus_data] = useState({})
    const [currentDate, setCurrentDate] = useState();
    const [isLoading, setIsloading] = useState(false)
    const permission_info = JSON.parse(localStorage.getItem("permissions"));

    const timezone = user_info?.user_school?.timezone;
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(moment().tz(timezone).format('DD/MM/YYYY'))
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);
    const row_limit = [
        // { value: 5, label: 5 },
        // { value: 10, label: 10 },
        // { value: 15, label: 15 },
        { value: 20, label: 20 },
        { value: 50, label: 50 },
        { value: 100, label: 100 }
    ]

    useEffect(() => {
        GetAllDormitoryStudent();
        GetAllClassesList();
        const dayArray = [
            { label: 'Select Day', value: '' },
            { label: 'Sunday', value: 'Sunday' },
            { label: 'Monday', value: 'Monday' },
            { label: 'Tuesday', value: 'Tuesday' },
            { label: 'Wednesday', value: 'Wednesday' },
            { label: 'Thursday', value: 'Thursday' },
            { label: 'Friday', value: 'Friday' },
            { label: 'Saturday', value: 'Saturday' }
        ]
        setAdhoc_leave_days(dayArray)
    }, []);

    const GetAllDormitoryStudent = async () => {
        props.onGetAllDormitoryStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Dormitory",
                value: "",
            });
            if (response.success === true) {
                const arrayData = response.data.rows;
                if(permission_info){
                   const permClassDormArray = permission_info?.perm_class_dorm;
                   const filteredPermDorm = permClassDormArray.filter(item => item?.dormitory_id !== null)
                   const allowedDormIds = filteredPermDorm.map(item => item?.dormitory_id);
                   const filteredData = arrayData.filter(item => allowedDormIds.includes(item.dormitory_id));
                   for (let key in filteredData) {
                    country_level.push({
                           label: filteredData[key].dormitory_name,
                           value: filteredData[key].dormitory_id,
                           dormitory_id: filteredData[key].dormitory_id,
                       });
                   }
                }else{
                    for (let key in arrayData) {
                        if (arrayData[key].dormitory_status === true) {
                            country_level.push({
                                label: arrayData[key].dormitory_name,
                                value: arrayData[key].dormitory_id,
                            });
                        }
                    }
                }
            }
            setDormitory_ids(country_level)
        });
    }

    const GetAllClassesList = async () => {
        props.onGetAllClassesList().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Year",
                value: "",
            });
            if (response.success === true) {
                const arrayData = response.data.rows;
                if(permission_info){
                    const permClassDormArray = permission_info?.perm_class_dorm;
                    const filteredPermDorm = permClassDormArray?.filter(item => item?.class_id !== null)
                    const allowedDormIds = filteredPermDorm?.map(item => item?.class_id);
                    const filteredData = arrayData?.filter(item => allowedDormIds?.includes(item?.class_id));
                    for (let key in filteredData) {
                        country_level.push({
                            label: filteredData[key].class_name,
                            value: filteredData[key].class_id,
                            class_id: filteredData[key].class_id,
                        });
                    }
                 }else{
                     for (let key in arrayData) {
                         country_level.push({
                            label: arrayData[key].class_name,
                            value: arrayData[key].class_id,
                         });
                     }
                }
            }
            setClass_ids(country_level)
        });
    }

    useEffect(() => {
        GetAllAdhocLeaveforSignOrSignOut()
    }, [page, limit, sort, order, dormitory_id, class_id, gender, from_date, to_date, search, adhoc_leave_day, tableHeaderDate])

    const GetAllAdhocLeaveforSignOrSignOut = async () => {
        props.onGetAllAdhocLeaveforSignOrSignOut(page, limit, sort, order, dormitory_id, class_id, gender, from_date, to_date, search, adhoc_leave_day).then((response) => {
            if (response.success === true) {
                const adhoc_data = response.data.rows;
                console.log("adhoc_data", adhoc_data);
                const filter_adhoc_data = adhoc_data.map((single_adhoc, adhoc_index) => {
                    const week_days_array = [{}, {}, {}, {}, {}, {}, {}]
                    const filter_stu_adhoc_data = single_adhoc.stu_adhoc_data.filter((single_leave, index) => {
                        const adhoc_date = moment(single_leave.arrival_date).format("YYYY/MM/DD")
                        return adhoc_date >= dateForFormat[0] && adhoc_date <= dateForFormat[6]
                    });
                    filter_stu_adhoc_data.forEach(element => {
                        const week_days_array_index = tableHeaderDate.indexOf(moment(new Date(element.departure_date)).format("DD/MM/YYYY"))
                        if (!week_days_array[week_days_array_index].day) {
                            week_days_array[week_days_array_index].day = []
                            week_days_array[week_days_array_index].day.push(element)
                        } else {
                            week_days_array[week_days_array_index].day.push(element)
                        }
                    });
                    return { ...single_adhoc, stu_adhoc_data: week_days_array, serialNo: 0 + limit * (page - 1) + (adhoc_index + 1), }
                })
                setCount(response.data.count)
                setTableData(filter_adhoc_data)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        })
    }

    useEffect(() => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentDate = new Date();
        const upcomingWeekdays = [];
        const upcomingWeekdate = [];
        const upcomingDateForFormat = [];
        for (let i = 0; i < 7; i++) {
            const nextDay = new Date();
            nextDay.setDate(currentDate.getDate() + i);
            upcomingWeekdate.push(moment(nextDay).format("DD/MM/YYYY"))
            upcomingDateForFormat.push(moment(nextDay).format("YYYY/MM/DD"))
            upcomingWeekdays.push(days[nextDay.getDay()]);
        }
        setTableHeaderDate(upcomingWeekdate)
        setTableHeaderDays(upcomingWeekdays)
        setDateForFormat(upcomingDateForFormat)
    }, [])


    const UpdateAdhocLeaveSignInOrSignOut = (leave_data) => {
        setIsloading(true);
        console.log("DSfsdgdfgd", props);
        props.onUpdateAdhocLeaveSignInOrSignOut(leave_data).then((response) => {
            if (response.success === true) {
                setIsloading(false);
                setConfirm_modal(false)
                GetAllAdhocLeaveforSignOrSignOut()
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                setIsloading(false);
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        })

    }

    const showModal = (leave, status, leaveData) => {
        let studentDepartDate = moment(leaveData?.departure_date).format("DD/MM/YYYY") 
        if(studentDepartDate === currentDate){
            setStatus_data({ leave: leave, status: status })
            setConfirm_modal(true)
        }
    }
    const modalHide = () => { setConfirm_modal(false) };
   

    return (
        <>

            {confirm_modal && (
                <AdhocLeaveSignInSignOutModal
                    confirm_modal={confirm_modal}
                    modalHide={() => modalHide()}
                    status_data={status_data}
                    UpdateAdhocLeaveSignInOrSignOu={(data) => UpdateAdhocLeaveSignInOrSignOut(data)}
                    isLoading={isLoading}
                />
            )}

            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SideBarLeave />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    {/* <!--begin::Container--> */}
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='card'>
                                                    <div className='card-header align-items-center'>
                                                        <h3 className='m-0 fw-bolder'>Search Adhoc Leave</h3>
                                                    </div>
                                                    <div className='card-body'>
                                                        <div className="row filter-top-block filter-block-two">
                                                            <div className="col">
                                                                <label className='form-label'>Select Year</label>
                                                                <Select
                                                                    styles={customStyles}
                                                                    options={class_ids}
                                                                    value={class_ids.filter((option) => {
                                                                        return option.value === class_id;
                                                                    })}
                                                                    onChange={(event) => {
                                                                        setClass_id(event.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col"><label className='form-label'>Select Dormitory</label>
                                                                <Select
                                                                    styles={customStyles}
                                                                    options={dormitory_ids}
                                                                    value={dormitory_ids.filter((option) => {
                                                                        return option.value === dormitory_id;
                                                                    })}
                                                                    onChange={(event) => {
                                                                        setDormitory_id(event.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col"><label className='form-label'>Select Adhoc leave Day</label>
                                                                <Select
                                                                    styles={customStyles}
                                                                    options={adhoc_leave_days}
                                                                    value={adhoc_leave_days.filter((option) => {
                                                                        return option.value === adhoc_leave_day;
                                                                    })}
                                                                    onChange={(event) => {
                                                                        setAdhoc_leave_day(event.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <label className='form-label'>From Date</label>
                                                                <div className='position-relative'>
                                                                    <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                                        <i class="far fa-calendar-alt mt-2px"></i>
                                                                    </span>
                                                                    <input
                                                                        id="session-date"
                                                                        required
                                                                        placeholder="From Date"
                                                                        type="date"
                                                                        className="form-control"
                                                                        value={from_date}
                                                                        onChange={(e) => setFrom_date(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <label className='form-label'>To Date</label>
                                                                <div className='position-relative'>
                                                                    <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                                        <i class="far fa-calendar-alt mt-2px"></i>
                                                                    </span>
                                                                    <input
                                                                        id="session-date"
                                                                        required
                                                                        placeholder="To Date"
                                                                        type="date"
                                                                        className="form-control"
                                                                        value={to_date}
                                                                        onChange={(e) => setTo_date(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <label className='form-label'>Search</label>
                                                                <div className="position-relative">
                                                                    <span className="position-absolute p-3" style={{ right: "0%" }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none"><path d="M19 19L13.0001 13M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#999" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                                                    </span>
                                                                    <input type="search" placeholder="Search..." className="form-control pe-10" value={search} onChange={(e) => setSearch(e.target.value)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <label className="fw-bold mb-2">Gender</label>
                                                                    </div>
                                                                    <div className="radio-btn d-flex align-items-center">
                                                                    
                                                                            <label className="cursor-pointer d-flex align-items-center">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="gende"
                                                                                    name="gen"
                                                                                    className="form-check-input me-2 cursor-pointer"
                                                                                    value="male"
                                                                                    onChange={(event) => { setGender(event.target.value); }}
                                                                                    checked={gender === "male" ? true : false}
                                                                                />
                                                                                Male
                                                                            </label>

                                                                            <label className="cursor-pointer d-flex align-items-center">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="gender"
                                                                                    name="gen"
                                                                                    className="form-check-input me-2 cursor-pointer"
                                                                                    value="female"
                                                                                    onChange={(event) => { setGender(event.target.value); }}
                                                                                    checked={gender === "female" ? true : false}
                                                                                />
                                                                                Female
                                                                            </label>

                                                                            <label className="cursor-pointer d-flex align-items-center">
                                                                                <input
                                                                                    type="radio"
                                                                                    id="gender"
                                                                                    name="gen"
                                                                                    className="form-check-input me-2 cursor-pointer"
                                                                                    value=""
                                                                                    onChange={(event) => { setGender(event.target.value); }}
                                                                                    checked={gender === "" ? true : false}
                                                                                />
                                                                                Both
                                                                            </label>
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                      
                                                        </div>
                                                        <div className="row mt-7">
                                                            

                                                            <div className="col-md-12">
                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    {/* <div>
                                                                        <label className="form-label">Short By : </label>
                                                                        <button type="button" className="ms-3 btn btn-secondary btn-sm" onClick={()=>sort === "student_first_name" ? setSort("student_last_name") : setSort("student_first_name")}>
                                                                            {sort === "student_first_name" ? "Student First Name" : "Student Last Name"}
                                                                        </button>
                                                                    </div> */}
                                                                    <button type="button" className="ms-3 btn btn-secondary btn-sm"
                                                                        onClick={(event) => {
                                                                            setClass_id("");
                                                                            setDormitory_id("");
                                                                            setAdhoc_leave_day("")
                                                                            setFrom_date("");
                                                                            setTo_date("");
                                                                            setSearch("")
                                                                            setGender("")
                                                                            setOrder("asc")
                                                                        }} >
                                                                        Clear Search
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='row'>
                                            <div className="col-lg-12 mt-5">
                                                <div className='card'>
                                                    <div className='card-header align-items-center'>
                                                        <h3 className='m-0 fw-bolder'>Adhoc Leave for SignIn Or SignOut</h3>
                                                    </div>
                                                    <div className='card-body'>
                                                        <div className='table-responsive'>
                                                            <table className='table align-middle table-row-bordered  fs-6 gs-4 gy-4 gx-4'>
                                                                <thead>
                                                                    <tr className="text-bg-light">
                                                                        <th ></th>
                                                                        <th></th>
                                                                        {tableHeaderDays.map(day => <th style={{ width: "10%" }}>{day}</th>)}
                                                                    </tr>
                                                                    <tr>
                                                                        <th className='text-nowrap'>Sr.No</th>
                                                                        <th>
                                                                            <div  className="d-flex justify-content-start text-nowrap align-items-end">
                                                                            <span>
                                                                                Student Name
                                                                            </span>
                                                                            {/* <span className="ms-5 ">
                                                                                <div className="d-flex flex-column align-items-start justify-content-center">
                                                                                    <i class="fas fa-sort-up fs-1" style={{ lineHeight: "10px", color: `${order === "asc" ? "#1f80ff" : ""}` }} onClick={() => setOrder("asc")}></i>
                                                                                    <i class="fas fa-sort-down fs-1" style={{ lineHeight: "10px", color: `${order === "desc" ? "#1f80ff" : ""}` }} onClick={() => setOrder("desc")}></i>
                                                                                </div>
                                                                            </span> */}
                                                                            <span className="ms-3">
                                                                                <IconButton color="primary" size="small">
                                                                                    <SwapVertIcon fontSize="small" onClick={()=> order === "desc" ? setOrder("asc") : setOrder("desc")}/>
                                                                                </IconButton>
                                                                            </span>
                                                                            </div>
                                                                        </th>
                                                                        {tableHeaderDate.map(date => <th className='text-nowrap'>{date}</th>)}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        tableData.map((item, index) => {
                                                                            console.log(item,"::item23456")
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item.serialNo}</td>
                                                                                    <td className='text-nowrap' >{item.student_first_name} {item.student_last_name}</td>
                                                                                    {item?.stu_adhoc_data.map((leave) => {
                                                                                        return (<td className="p-1">
                                                                                            {leave?.day !== undefined && leave.day.map((leave_data) => {
                                                                                                return (
                                                                                                    <button
                                                                                                        className={leave_data.check_in_out === "pending" ? "badge badge-light-warning w-100 text-center p-1" :
                                                                                                            (leave_data.check_in_out === "out" ? "badge badge-light-danger w-100 text-center p-1" : "badge badge-light-success w-100 text-center p-1")}
                                                                                                        onClick={() => showModal(leave_data.adhoc_leave_uuid, leave_data.check_in_out, leave_data)}
                                                                                                    >
                                                                                                        <span>{moment(leave_data.start_time, "hh:mm").format("hh:mm A")} - {moment(leave_data.end_time, "hh:mm").format("hh:mm A")}</span>
                                                                                                    </button>
                                                                                                )
                                                                                            })}
                                                                                        </td>)
                                                                                    })}
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className='mt-5 pt-5'>
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="d-flex justify-content-between">
                                                                        <Select
                                                                            menuPlacement="top"
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            defaultValue={row_limit[0]}
                                                                            name="color"
                                                                            options={row_limit}
                                                                            onChange={(e) => setLimit(e.value)}
                                                                        />
                                                                        <Stack spacing={1} >
                                                                            <Pagination
                                                                                count={Math.ceil(count / limit)}
                                                                                page={page}
                                                                                onChange={(e, value) => setPage(value)}
                                                                                color="primary"
                                                                            />
                                                                        </Stack>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}


function mapStateToProps(state) {
    return {

    };
}

const mapDispatchToProps = {
    onGetAllAdhocLeaveforSignOrSignOut: userActions.GetAllAdhocLeaveforSignOrSignOut,
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesList: userActions.GetAllClassesList,
    onUpdateAdhocLeaveSignInOrSignOut: userActions.UpdateAdhocLeaveSignInOrSignOut,
};
export default connect(mapStateToProps, mapDispatchToProps)(SignAdhocLeaveOutIn);