import React, { useCallback, useEffect, useState } from 'react'

import Footer from "../../Constant/Footer";
import SuperAdminTopbar from "../topbar";
import SuperAdminTopMenu from "../Sidemenu";
import SubscriptionsItem from './SubscriptionsItem';
import * as userActions from "../../../actions/index";
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';


function Subscriptions() {
  const dispatch = useDispatch();
  const [subscriptionData, setSubscriptionData] = useState()
  const [filterpage, setfilterpage] = useState(1);
  const [filterlimit, setfilterlimit] = useState(10);
  const [SuperAdminSubscriptioncount, setSuperAdminSubscriptioncount] = useState(0);
  const [filtersearch, setfiltersearch] = useState(null);

  // function calculateYearsAndMonths(startDate, endDate) {
  //   const start = new Date(startDate);
  //   const end = new Date(endDate);
  //   console.log(start,end,"::test")

  //   // Calculate the difference in years
  //   let years = end.getFullYear() - start.getFullYear();

  //   // Calculate the difference in months
  //   let months = end.getMonth() - start.getMonth();

  //   // Adjust if the end date's month is before the start date's month
  //   if (months < 0) {
  //     months += 12; // Add 12 months to keep the months positive
  //     years--; // Decrease a year if months roll over
  //   }

  //   // Adjust months if the end day is before the start day in the same month
  //   if (end.getDate() < start.getDate()) {
  //     months--;
  //     if (months < 0) {
  //       months += 12; // Adjust again if negative
  //       years--; // Decrease a year if months roll back
  //     }
  //   }

  //   // Convert the total years and months into a decimal value
  //   const decimalYears = years + (months / 12);
  //   const newYears = Math.ceil(decimalYears)
  //   const string = newYears == 1 ? "Year":"Years" 
  //   return newYears + string; // Return as a decimal (years + fractional months)
  // }
  const GetSchoolGetPaymentDetails = useCallback(async () => {
    dispatch(userActions.GetSchoolGetPaymentDetails(filterpage, filterlimit, filtersearch)).then((response) => {
      if (response.success === true) {
        const arrayData = response.data.rows;
        console.log("arrayData=======", arrayData);
        let schoolSubscriptionData = [];
        const sumvalue = 1;
        for (let key in arrayData) {
          const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

          schoolSubscriptionData.push({
            perpage: perpage,
            school_name: arrayData[key].school_name && arrayData[key].school_name.charAt(0).toUpperCase() + arrayData[key].school_name.slice(1),
            start_date: moment(arrayData[key].subscriptionData.start_date).format('DD/MM/YYYY'),
            end_date: moment(arrayData[key].subscriptionData.end_date).format('DD/MM/YYYY'),
            amount: arrayData[key].subscriptionData.amount ? arrayData[key].subscriptionData.amount : "-",
            created_date: moment(arrayData[key].subscriptionData.created_date).format('DD/MM/YYYY'),
            // subscription_plan: arrayData[key].type === "demo" ? "-" : calculateYearsAndMonths(arrayData[key].start_date, arrayData[key].end_date),
            type: arrayData[key].subscriptionData.type === "demo" ? <span className="badge badge-light-info px-4 py-2">Trial</span>:  <span className="badge badge-light-danger px-4 py-2">subscription</span>,
            school_detail_id: arrayData[key].school_detail_id
          });
        }
        setSubscriptionData(schoolSubscriptionData)
        setSuperAdminSubscriptioncount(response.data.count);
      } else {
      }
    });
  }, [dispatch, filterpage, filterlimit, filtersearch]);

  useEffect(() => {
    GetSchoolGetPaymentDetails();
  }, [GetSchoolGetPaymentDetails, filterpage, filterlimit, filtersearch]);

  console.log(filtersearch, "::subscriptionData676")
  return (
    <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <SuperAdminTopMenu />
          <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
            <SuperAdminTopbar></SuperAdminTopbar>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
              <div className="post d-flex flex-column-fluid" id="kt_post">
                {/* <!--begin::Container--> */}
                <div id="kt_content_container" className="container-fluid">


                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade" id="Snapshot" role="tabpanel" aria-labelledby="Snapshot-tab"></div>

                    <div className="tab-pane fade show active" id="Rollcall" role="tabpanel" aria-labelledby="Rollcall-tab">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card tabs-outer">
                            <div className="card-header position-relative py-0">
                              <h5 className="card-title text-gray-800 fw-bolder m-0">Subscriptions</h5>

                              <div className="d-flex align-items-center position-relative">
                                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                    <path
                                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                                <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search "
                                onChange={(event) => {
                                  setfiltersearch(event.target.value);
                                  setfilterpage(1);
                                }}

                                />
                              </div>
                            </div>

                            <div className="card-body">
                              <div className="card-toolbar">
                                <div className="d-flex align-items-center justify-content-between">

                                  {/* <!-- <h4 className="card-title text-gray-800 fw-bolder m-0">Schools</h4> --> */}


                                  <div className="row">
                                    <div className="col-sm-12">

                                      {subscriptionData && <SubscriptionsItem

                                        SuperAdminSubscriptioncount={SuperAdminSubscriptioncount}
                                        SuperAdminDashboard={subscriptionData}
                                        data={subscriptionData}
                                        propsfilterpage={(event) => setfilterpage(event)}
                                        // // RemoveCourseModule={(event, data) => RemoveCourseModule(event, data)}

                                        filterlimit={filterlimit}
                                        filterpage={filterpage}
                                        propsfilterlimit={(filterlimit) => {
                                          setfilterlimit(filterlimit);
                                        }}
                                      />}



                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                    </div>
                  </div>
                </div>
                {/* <!--end::Container--> */}
              </div>
            </div>
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div >

  )
}

export default Subscriptions
// const mapStateToProps = (state) => ({});
// const mapDispatchToProps = {
//   // onGetSchoolGetPaymentDetails: userActions.GetSchoolGetPaymentDetails,
// };
// export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
