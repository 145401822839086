import React,{ Component } from "react";
import { Navigate } from "react-router-dom";
import Select from "react-select";
import { connect } from "react-redux";

import Validations from "../Utility/Validations";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import { toast } from "react-toastify";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Tooltip from '@mui/material/Tooltip';
import * as actionType from "../../constants/actionTypes";
import * as userActions from "../../actions/index";
import AvatarImageCropper from 'react-avatar-image-cropper';
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};
const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

class SchoolEdit extends React.Component {
    constructor(props) {
        super(props);
        window.addEventListener('keydown', this.handleKeyPress);
        this.state = {
            OpenImageModal: false,
            isLoading: false,
            identifiers: "",
            school_form: {
                school_name: {
                    type: "text",
                    label: "School Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "School Name",
                    validations: { required: true },
                },

                school_detail_uuid: {
                    type: "text",
                    label: "school_detail_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

                user_uuid: {
                    type: "text",
                    label: "school_detail_uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

                actual_school_code: {
                    type: "text",
                    label: "School Code *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "School Code",
                    validations: { required: false },
                },

                school_code: {
                    type: "text",
                    label: "School Code *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "School Code",
                    validations: { required: true },
                },

                weekend_day_from: {
                    type: "select",
                    label: "Weekend Start Day *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select weekend day",
                    validations: { required: true },
                    options: [
                        { label: "From Day", value: "" },
                        { label: "Sunday", value: "Sunday" },
                        { label: "Monday", value: "Monday" },
                        { label: "Tuesday", value: "Tuesday" },
                        { label: "Wednesday", value: "Wednesday" },
                        { label: "Thursday", value: "Thursday" },
                        { label: "Friday", value: "Friday" },
                        { label: "Saturday", value: "Saturday" },
                    ],
                },

                weekend_day_to: {
                    type: "select",
                    label: "Weekend Finish Day *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select weekend day",
                    validations: { required: true },
                    options: [
                        { label: "To Day", value: "" },
                        { label: "Sunday", value: "Sunday" },
                        { label: "Monday", value: "Monday" },
                        { label: "Tuesday", value: "Tuesday" },
                        { label: "Wednesday", value: "Wednesday" },
                        { label: "Thursday", value: "Thursday" },
                        { label: "Friday", value: "Friday" },
                        { label: "Saturday", value: "Saturday" },
                    ],
                },

                cut_off_day: {
                    type: "select",
                    label: "Cut off Day *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select Cut off Day",
                    validations: { required: true },
                    options: [
                        { label: "Cut off Day", value: "" },
                        { label: "Sunday", value: "Sunday" },
                        { label: "Monday", value: "Monday" },
                        { label: "Tuesday", value: "Tuesday" },
                        { label: "Wednesday", value: "Wednesday" },
                        { label: "Thursday", value: "Thursday" },
                        { label: "Friday", value: "Friday" },
                        { label: "Saturday", value: "Saturday" },
                    ],
                },

                email: {
                    type: "email",
                    label: "Primary E-mail",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: true, email: true },
                },

                // password: {
                //     type: "password",
                //     label: "Password",
                //     value: "",
                //     valid: true,
                //     error_msg: "",
                //     onBlur_out: false,
                //     placeholder: "Abc@123",
                //     validations: { required: false },
                // },

                contact_one: {
                    type: "number",
                    label: "Primary Contact Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: true, number: true, min_length_phone: 7 },
                },

                contact_two: {
                    type: "number",
                    label: "Alternate Contact Number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: false, number: true, min_length_phone: 7 },
                },

                country: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: true },
                    disabled: true
                },

                country_id: {
                    type: "select",
                    label: "Country / Region *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Select weekend day",
                    validations: { required: true },
                    options: [],
                    disabled: true
                },

                state_id: {
                    type: "select",
                    label: "State *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "State",
                    validations: { required: true },
                    options: [],
                },

                state: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: true },
                },

                city_id: {
                    type: "select",
                    label: "City *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [
                        { label: "City *", value: "" },
                    ],
                },

                city: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: true },
                },

                school_address: {
                    type: "text",
                    label: "Physical Address *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "",
                    validations: { required: true },
                },

                zipcode: {
                    type: "text",
                    label: "Physical Address *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "1234",
                    validations: { required: true },
                },

                country_host: {
                    type: "text",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: true },
                },

                highest_class: {
                    type: "select",
                    label: "Highest Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [
                        { label: "Highest Year", value: "" },
                        { label: "1st Year", value: 1 },
                        { label: "2nd Year", value: 2 },
                        { label: "3rd Year", value: 3 },
                        { label: "4th Year", value: 4 },
                        { label: "5th Year", value: 5 },
                        { label: "6th Year", value: 6 },
                        { label: "7th Year", value: 7 },
                        { label: "8th Year", value: 8 },
                        { label: "9th Year", value: 9 },
                        { label: "10th Year", value: 10 },
                        { label: "11th Year", value: 11 },
                        { label: "12th Year", value: 12 },
                        { label: "13th Year", value: 13 },
                        { label: "14th Year", value: 14 },
                    ],
                },
                domain_name: {
                    type: "text",
                    label: "Domain Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: true },
                },


                first_name: {
                    type: "text",
                    label: "First Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "First Name *",
                    validations: { required: true },
                },

                last_name: {
                    type: "text",
                    label: "Last Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: true },
                },

                weekend_time_from: {
                    type: "time",
                    label: "Select weekend time from",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "08:08",
                    validations: { required: true },



                },

                weekend_time_to: {
                    type: "time",
                    label: "Select weekend time to",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "08:08",
                    validations: { required: true },
                },

                cut_off_time: {
                    type: "time",
                    label: "Select cutoff",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "08:08",
                    validations: { required: true },
                },

                session_start_month: {
                    type: "select",
                    label: "Session Start Month *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [
                        { label: "Select Session Start Month", value: "" },
                        { label: "January", value: "January" },
                        { label: "February", value: "February" },
                        { label: "March", value: "March" },
                        { label: "April", value: "April" },
                        { label: "May", value: "May" },
                        { label: "June", value: "June" },
                        { label: "July", value: "July" },
                        { label: "August", value: "August" },
                        { label: "September", value: "September" },
                        { label: "October", value: "October" },
                        { label: "November", value: "November" },
                        { label: "December", value: "December" },
                    ],
                },

                session_end_month: {
                    type: "select",
                    label: "Session End Month *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [
                        { label: "Select Session End Month", value: "" },
                        { label: "January", value: "January" },
                        { label: "February", value: "February" },
                        { label: "March", value: "March" },
                        { label: "April", value: "April" },
                        { label: "May", value: "May" },
                        { label: "June", value: "June" },
                        { label: "July", value: "July" },
                        { label: "August", value: "August" },
                        { label: "September", value: "September" },
                        { label: "October", value: "October" },
                        { label: "November", value: "November" },
                        { label: "December", value: "December" },
                    ],
                },

                session_start_year: {
                    type: "select",
                    label: "Session Start Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [],
                },

                session_end_year: {
                    type: "select",
                    label: "Session End Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "City",
                    validations: { required: true },
                    options: [],
                },

                timezone: {
                    type: "select",
                    label: "Session End Year *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Time Zone",
                    validations: { required: true },
                    options: [{ label: "Time Zone *", value: "" },],
                },



                other_contact_1_name: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_1_position: {
                    type: "text",
                    label: "Position",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_1_email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_1_contact: {
                    type: "tel",
                    label: "Contact",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_name: {
                    type: "text",
                    label: "Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_position: {
                    type: "text",
                    label: "Position",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },
                other_contact_2_contact: {
                    type: "tel",
                    label: "Contact",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "Last Name *",
                    validations: { required: false },
                },


                country_code: {
                    type: "hidden",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: false },
                },

                school_logo: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/school-logo-2.png",
                },
                avatar: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/school-logo.png",
                },
            },
        };
    }

    componentDidMount() {
        this.GetAllSelectedCountries();
        this.GetSchoolById();
        let maxOffset = 10;
        let thisYear = (new Date()).getFullYear();
        let allYears = [];
        for (let x = 0; x < maxOffset; x++) {
            allYears.push({
                label: thisYear + x,
                value: thisYear + x,
            })
        }
        const update_school_form = { ...this.state.school_form };
        update_school_form['session_start_year'].options = allYears;
        update_school_form['session_end_year'].options = allYears;
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        update_school_form['country_code'].value = user_info.user_school.country_code;
        this.setState({ school_form: update_school_form });
        this.GetLoginUserPermissions();
    }

    GetSchoolById() {
        this.props.onGetSchoolById(this.props.user_uuid).then((response) => {
            if (response.success === true) {
                let school_data = response.data;
                const update_school_form = { ...this.state.school_form };
                for (let key in update_school_form) {
                    let form_element = update_school_form[key];
                    if (school_data[key]) {
                        if (key === 'country_id') {
                            this.GetAllStatesByCountryId(school_data[key])
                            this.GetAllTimezonesByCountryCode(school_data[key]);
                        }
                        if (key === 'state_id') {
                            this.GetAllCitiesByStateId(school_data[key]);
                        }
                        if (key === 'school_logo' && school_data[key] !== null && school_data[key] !== '') {
                            form_element.thumbnail_image = actionType.environment_url + '/' + school_data[key];
                        }

                        if (key === 'session_start_year') {
                            form_element.value = parseInt(school_data[key]);
                        }

                        else if (key === 'session_end_year') {
                            form_element.value = parseInt(school_data[key]);
                        }

                        else {
                            form_element.value = school_data[key];
                        }

                        update_school_form[key] = form_element;
                    }
                }
                if (school_data.admin_user[0]) {
                    if (school_data.admin_user[0].avatar !== null && school_data.admin_user[0].avatar !== '') {
                        update_school_form['avatar'].thumbnail_image = actionType.environment_url + '/' + school_data.admin_user[0].avatar;
                        update_school_form['avatar'].value = school_data.admin_user[0].avatar;
                    }
                    update_school_form['first_name'].value = school_data.admin_user[0].first_name;
                    update_school_form['last_name'].value = school_data.admin_user[0].last_name;
                    update_school_form['contact_one'].value = school_data.admin_user[0].contact_one;
                    update_school_form['contact_two'].value = school_data.admin_user[0].contact_two;
                    update_school_form['email'].value = school_data.admin_user[0].email;
                    update_school_form['user_uuid'].value = school_data.admin_user[0].user_uuid;

                }
                this.setState({ school_form: update_school_form });
            } else {
            }
        });

    }

    GetLoginUserPermissions() {
        this.props.onGetLoginUserPermissions().then((response) => {
            if (response.success === true) {
            } else {
            }
        });
    }

    inputChangeHandlerimage = (blob, identifiers) => {
        console.log("blob", identifiers);
        const src = window.URL.createObjectURL(blob);
        let url = URL.createObjectURL(blob);
        let file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type })
        const update_school_form = { ...this.state.school_form };
        const form_element = { ...update_school_form[identifiers] };
        form_element.thumbnail_image = url;

        form_element.value = file;
        update_school_form[identifiers] = form_element;
        this.setState({ school_form: update_school_form });
        this.setState({ OpenImageModal: !this.state.OpenImageModal })
    };

    setIdentifiers = (value) => {
        this.setState({ OpenImageModal: !this.state.OpenImageModal })
        this.setState({ identifiers: value })
    }

    inputChangeHandler(event, identifier) {
        const update_school_form = { ...this.state.school_form };
        const form_element = { ...update_school_form[identifier] };
        // form_element.value = event.target.value;
        if (identifier === 'contact_one') {

            form_element.value = event;
        }
        else if (identifier === 'contact_two') {

            form_element.value = event;
        }
        else {
            form_element.value = event.target.value;
        }
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        update_school_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_school_form) {
            is_form_valid = update_school_form[identifier].valid && is_form_valid;
        }
        this.setState({
            school_form: update_school_form,
            is_form_valid: is_form_valid,
        });
    }

    handleChange(data, identifier, type) {
        const update_school_form = { ...this.state.school_form };
        if (identifier === 'country_id') {
            this.GetAllStatesByCountryId(data.value);
            update_school_form['country'].value = data.label;
            update_school_form['country_host'].value = data.country_host;
            this.GetAllTimezonesByCountryCode(data.country_code);

        }
        else if (identifier === 'state_id') {
            update_school_form['state'].value = data.label;
            this.GetAllCitiesByStateId(data.value);

        }
        else if (identifier === 'city_id') {
            update_school_form['city'].value = data.label;

        }
        update_school_form[identifier].value = data.value;
        this.setState({ school_form: update_school_form });
    }


    otherContactInputHandler (e, identifier) {
        const update_school_form = { ...this.state.school_form };
        const form_element = update_school_form[identifier]
        if(identifier === "other_contact_2_contact" || identifier === "other_contact_1_contact"){
            form_element.value = e ;
        }else{
            form_element.value = e.target.value;
        }
        this.setState({school_code:update_school_form})
    }

    GetAllSelectedCountries = async (form_data) => {
        this.props.onGetAllSelectedCountries().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Country",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {

                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].name,
                        value: arrayData[key].country_id,
                        country_code: arrayData[key].country_code,
                        country_host: arrayData[key].country_host,
                    });
                }
            }
            update_school_form['country_id'].options = country_level;
            this.setState({ school_form: update_school_form });
        });
    }

    GetAllStatesByCountryId = async (CountryID) => {
        this.props.onGetAllStatesByCountryId(CountryID).then((response) => {
            let state_level = [];
            state_level.push({
                label: "State",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {

                const arrayData = response.data.rows;

                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        state_level.push({
                            label: arrayData[key].state_name,
                            value: arrayData[key].state_id,
                        });
                    }
                }
            }
            update_school_form['state_id'].options = state_level;
            this.setState({ school_form: update_school_form });
        });
    }

    GetAllCitiesByStateId = async (state_id) => {
        this.props.onGetAllCitiesByStateId(state_id).then((response) => {
            let City_level = [];
            City_level.push({
                label: "City",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_activate === true) {
                        City_level.push({
                            label: arrayData[key].city_name,
                            value: arrayData[key].city_id,
                        });
                    }
                }
            }
            update_school_form['city_id'].options = City_level;
            this.setState({ school_form: update_school_form });
        });
    }

    GetAllTimezonesByCountryCode = async (country_code) => {
        userActions.GetAllTimezonesByCountryCode(country_code).then((response) => {
            let state_level = [];
            state_level.push({
                label: "Time Zone",
                value: "",
            });
            const update_school_form = { ...this.state.school_form };
            if (response.success === true) {
                const arrayData = response.data;
                for (let key in arrayData) {
                    state_level.push({
                        label: arrayData[key].timezone,
                        value: arrayData[key].timezone,
                    });
                }
            }
            update_school_form['timezone'].options = state_level;
            this.setState({ school_form: update_school_form });
        });
    }

    SubmitHandler = async (event) => {
        console.log("onLoginAsSchoolAdmin", this.state.school_form);
        event.preventDefault();
        let is_form_valid = true;
        const update_school_form = this.state.school_form;
        for (let key in update_school_form) {
            let form_element = update_school_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_school_form[key] = form_element;
        }
        this.setState({ school_form: update_school_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.school_form) {
                if (key !== "avatar") {
                    form_data[key] = this.state.school_form[key].value;
                }
            }
            this.setState({ isLoading: true });
            this.props.onUpdateSchool(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
                    const UUID = user_info.user_school.school_detail_uuid;
                    const Payload = {
                        user_uuid: user_info.user_uuid,
                    }
                    this.props.onLoginAsSchoolAdmin(Payload).then((response) => {
                        localStorage.removeItem("bss_authuser");
                        localStorage.setItem("bss_authuser", JSON.stringify(response.data));
                    });
                    setTimeout(() => {
                        this.setState({ isLoading: false });
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/school/${UUID}`,
                        });
                    }, 500);
                } else {
                    this.setState({ isLoading: false });
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }

    };

    SubmitHandlerBoarding = async (event, user_uuid) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_school_form = this.state.school_form;
        for (let key in update_school_form) {
            let form_element = update_school_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_school_form[key] = form_element;
        }
        this.setState({ school_form: update_school_form });
        if (is_form_valid) {
            // this.SubmitHandler(event)
            const form_data = {};
            for (let key in this.state.school_form) {
                if (key !== "school_logo") {
                    form_data[key] = this.state.school_form[key].value;
                }
            }
            this.props.onUpdateSchoolUser(form_data).then((response) => {
                if (response.success === true) {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
                    const UUID = user_info.user_school.school_detail_uuid;
                    const Payload = {
                        user_uuid: user_info.user_uuid,
                    }
                    this.props.onLoginAsSchoolAdmin(Payload).then((response) => {
                        localStorage.removeItem("bss_authuser");
                        localStorage.setItem("bss_authuser", JSON.stringify(response.data));
                    });
                    setTimeout(() => {
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/school/${UUID}`,
                        });
                    }, 500);
                }
                else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }

            });
        }
    };


    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 27) {
            this.setState({ OpenImageModal: false })
        }
    };

    render() {
        const { school_form } = this.state;
        return (
            <React.Fragment>
                {this.state.OpenImageModal &&
                    <div className="upload-image-outer-div" >
                        <span style={{ float: "right" }} className="pointer m-5" onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}>
                            <i class="fas fa-times fa-2x" style={{ color: "#ffffff", }}></i>
                        </span>
                     
                        <div className="upload-image-inner-div">
                            <AvatarImageCropper
                                rootStyle={{ borderRadius: "50%" }}
                                cancelBtnStyle={{ display: "none" }}
                                apply={(blob) => this.inputChangeHandlerimage(blob, this.state.identifiers)}
                                className={"image_crop_style"}
                                sliderConStyle={{ margin: "20px", backgroundColor: "none" }}
                                applyBtnStyle={{}}
                                isBack={true} />
                        </div>
                        <div className="text-center mt-10">
                            <span className="text-white font-weight-bold">The image maximum size is 2MB. Please upload png/jpg/jpeg image</span>
                        </div>
                    </div>
                }
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        {/* <!--begin::Container--> */}
                                        <div id="kt_content_container" className="container-fluid">
                                            <ul className="nav nav-tabs home-tabs fontweight" id="myTab" role="tablist">
                                                <li className="nav-item me-6" role="presentation">
                                                    <button className="nav-link active" id="StudentProfile-tab" data-bs-toggle="tab" data-bs-target="#StudentProfile" type="button" role="tab" aria-controls="home" aria-selected="true">
                                                        School Profile
                                                    </button>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <button className="nav-link" id="BoardingDetail-tab" data-bs-toggle="tab" data-bs-target="#BoardingDetail" type="button" role="tab" aria-controls="process" aria-selected="false">
                                                    School Admin Details
                                                    </button>
                                                </li>
                                            </ul>

                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="StudentProfile" role="tabpanel" aria-labelledby="StudentProfile-tab">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0">
                                                                <h3 className="card-title text-gray-800 fw-bolder m-0">School Profile</h3>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row mb-8">
                                                                    <div className="col-lg-5 fv-row mb-5">
                                                                        <div className="row">
                                                                            <div className="col-sm-8">
                                                                                <div className="profile-image-out p-0">
                                                                                    <img alt="profile" src={school_form.school_logo.thumbnail_image} className="img-fluid" />
                                                                                    <div className="d-flex justify-content-center h-100 flex-column align-items-center">
                                                                               
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-sm btn-outline-primary px-6 rounded-50 "
                                                                                            onClick={() => this.setIdentifiers("school_logo")}
                                                                                        >
                                                                                            Upload Logo
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-7">
                                                                        <div className="row">
                                                                            <div className="col-lg-6 fv-row mb-7">
                                                                                <label className="fw-bold mb-2">{school_form.school_name.label} </label>
                                                                                <input
                                                                                    type={school_form.school_name.type}
                                                                                    value={school_form.school_name.value ? school_form.school_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={school_form.school_name.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "school_name")}
                                                                                />
                                                                                {!school_form.school_name.valid && school_form.school_name.onBlur_out ? <div className="error field-error">{school_form.school_name.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6 fv-row mb-7">
                                                                                <label className="fw-bold mb-2">{school_form.actual_school_code.label}</label>
                                                                                <input
                                                                                    type={school_form.actual_school_code.type}
                                                                                    className="form-control"
                                                                                    value={school_form.actual_school_code.value ? school_form.actual_school_code.value : ""}
                                                                                    placeholder={school_form.actual_school_code.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "actual_school_code")}
                                                                                    readOnly
                                                                                />
                                                                                {!school_form.actual_school_code.valid && school_form.actual_school_code.onBlur_out ? <div className="error field-error">{school_form.actual_school_code.error_msg}</div> : ""}
                                                                            </div>


                                                                            <div className="col-lg-6 fv-row mb-7">
                                                                                <label className="fw-bold mb-2">Zip Code *</label>
                                                                                <input
                                                                                    type={school_form.zipcode.type}
                                                                                    value={school_form.zipcode.value ? school_form.zipcode.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={school_form.zipcode.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "zipcode")}
                                                                                />
                                                                                {!school_form.zipcode.valid && school_form.zipcode.onBlur_out ? <div className="error field-error">{school_form.zipcode.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6 fv-row mb-7">
                                                                                <label className="fw-bold mb-2">{school_form.country_id.label}</label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="fname"
                                                                                    className="form-control time-input"
                                                                                    value={school_form.country.value ? school_form.country.value : ""}
                                                                                    readOnly
                                                                                />
                                                                                {!school_form.country_id.valid && school_form.country_id.onBlur_out ? <div className="error field-error">{school_form.country.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6 fv-row mb-7">
                                                                                <label className="fw-bold mb-2">{school_form.state_id.label}</label>
                                                                                <Select
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={school_form.state_id.options}
                                                                                    value={school_form.state_id.options.filter(function (option) {
                                                                                        return option.value === school_form.state_id.value;
                                                                                    })}
                                                                                    onChange={(newValue) => this.handleChange(newValue, "state_id", "weekend_day from value")}
                                                                                />
                                                                                {!school_form.state_id.valid && school_form.state_id.onBlur_out ? <div className="error field-error">{school_form.state.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6 fv-row mb-7">
                                                                                <label className="fw-bold mb-2">{school_form.city_id.label}</label>
                                                                                <Select
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={school_form.city_id.options}
                                                                                    value={school_form.city_id.options.filter(function (option) {
                                                                                        return option.value === school_form.city_id.value;
                                                                                    })}
                                                                                    onChange={(newValue) => this.handleChange(newValue, "city_id", "weekend_day from value")}
                                                                                />
                                                                                {!school_form.city_id.valid && school_form.city_id.onBlur_out ? <div className="error field-error">{school_form.city.error_msg}</div> : ""}
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-lg-3 fv-row mb-7 mb-sm-0">
                                                                        <label className="fw-bold mb-2">Physical Address *</label>
                                                                        <input
                                                                            type={school_form.school_address.type}
                                                                            value={school_form.school_address.value ? school_form.school_address.value : ""}
                                                                            className="form-control"
                                                                            placeholder={school_form.school_address.placeholder}
                                                                            onChange={(event) => this.inputChangeHandler(event, "school_address")}
                                                                        />
                                                                        {!school_form.school_address.valid && school_form.school_address.onBlur_out ? (
                                                                            <div className="error field-error">{school_form.school_address.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row mb-7 mb-sm-0">
                                                                        <label className="fw-bold mb-2">Time Zone *</label>
                                                                        <input
                                                                            type="text"
                                                                            name="fname"
                                                                            className="form-control"
                                                                            value={school_form.timezone.value ? school_form.timezone.value : ""}
                                                                            readOnly
                                                                        />
                                                                        {!school_form.timezone.valid && school_form.timezone.onBlur_out ? <div className="error field-error">{school_form.city.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row mb-7 mb-sm-0">
                                                                        <label className="fw-bold mb-2">{school_form.highest_class.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.highest_class.options}
                                                                            value={school_form.highest_class.options.filter(function (option) {
                                                                                return option.value === school_form.highest_class.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "highest_class")}
                                                                        />
                                                                        {!school_form.highest_class.valid && school_form.highest_class.onBlur_out ? <div className="error field-error">{school_form.highest_class.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row mb-7 mb-sm-0">
                                                                        <label className="fw-bold mb-2">School Email Domain *</label>
                                                                        <input
                                                                            type="text"
                                                                            name="fname"
                                                                            className="form-control"
                                                                            value={school_form.domain_name.value ? school_form.domain_name.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <hr className="mb-8  mt-0"></hr>
                                                                <div className="row">
                                                                    <h6 className="mb-5">Other School Contact 1</h6>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_1_name.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_1_name.type}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_1_name.value ? school_form.other_contact_1_name.value : ""}
                                                                            onChange={(e)=>this.otherContactInputHandler(e,"other_contact_1_name")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_1_position.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_1_position.type}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_1_position.value ? school_form.other_contact_1_position.value : ""}
                                                                            onChange={(e)=>this.otherContactInputHandler(e,"other_contact_1_position")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_1_contact.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={school_form.country_code.value ? school_form.country_code.value : ""}
                                                                            value={school_form.other_contact_1_contact.value ? school_form.other_contact_1_contact.value : ""}
                                                                            onChange={(e)=>this.otherContactInputHandler(e,"other_contact_1_contact")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_1_email.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_1_email.type}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_1_email.value ? school_form.other_contact_1_email.value : ""}
                                                                            onChange={(e)=>this.otherContactInputHandler(e,"other_contact_1_email")}
                                                                        />
                                                                    </div>
                                                                </div>



                                                                <div className="row">
                                                                    <h6 className="mb-5">Other School Contact 2</h6>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_2_name.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_2_name.type}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_2_name.value ? school_form.other_contact_2_name.value : ""}
                                                                            onChange={(e)=>this.otherContactInputHandler(e,"other_contact_2_name")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_2_position.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_2_position.type}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_2_position.value ? school_form.other_contact_2_position.value : ""}
                                                                            onChange={(e)=>this.otherContactInputHandler(e,"other_contact_2_position")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_2_contact.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={school_form.country_code.value ? school_form.country_code.value : ""}
                                                                            value={school_form.other_contact_2_contact.value ? school_form.other_contact_2_contact.value : ""}
                                                                            onChange={(e)=>this.otherContactInputHandler(e,"other_contact_2_contact")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{school_form.other_contact_2_email.label}</label>
                                                                        <input
                                                                            type={school_form.other_contact_2_email.type}
                                                                            className="form-control ps-7"
                                                                            value={school_form.other_contact_2_email.value ? school_form.other_contact_2_email.value : ""}
                                                                            onChange={(e)=>this.otherContactInputHandler(e,"other_contact_2_email")}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0">
                                                                <h3 className="card-title text-gray-800 fw-bolder m-0">Weekend Details</h3>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-3 fv-row mb-7">
                                                                        <label className="fw-bold mb-2 d-flex">{school_form.weekend_day_from.label}

                                                                            <Tooltip title="Define “Weekend Start” & “Weekend Finish” on this School Leave system" placement="top">
                                                                                <i className="fas fa-question-circle ms-2"></i>
                                                                            </Tooltip>
                                                                        </label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            name={"weekend_day_from"}
                                                                            className="react-bootstrap-typeahead tag-outer "
                                                                            options={school_form.weekend_day_from.options}
                                                                            value={school_form.weekend_day_from.options.filter(function (option) {
                                                                                return option.value === school_form.weekend_day_from.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "weekend_day_from", "weekend_day from value")}
                                                                        />

                                                                        {!school_form.weekend_day_from.valid && school_form.weekend_day_from.onBlur_out ? <div className="error field-error">{school_form.weekend_day_from.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row mb-7">
                                                                        <label className="fw-bold mb-2">Weekend Start Time *</label>
                                                                        <input type="time" name="fname" className="form-control time-input"
                                                                            placeholder="13:30"
                                                                            value={school_form.weekend_time_from.value ? school_form.weekend_time_from.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "weekend_time_from")}
                                                                        />
                                                                        {!school_form.weekend_time_from.valid && school_form.weekend_time_from.onBlur_out ? <div className="error field-error">{school_form.weekend_time_from.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row mb-7">
                                                                        <label className="fw-bold mb-2">{school_form.weekend_day_to.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.weekend_day_to.options}
                                                                            value={school_form.weekend_day_to.options.filter(function (option) {
                                                                                return option.value === school_form.weekend_day_to.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "weekend_day_to", "weekend_day to value")}
                                                                        />

                                                                        {!school_form.weekend_day_to.valid && school_form.weekend_day_to.onBlur_out ? <div className="error field-error">{school_form.weekend_day_to.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-3 fv-row mb-7">
                                                                        <label className="fw-bold mb-2">Weekend Finish Time *</label>
                                                                        <input type="time" name="fname" className="form-control time-input"
                                                                            value={school_form.weekend_time_to.value ? school_form.weekend_time_to.value : ""}
                                                                            placeholder="13:30"
                                                                            onChange={(event) => this.inputChangeHandler(event, "weekend_time_to")}
                                                                        />
                                                                        {!school_form.weekend_time_to.valid && school_form.weekend_time_to.onBlur_out ? <div className="error field-error">{school_form.weekend_time_to.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-7">
                                                                        <label className="fw-bold mb-2 d-flex">{school_form.cut_off_day.label}

                                                                            <Tooltip title="For Boarders to submit their Leave Applications" placement="top">
                                                                                <i className="fas fa-question-circle ms-2"></i>
                                                                            </Tooltip>
                                                                        </label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.cut_off_day.options}
                                                                            value={school_form.cut_off_day.options.filter(function (option) {
                                                                                return option.value === school_form.cut_off_day.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "cut_off_day", "weekend_day to value")}
                                                                        />

                                                                        {!school_form.cut_off_day.valid && school_form.cut_off_day.onBlur_out ? <div className="error field-error">{school_form.cut_off_day.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-7">
                                                                        <label className="fw-bold mb-2 d-flex">Cut off time *

                                                                            <Tooltip title="For Boarders to submit their Leave Applications" placement="top">
                                                                                <i className="fas fa-question-circle ms-2"></i>
                                                                            </Tooltip>
                                                                        </label>

                                                                        <input type="time" name="fname" className="form-control time-input"
                                                                            value={school_form.cut_off_time.value ? school_form.cut_off_time.value : ""}
                                                                            placeholder="13:30"
                                                                            onChange={(event) => this.inputChangeHandler(event, "cut_off_time")}
                                                                        />
                                                                        {!school_form.cut_off_time.valid && school_form.cut_off_time.onBlur_out ? <div className="error field-error">{school_form.cut_off_time.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-7">
                                                                        <label className="fw-bold mb-2">{school_form.session_start_month.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.session_start_month.options}
                                                                            value={school_form.session_start_month.options.filter(function (option) {
                                                                                return option.value === school_form.session_start_month.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "session_start_month")}
                                                                        />

                                                                        {!school_form.session_start_month.valid && school_form.session_start_month.onBlur_out ? <div className="error field-error">{school_form.session_start_month.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-7">
                                                                        <label className="fw-bold mb-2">{school_form.session_start_year.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.session_start_year.options}
                                                                            value={school_form.session_start_year.options.filter(function (option) {
                                                                                return option.value === school_form.session_start_year.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "session_start_year")}
                                                                        />

                                                                        {!school_form.session_start_year.valid && school_form.session_start_year.onBlur_out ? <div className="error field-error">{school_form.session_start_year.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-7 mb-sm-0">
                                                                        <label className="fw-bold mb-2">{school_form.session_end_month.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.session_end_month.options}
                                                                            value={school_form.session_end_month.options.filter(function (option) {
                                                                                return option.value === school_form.session_end_month.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "session_end_month")}
                                                                        />

                                                                        {!school_form.session_end_month.valid && school_form.session_end_month.onBlur_out ? <div className="error field-error">{school_form.session_end_month.error_msg}</div> : ""}
                                                                    </div>

                                                                    <div className="col-lg-6 fv-row mb-7 mb-sm-0">
                                                                        <label className="fw-bold mb-2">{school_form.session_end_year.label}</label>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            className="react-bootstrap-typeahead tag-outer"
                                                                            options={school_form.session_end_year.options}
                                                                            value={school_form.session_end_year.options.filter(function (option) {
                                                                                return option.value === school_form.session_end_year.value;
                                                                            })}
                                                                            onChange={(newValue) => this.handleChange(newValue, "session_end_year")}
                                                                        />

                                                                        {!school_form.session_end_year.valid && school_form.session_end_year.onBlur_out ? <div className="error field-error">{school_form.session_end_year.error_msg}</div> : ""}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px" disabled={this.state.isLoading}>
                                                                    Update School
                                                                </button>
                                                            </div>
                                                        </div>
                                                 
                                                    </form>
                                                </div>


                                                <div className="tab-pane fade" id="BoardingDetail" role="tabpanel" aria-labelledby="BoardingDetail-tab">
                                                    <form onSubmit={this.SubmitHandlerBoarding}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0  d-flex align-items-center">
                                                                <h3 className="card-title text-gray-800 fw-bolder m-0">School Admin Details</h3>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-5 fv-row">
                                                                        <div className="row">
                                                                            <div className="col-sm-12 mb-7 mb-sm-0">                                                                                <div className="profile-image-out p-0">
                                                                                <div className="row">
                                                                                    <div className="col-sm-8">
                                                                                        <img alt="profile" src={school_form.avatar.thumbnail_image} className="img-fluid" />
                                                                                        <div className="justify-content-center h-100 flex-column align-items-center">
                                                                                         
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-sm btn-outline-primary px-6 rounded-50 "
                                                                                                onClick={() => this.setIdentifiers("avatar")}
                                                                                            >
                                                                                                Upload Image
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-7">
                                                                        <div className="row">
                                                                            <div className="col-lg-6 fv-row mb-7">
                                                                                <label className="fw-bold mb-2">{school_form.first_name.label} </label>
                                                                                <input
                                                                                    type={school_form.first_name.type}
                                                                                    value={school_form.first_name.value ? school_form.first_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={school_form.first_name.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "first_name")}
                                                                                />
                                                                                {!school_form.first_name.valid && school_form.first_name.onBlur_out ? <div className="error field-error">{school_form.first_name.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6 fv-row mb-7">
                                                                                <label className="fw-bold mb-2">{school_form.last_name.label} </label>
                                                                                <input
                                                                                    type={school_form.last_name.type}
                                                                                    value={school_form.last_name.value ? school_form.last_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={school_form.last_name.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "last_name")}
                                                                                />
                                                                                {!school_form.last_name.valid && school_form.last_name.onBlur_out ? <div className="error field-error">{school_form.last_name.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6 fv-row mb-7">
                                                                                <label className="fw-bold mb-2">{school_form.contact_one.label}</label>
                                                                                <ReactPhoneInput
                                                                                    // countryCodeEditable={false}
                                                                                    country={school_form.country_code.value ? school_form.country_code.value : ""}
                                                                                    value={school_form.contact_one.value ? school_form.contact_one.value : ""}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "contact_one")}
                                                                                    //  onChange={this.handleOnChange}
                                                                                    enableLongNumbers={true}
                                                                                />

                                                                                {!school_form.contact_one.valid && school_form.contact_one.onBlur_out ? <div className="error field-error">{school_form.contact_one.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6 fv-row mb-7">
                                                                                <label className="fw-bold mb-2">{school_form.contact_two.label}</label>
                                                                                {console.log("school_form.contact_two.value ",school_form.contact_two.value )}
                                                                                <ReactPhoneInput
                                                                                    // countryCodeEditable={false}
                                                                                    country={school_form.country_code.value ? school_form.country_code.value : ""}
                                                                                    value={school_form.contact_two.value ? school_form.contact_two.value : ""}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "contact_two")}
                                                                                    //  onChange={this.handleOnChange}
                                                                                    enableLongNumbers={true}
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-12 fv-row mb-7">
                                                                                <label className="fw-bold mb-2">Primary Email * (Will be used for Admin Login)</label>
                                                                                <input
                                                                                    type={school_form.email.type}
                                                                                    value={school_form.email.value ? school_form.email.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={school_form.email.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "email")}
                                                                                    readOnly
                                                                                />

                                                                                {!school_form.email.valid && school_form.email.onBlur_out ? <div className="error field-error">{school_form.email.error_msg}</div> : ""}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px">
                                                                    Update Contact Detail
                                                                </button>
                                                            </div>
                                                        </div>
                                                     
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetAllSelectedCountries: userActions.GetAllSelectedCountries,
    onGetAllStatesByCountryId: userActions.GetAllStatesByCountryId,
    onGetAllCitiesByStateId: userActions.GetAllCitiesByStateId,
    onUpdateSchool: userActions.UpdateSchool,
    onUpdateSchoolUser: userActions.UpdateSchoolUser,
    onGetSchoolById: userActions.GetSchoolById,
    onLoginAsSchoolAdmin: userActions.LoginAsSchoolAdmin,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,

};
export default connect(mapStateToProps, mapDispatchToProps)(SchoolEdit);