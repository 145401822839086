import React, { createRef } from "react";

import { Navigate } from "react-router-dom";

import { connect } from "react-redux";

import Validations from "../Utility/Validations";
import Sidebar from "../Constant/sidebar";
import Footer from "../Constant/Footer";
import TopBar from "../Constant/TopBar";
import Select from "react-select";

import { toast } from "react-toastify";

import * as userActions from "../../actions/index";
import AvatarImageCropper from 'react-avatar-image-cropper';

import BreakfastTimeHandler from "../Leave/UniversalComponent/BreakfastTimeHandler";
import LunchTimeHandler from "../Leave/UniversalComponent/LunchTimeHandler";
import DinnerTimeHandler from "../Leave/UniversalComponent/DinnerTimeHandler";

import moment from "moment";
import { height, width } from "@mui/system";
import { CircleStencil, Cropper } from 'react-advanced-cropper';
import "react-advanced-cropper/dist/style.css";
import "./style.css";
import { Button } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
var manager_id = [];

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;


class DormitoryAdd extends React.Component {
    inputRef = createRef();
    cropperRef = createRef();
    constructor(props) {
        super(props);
        window.addEventListener('keydown', this.handleKeyPress);
        this.state = {
            OpenImageModal: false,
            isLoading: false,
            image: "",
            errorMessage: "",
            dormitory_form: {
                dormitory_name: {
                    type: "text",
                    label: "Dormitory Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Dormitory Name",
                    validations: { required: true },
                },
                bio_note: {
                    type: "text",
                    label: "Bio Note *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Bio Note",
                    validations: { required: true },
                },
                dormitory_type: {
                    type: "radio",
                    label: "Dormitory Type *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },
                dormitory_image: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    thumbnail_image: "/assets/media/dormitory1.png",
                },
                dormitory_contact: {
                    type: "number",
                    label: "Dormitory Contact Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Dormitory Contect Number",
                    validations: { required: true },
                },
                user_ids: {
                    type: "select",
                    label: "Select Dormitory Head *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },




                breakfast_time: {
                    type: "select",
                    label: "Breakfast Time *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Breakfast Time",
                    validations: { required: true },
                    options: [],
                },
                lunch_time: {
                    type: "select",
                    label: "Lunch Time *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Lunch Time",
                    validations: { required: true },
                    options: [],
                },
                dinner_time: {
                    type: "select",
                    label: "Dinner Time *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Dinner Time",
                    validations: { required: true },
                    options: [],
                },
            },
        };
    }

    componentDidMount() {

        this.GetAllManagersListBySchoolId();
        this.GetLoginUserPermissions();
    }

    GetLoginUserPermissions() {
        this.props.onGetLoginUserPermissions().then((response) => {
            if (response.success === true) {
            } else {
            }
        });
    }

    GetAllManagersListBySchoolId = async (form_data) => {
        this.props.onGetAllManagersListBySchoolId().then((response) => {
            let state_level = [];
            state_level.push({
                label: "Select Dormitory Head",
                value: ""
            })
            const update_dormitory_form = { ...this.state.dormitory_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    state_level.push({
                        label: arrayData[key].first_name + " " + arrayData[key].last_name,
                        value: arrayData[key].user_id,

                    });
                }
            }
            update_dormitory_form['user_ids'].options = state_level;
            this.setState({ dormitory_form: update_dormitory_form });
        });
    }
    handleChangeMulti = (event) => {
        const update_dormitory_form = { ...this.state.dormitory_form };
        manager_id = []
        for (let key in event) {
            manager_id.push(JSON.parse(event[key].value))
        }
        update_dormitory_form["user_ids"].value = JSON.stringify(manager_id);
        this.setState({ dormitory_form: update_dormitory_form });
    };



    // inputChangeHandlerimage = blob => {
    //     const src = window.URL.createObjectURL(blob);
    //     let url = URL.createObjectURL(blob);
    //     let file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type })
    //     const update_dormitory_form = { ...this.state.dormitory_form };
    //     const form_element = { ...update_dormitory_form["dormitory_image"] };
    //     form_element.thumbnail_image = url;

    //     form_element.value = file;
    //     update_dormitory_form["dormitory_image"] = form_element;
    //     this.setState({ dormitory_form: update_dormitory_form });
    //     this.setState({ OpenImageModal: !this.state.OpenImageModal })
    // };




    inputChangeHandler(event, identifier) {
        const update_dormitory_form = { ...this.state.dormitory_form };
        const form_element = { ...update_dormitory_form[identifier] };
        form_element.value = event.target.value;

        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_dormitory_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_dormitory_form) {
            is_form_valid = update_dormitory_form[identifier].valid && is_form_valid;
        }
        this.setState({
            dormitory_form: update_dormitory_form,
            is_form_valid: is_form_valid,
        });
    }




    SubmitHandler = async (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_dormitory_form = this.state.dormitory_form;
        for (let key in update_dormitory_form) {
            let form_element = update_dormitory_form[key];
            let validate_element = Validations(form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_dormitory_form[key] = form_element;
        }
        this.setState({ dormitory_form: update_dormitory_form });
        if (is_form_valid) {
            const form_data = {};

            for (let key in this.state.dormitory_form) {
                form_data[key] = this.state.dormitory_form[key].value;
            }
            // console.log("final_form",form_data);
            this.setState({ isLoading: true });
            this.props.onCreateDormitories(form_data).then((response) => {
                if (response.success === true) {
                    console.log("hey");
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setTimeout(() => {
                        this.setState({ isLoading: false });
                        this.setState({
                            isAuthenticated: true,
                            redirect_url: `/${school_code}/dormitory-list`,
                        });
                    }, 500);
                } else {
                    console.log("error");
                    this.setState({ isLoading: false });
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            });
        }
    };

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        if (event.keyCode === 27) {
            this.setState({ OpenImageModal: false })
        }
    };

    mealTimeHandler = (e, identifier) => {
        const update_dormitory_form = { ...this.state.dormitory_form };
        const form_element = { ...update_dormitory_form[identifier] };
        form_element.value = moment(e, 'hh:mm A').format('HH:mm');
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        update_dormitory_form[identifier] = form_element;
        this.setState({ dormitory_form: update_dormitory_form });
    }

    // react-advanced-cropper 

    onUpload = () => {
        if (this.inputRef.current) {
            this.inputRef.current.click();
        }
    };

    onCrop = () => {
        const cropper = this.cropperRef.current;
        if (cropper) {
            const canvas = cropper.getCanvas();
            if (canvas) {
                canvas.toBlob((blob) => {
                    if (blob) {
                        this.inputChangeHandlerimage(blob);
                    }
                });
            }
        }
    };

    inputChangeHandlerimage = (blob) => {
        const src = window.URL.createObjectURL(blob);
        const file = new File([blob], "image.png", { lastModified: new Date().getTime(), type: blob.type });

        const update_dormitory_form = { ...this.state.dormitory_form };
        const form_element = { ...update_dormitory_form["dormitory_image"] };
        form_element.thumbnail_image = src;
        form_element.value = file;
        update_dormitory_form["dormitory_image"] = form_element;

        this.setState({
            dormitory_form: update_dormitory_form,
            OpenImageModal: !this.state.OpenImageModal,
        });
    };

    onLoadImage = (event) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            // Check if the file size exceeds 2MB (2 * 1024 * 1024 bytes)
            if (file.size > 2 * 1024 * 1024) {
                this.setState({
                    errorMessage: "The size of image is too large.",
                    image: null, // Clear any previously loaded image
                });
            } else {
                this.setState({
                    image: URL.createObjectURL(file),
                    errorMessage: "", // Clear error if the file is valid
                });
            }
        }
        event.target.value = ""; // Reset the input
    };

    componentWillUnmount() {
        const { image } = this.state;
        if (image) {
            URL.revokeObjectURL(image);
        }
    }

    render() {
        const { dormitory_form } = this.state;
        const { image, OpenImageModal, errorMessage } = this.state;
        return (
            <React.Fragment>
                {this.state.OpenImageModal &&
                    <div className="upload-image-outer-div"  >
                        <span style={{ float: "right" }} className="pointer m-5" onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}>
                            <i class="fas fa-times fa-2x" sixe="xl" style={{ color: "#ffffff", }}></i>
                        </span>

                        {
                            image ?
                                <div className="upload-image-dormitory">
                                    <div className="example">
                                        <div className="example__cropper-wrapper">
                                            <Cropper
                                                ref={this.cropperRef}
                                                className="example__cropper"
                                                backgroundClassName="example__cropper-background"
                                                src={image}
                                            />
                                        </div>
                                        <div className="example__buttons-wrapper">
                                            <button className="example__button" onClick={this.onUpload}>
                                                <input
                                                    ref={this.inputRef}
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={this.onLoadImage}
                                                />
                                                Upload image
                                            </button>
                                            {image && (
                                                <button className="example__button" onClick={this.onCrop}>
                                                    Submit
                                                </button>
                                            )}
                                        </div>
                                        {OpenImageModal && <div className="modal">Image has been updated!</div>}
                                    </div>
                                </div>
                                : <div className="upload-image-inner-div-dormitory">
                                    <div className="upload-input-container">
                                        <div
                                            className="upload-input"
                                            onClick={this.onUpload}
                                        >
                                            <div>
                                                <svg viewBox="0 0 24 24" style={{ display: "inline-block", color: "rgb(255, 255, 255)", fill: "currentcolor", height: "32px", width: "32px", userSelect: "none" }}>
                                                    <circle cx="12" cy="12" r="3.2"></circle>
                                                    <path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"></path>
                                                </svg>
                                            </div>

                                            <input
                                                ref={this.inputRef}
                                                type="file"
                                                accept="image/*"
                                                onChange={this.onLoadImage}
                                            />
                                            Upload image
                                            {errorMessage && <span className="text-danger font-weight-bold">{errorMessage}</span>}
                                        </div>
                                    </div>
                                </div>
                        }

                        {/* <div className="upload-image-inner-div-dormitory">
                            <AvatarImageCropper
                                rootStyle={{ borderRadius: "50%", backgroundPosition: "center", backgroundSize: "contain" }}
                                cancelBtnStyle={{ display: "none" }}
                                apply={this.inputChangeHandlerimage}
                                className={"image_crop_style"}
                                sliderConStyle={{ margin: "40px", backgroundColor: "none" }}
                                applyBtnStyle={{}}
                                isBack={true}
                            // maxsize={1920*1920*10}
                            />
                        </div> */}
                        <div className="text-center mt-10 d-flex flex-column align-items-center">
                            <span className="text-white font-weight-bold rounded ">The image maximum size is 2MB. Please upload png/jpg/jpeg image</span>
                        </div>
                    </div>
                }
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100">
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>

                            {/* <LeftSideBar></LeftSideBar> */}

                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                {/* <Topbar></Topbar> */}

                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="school-edit-form ">
                                                <div className="" id="Snapshot">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative details-bg">
                                                                <h5 className="card-title  m-0">Add Dormitory</h5>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row align-items-center">
                                                                    <div className="col-lg-4 mb-5 mb-sm-0">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <label className="fw-bold mb-2 ">Dormitory Image</label>
                                                                                <div className="profile-image-out p-0">
                                                                                    <img alt="profile" src={dormitory_form.dormitory_image.thumbnail_image} className="img-fluid" style={{ objectFit: "contain" }} />
                                                                                    <div className="d-flex justify-content-center h-100 flex-column align-items-center">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-sm btn-outline-primary px-10 rounded-50"
                                                                                            onClick={() => this.setState({ OpenImageModal: !this.state.OpenImageModal })}
                                                                                        >
                                                                                            Upload Image
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-lg-8">
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <label className="fw-bold mb-2">{dormitory_form.dormitory_type.label}</label>
                                                                                <div className="d-flex">
                                                                                    <label className="cursor-pointer">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="gender"
                                                                                            name="gen"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value="boy"
                                                                                            onChange={(event) => this.inputChangeHandler(event, "dormitory_type")}
                                                                                        />
                                                                                        Male
                                                                                    </label>

                                                                                    <label className="ms-8 cursor-pointer">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="gender1"
                                                                                            name="gen"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value="girl"
                                                                                            onChange={(event) => this.inputChangeHandler(event, "dormitory_type")}
                                                                                        />
                                                                                        Female
                                                                                    </label>

                                                                                    <label className="ms-8 cursor-pointer">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="gender2"
                                                                                            name="gen"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value="both"
                                                                                            onChange={(event) => this.inputChangeHandler(event, "dormitory_type")}
                                                                                        />
                                                                                        Both
                                                                                    </label>
                                                                                </div>
                                                                                {!dormitory_form.dormitory_type.valid && dormitory_form.dormitory_type.onBlur_out ? (
                                                                                    <div className="error field-error">{dormitory_form.dormitory_type.error_msg}</div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>

                                                                        </div>

                                                                        <div className="row my-5">
                                                                            <div className="col-lg-6">
                                                                                <label className="fw-bold mb-2">{dormitory_form.dormitory_name.label} </label>
                                                                                <input
                                                                                    type={dormitory_form.dormitory_name.type}
                                                                                    value={dormitory_form.dormitory_name.value ? dormitory_form.dormitory_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={dormitory_form.dormitory_name.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "dormitory_name")}
                                                                                />
                                                                                {!dormitory_form.dormitory_name.valid && dormitory_form.dormitory_name.onBlur_out ? (
                                                                                    <div className="error field-error">{dormitory_form.dormitory_name.error_msg}</div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>

                                                                            <div className="col-lg-6">
                                                                                <label className="fw-bold mb-2">{dormitory_form.user_ids.label}</label>
                                                                                <Select
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={dormitory_form.user_ids.options}
                                                                                    // value={dormitory_form.user_ids.value ? dormitory_form.user_ids.valid : ""}
                                                                                    onChange={this.handleChangeMulti}
                                                                                    isMulti
                                                                                />
                                                                                {!dormitory_form.user_ids.valid && dormitory_form.user_ids.onBlur_out ? <div className="error field-error">{dormitory_form.user_ids.error_msg}</div> : ""}
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <label className="fw-bold mb-2">{dormitory_form.bio_note.label}</label>
                                                                                <textarea style={{ maxHeight: '300px' }}
                                                                                    type={dormitory_form.bio_note.type}
                                                                                    value={dormitory_form.bio_note.value ? dormitory_form.bio_note.value : ""}
                                                                                    className="form-control autoresizing"
                                                                                    placeholder={dormitory_form.bio_note.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "bio_note")}
                                                                                />
                                                                                {!dormitory_form.bio_note.valid && dormitory_form.bio_note.onBlur_out ? <div className="error field-error">{dormitory_form.bio_note.error_msg}</div> : ""}
                                                                            </div>

                                                                            <div className="col-lg-6">
                                                                                <label className="fw-bold mb-2">{dormitory_form.dormitory_contact.label}</label>
                                                                                <input
                                                                                    type={dormitory_form.dormitory_contact.type}
                                                                                    value={dormitory_form.dormitory_contact.value ? dormitory_form.dormitory_contact.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={dormitory_form.dormitory_contact.placeholder}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "dormitory_contact")}
                                                                                />
                                                                                {!dormitory_form.dormitory_contact.valid && dormitory_form.dormitory_contact.onBlur_out ? (
                                                                                    <div className="error field-error">{dormitory_form.dormitory_contact.error_msg}</div>
                                                                                ) : (
                                                                                    ""
                                                                                )}                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative details-bg">
                                                                <h5 className="card-title  m-0">Dormitory Meal Timings </h5>
                                                            </div>

                                                            <div className="card-body">
                                                                <div className="row mt-5">
                                                                    <div className="col-4">
                                                                        <label className="fw-bold mb-2">{dormitory_form.breakfast_time.label}</label>
                                                                        <BreakfastTimeHandler
                                                                            placeholder={dormitory_form.breakfast_time.placeholder}
                                                                            onChange={(event) => this.mealTimeHandler(event, "breakfast_time")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <label className="fw-bold mb-2">{dormitory_form.lunch_time.label}</label>
                                                                        <LunchTimeHandler
                                                                            placeholder={dormitory_form.lunch_time.placeholder}
                                                                            onChange={(event) => this.mealTimeHandler(event, "lunch_time")}
                                                                        />
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <label className="fw-bold mb-2">{dormitory_form.dinner_time.label}</label>
                                                                        <DinnerTimeHandler
                                                                            placeholder={dormitory_form.dinner_time.placeholder}
                                                                            onChange={(event) => this.mealTimeHandler(event, "dinner_time")}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <div className="d-flex align-items-end">
                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px" disabled={this.state.isLoading}>
                                                                    Add Dormitory
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {

    onCreateDormitories: userActions.CreateDormitories,
    onGetAllManagersListBySchoolId: userActions.GetAllManagersListBySchoolId,
    onGetLoginUserPermissions: userActions.GetLoginUserPermissions,
};
export default connect(mapStateToProps, mapDispatchToProps)(DormitoryAdd);