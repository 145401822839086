import React, { useState, useRef ,useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import Validations from "../../Utility/Validations";

const BorderLeaveApproveModal = (props) => {
  const [showModal, setShowModal] = useState(false)
  const textareaRefComment = useRef(null);
  const [approve_form, setApproveForm] = useState({
    is_parent_approval: {
      type: "text",
      label: " is parent approval",
      value: '',
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "",
      validations: { required: false },
    },
    is_user_approval: {
      type: "text",
      label: "is user approval",
      value: '',
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "",
      validations: { required: false },
    },
    parent_approved_comments: {
      type: "text",
      label: "Parent Comments",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "Leave Approved on the behalf of parents",
      validations: false,
    },
    user_approved_comments: {
      type: "text",
      label: "User Comments",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "Leave Approved",
      validations: false,
    },
    border_leave_id: {
      type: "hidden",
      label: "Border Id",
      value: "",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "",
      validations: { required: false },
    },
    modal_mode: {
      type: "hidden",
      label: "modal mode",
      value:"",
      valid: true,
      error_msg: "",
      onBlur_out: false,
      placeholder: "",
      validations: { required: false },
    }
  });


  const inputChangeHandler = (event, identifier) => {
    const update_approve_form = { ...approve_form };
    if (props.approvedBy == 'parent') {
      update_approve_form[identifier].value = event.target.value;
      update_approve_form['is_parent_approval'].value = 'approved';
      update_approve_form['modal_mode'].value = 'parent';
    } else{
      update_approve_form[identifier].value = event.target.value;
      update_approve_form['is_user_approval'].value = 'approved'
      update_approve_form['modal_mode'].value = 'user';
    }
    textareaRefComment.current.style.height = 'auto'; // Reset the height to auto
    textareaRefComment.current.style.height = `${textareaRefComment.current.scrollHeight}px`; // Set the new height
    setApproveForm(update_approve_form)
  }

  const SubmitHandler = async (event) => {
    let is_form_valid = true;
    const addedForm = { ...approve_form };
    for (let key in addedForm) {
      let addedElement = addedForm[key];
      let validate_element = Validations(addedElement);
      is_form_valid = validate_element.valid === false ? false : is_form_valid;
      addedElement.onBlur_out = true;
      addedElement.valid = validate_element.valid;
      addedElement.error_msg = validate_element.error_msg;
      addedForm[key] = addedElement;
    }
    setApproveForm(addedForm);
    if (is_form_valid) {
      const form_data = {};
      for (let key in approve_form) {
        if (props.approvedBy === "user") {
          if (key === "user_approved_comments") {
            form_data[key] = approve_form[key].value === "" ? "Leave Approved" : approve_form[key].value;
          }else if(key === "is_user_approval"){
            form_data[key] = "approved"
          } else {
            form_data[key] = approve_form[key].value;
          }
        } 
        if(props.approvedBy === "parent"){
          if (key === "parent_approved_comments") {
            form_data[key] = approve_form[key].value === "" ? "Leave Approved on the behalf of parents" : approve_form[key].value;
          }else if(key === "is_parent_approval"){
            form_data[key] = "approved"
          } else {
            form_data[key] = approve_form[key].value;
          }
        }
      }
      props.BorderLeaveApproval(form_data);
    }
  }

  const closeModal = () => {
    props.hideApprovedLeaveModal(false)
  };

  useEffect(() => {
    setShowModal(props.showBoarderLeaveApprovedModal)
    const update_approve_form = { ...approve_form }
    update_approve_form['border_leave_id'].value = props.boarderLeaveData.border_leave_id;
    update_approve_form['modal_mode'].value = props.approvedBy;
    setApproveForm(update_approve_form)
  }, [props]);


  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Any comments relative to this particular Leave?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="form w-100" onSubmit={SubmitHandler}>
          <div className="row">
            <div className="col-lg-12 fv-row mb-4">
              {props.approvedBy === "parent" ?
                <div className="row">
                  <div className="col-sm-12">
                    <label className="fw-bold mb-2 me-4">Add Comment</label>
                    <textarea
                      type={approve_form.parent_approved_comments.type}
                      placeholder={approve_form.parent_approved_comments.placeholder}
                      className="form-control input_field autoresizing1"
                      onChange={(event) => inputChangeHandler(event, "parent_approved_comments")}
                      ref={textareaRefComment}
                    />
                    {!approve_form.parent_approved_comments.valid ? <div className="error field-error">{approve_form.parent_approved_comments.error_msg}</div> : ""}
                  </div>
                </div>
                :
                <div className="row">
                  <div className="col-sm-12">
                    <label className="fw-bold mb-2 me-4">Add Comment</label>
                    <textarea
                      type={approve_form.user_approved_comments.type}
                      placeholder={approve_form.user_approved_comments.placeholder}
                      className="form-control input_field autoresizing1"
                      onChange={(event) => inputChangeHandler(event, "user_approved_comments")}
                      ref={textareaRefComment}
                    />
                    {!approve_form.user_approved_comments.valid ? <div className="error field-error">{approve_form.user_approved_comments.error_msg}</div> : ""}
                  </div>
                </div>
              }
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary btn-sm" onClick={closeModal}>Close</Button>
        <Button type="button" className="btn btn-sm btn-primary px-10 btn-sm" onClick={() => SubmitHandler()} disabled={props.isLoading}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BorderLeaveApproveModal;
