import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import Switch from "@material-ui/core/Switch";
import styled from "styled-components";

const AdhocLeaveSignInSignOutModal = (props) => {
    const [status, setStatus] = useState()
    const [leaveUuid, setLeaveUuid] = useState()
    useEffect(() => {
        setLeaveUuid(props.status_data.leave)
        if (props.status_data.status === "pending") {
            setStatus(true)
        }
        if (props.status_data.status === "out") {
            setStatus(false)
        }
    }, [props])
    const setDataAndSubmit = () => {
        const payload = {
            check_in_out: status === true ? "out" : "in",
            adhoc_leave_uuid: leaveUuid
        }
        props.UpdateAdhocLeaveSignInOrSignOu(payload)
    }
    const deasbled = {
        position: "absolute",
        width: "100%",
        height: "100%",
        right: "0px",
        left: "0px",
        top: "-2px",
        bottom: "0",
        backgroundColor: "#ffffff82",
        zIndex: "99999999",
    }
    return (
        <React.Fragment>
            <Modal
                backdrop="static"
                keyboard={false}
                className="confirm_delete_modal confirm_modal"
                show={props.confirm_modal}
                onHide={() => { props.modalHide() }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Sign Out/IN Adhoc Leave</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-8">
                        <div className="d-flex align-items-center justify-content-center">
                            <label className="fw-bold">Change Status: </label>
                            <div className="ms-2 position-relative text-center">
                                {props.status_data.status === "in" && <div style={deasbled}></div>}
                                {/* <IOSSwitch value={status} onChange={(e) => setStatus(!status)} checked={status} /> */}
                                {/* <label 
                                    class="toggleSwitch xlarge" 
                                    value={status} 
                                    // onChange={(e) => setStatus(!status)} 
                                    checked={status}
                                >
                                    <input type="checkbox" value={status} checked={status} />
                                    <span>
                                        <span>IN</span>
                                        <span>Out</span>
                                    </span>{console.log(status,"::status")}
                                    <a></a>
                                </label> */}
                                {status?<span className="text-danger fw-bold">Out</span>: <span className="text-success fw-bold">IN</span>}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 mt-4 d-flex justify-content-end">
                        <button type="submit" className="btn btn-sm btn-primary" onClick={() => setDataAndSubmit()} disabled={props.isLoading}>
                            Submit
                        </button>
                        <button type="submit" className="btn btn-sm btn-secondary ms-4" onClick={() => { props.modalHide() }}>
                            Cancel
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );

}
export default AdhocLeaveSignInSignOutModal;


const IOSSwitch = styled(Switch).attrs(() => ({
    classes: {
        root: "root",
        switchBase: "switchBase",
        thumb: "thumb",
        track: "track",
        checked: "checked",
        focusVisible: "focusVisible"
    },
    disableRipple: true,
    focusVisibleClassName: "focusVisible"
}))`
    &.root {
      width: 80px;
      height: 40px;
      padding: 0;
      margin: 8px;
    }
  
    .switchBase {
      padding: 1px;
  
      &.checked {
        transform: translateX(41px);
        color: white;
        & + .track {
          background-color: #52d869;
          opacity: 1;
          border: none;
        }
      }
  
      &.focusVisible &.thumb {
        color: #52d869;
        border: 6x sold #fff;
      }
    }
  
    .thumb {
      width: 37px;
      height: 37px;
    }
  
    & .track {
      border-radius: 19px;
      background-color: #ff3838;
      opacity: 1;
      transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1),
        border 300ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  
    .checked {
    }
    .focusVisible {
    }
  `;


