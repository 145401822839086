import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "../../Constant/sidebar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import DeleteConfirmRemove from "../../Utility/DeleteConfirmRemove";
import { Link, NavLink } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as userActions from "../../../actions/index";
import AllergyItem from "./AllergyItem"
import { DeleteAllergyModal } from "../../Utility/DeleteAllergyModal";
import Select from "react-select";
import IconButton from '@mui/material/IconButton';
import { Tooltip } from "@mui/material";


const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};
const AllergicStudent = (props) => {
    const [src, setSrc] = useState("");
    const dispatch = useDispatch();
    const [class_id, setClass_id] = useState("");
    const [classList, setClassList] = useState([]);
    const [dormitory_id, setDormitory_id] = useState('');
    const [dormitoryList, setDormitoryList] = useState([]);
    const [gender, setGender] = useState('');
    const [student_id, setStudent_id] = useState('')
    const [student_age, setStudent_age] = useState('');
    const [Allergy_List, setAllergy_List] = useState([])
    const [allergy_name, setAllergy_name] = useState('')

    const [studentAgeList, setStudentAgeList] = useState([
        { label: "Select Age", value: "" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
        { label: "22", value: "22" },
        { label: "23", value: "23" },
        { label: "24", value: "24" },
        { label: "25", value: "25" },
        { label: "26", value: "26" },
        { label: "27", value: "27" },
        { label: "28", value: "28" },
        { label: "29", value: "29" },
        { label: "30", value: "30" },
    ]);


    const [filterlimit, setfilterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersort, setfiltersort] = useState("student_first_name");
    const [filtersearch, setfiltersearch] = useState(null);
    const [is_student_activate, setis_student_activate] = useState('');

    const [AllergyList, setAllergyList] = useState([]);
    const [AllergyListcount, setAllergyListcount] = useState(0);

    const [confirm_modal, setconfirm_modal] = useState(false);
    const [editconfirmremovedata, seteditconfirmremovedata] = useState([]);


    const [showModal, setShowModal] = useState(false);
    const [confirmRemoveData, setConfirmRemoveData] = useState({})
    const [to_date, setToDate] = useState("");
    const [from_date, setFromDate] = useState("");

    const [class_name, setclass_name] = useState("");
    const [dormitory_name, setdormitory_name] = useState("");
    const [isCsv, setIsCsv] = useState();
    const [pdf_url, setPdf_url] = useState("")

    const navigate = useNavigate();

    const permission_info = JSON.parse(localStorage.getItem("permissions"));

    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };

    const RemoveCourseModule = (event, course_data) => {
        seteditconfirmremovedata(course_data);
        setconfirm_modal(true);
    };

    const GetAllAllergicStudentsDetail = useCallback(async (is_pdf = false, is_csv = false) => {
        // dispatch(userActions.GetAllAllergicStudentsDetail(filterpage, filterlimit, filtersort, filterorder, is_student_activate, filtersearch,student_age, dormitory_id, class_id, gender,allergy_name)).then((response) => {
        dispatch(userActions.StudentsAllergicReport(filterpage, filterlimit, filtersort, filterorder, class_id, class_name, dormitory_id, dormitory_name, gender, to_date, from_date, filtersearch, allergy_name, is_pdf, is_csv)).then((response) => {
            if (response.success === true) {
                // const arrayData = response.data.rows;
                const arrayData = response.data;
                console.log(response, "::arrayData345")
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

                    MemberId_level.push({
                        perpage: perpage,
                        country_id: arrayData[key].country_id,
                        name: arrayData[key].student_first_name + arrayData[key].student_last_name,
                    });
                }
                // const filterArray = arrayData.map((item)=>{
                //     console.log("item_data",item);
                //     if(item.allergy_details.length ===0){
                //         DeleteAllergy(item)
                //     }else{

                //         return item;
                //     }
                // });
                setAllergyList(arrayData);
                setPdf_url(response.file)
                setIsCsv(response.csvFile)
                // setIsCsv(response.csvFile)
                // setAllergyListcount(response.data.count);
                setAllergyListcount(response.totalCount);
            }
            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    // navigate('/logout')
                }, 5000);
            }

            else {
            }
        });
    }, [dispatch, filterpage, filterlimit, filtersort, filterorder, is_student_activate, filtersearch, student_age, dormitory_id, class_id, gender, allergy_name]);


    useEffect(() => {
        GetAllAllergicStudentsDetail();
        GetAllDormitoryStudent();
        GetAllClassesDetailsStudent();
        GetAllAllergyList();
    }, [GetAllAllergicStudentsDetail, filterpage, filterlimit, filtersort, filterorder, is_student_activate, filtersearch, student_age, dormitory_id, class_id, gender, allergy_name]);

    const GetLoginUserPermissions = useCallback(async () => {
        dispatch(userActions.GetLoginUserPermissions()).then((response) => {
            if (response.success === true) {
            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

            else {
            }
        });
    }, [dispatch]);


    useEffect(() => {
        GetLoginUserPermissions();
    }, []);




    const toggleModal = (event, row) => {
        setShowModal(!showModal)
        setConfirmRemoveData(row)
    };




    const ConfirmRemove = (form_data) => {
        // console.log("form_data===", form_data);
        setconfirm_modal(false);
        // props.onDeleteCourse(form_data).then((response) => {
        //     if (response.success === true) {
        //         GetAllCourses();
        //         toast.success(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //         setadd_course_modal(false);
        //         setedit_course_modal(false);
        //         setconfirm_modal(false);
        //     } else {
        //         toast.error(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //     }
        // });
    };


    const handleChange = async (event, student_uuid, value) => {
        // console.log("hello")
        // const Payload = {
        //     studentuuid: studentuuid,
        //     is_student_activate: value,
        // };
        // console.log('Payload===', Payload)


        // props.onUpdateGroundedStudentStatus(Payload).then((response) => {
        //     if (response.success === true) {
        //         GetAllGroundedStudentsList();
        //         setfilterpage(1);
        //         toast.success(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //     } else {
        //         toast.error(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //     }
        // });

    }

    const DeleteAllergy = (row) => {
        const payload = {
            student_id: row.student_id,
        }
        props.onRemoveAllAllergyOfStudent(payload).then((response) => {
            if (response.success === true) {
                GetAllAllergicStudentsDetail()
                setShowModal(!showModal)
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }


    const GetAllDormitoryStudent = async (form_data) => {
        props.onGetAllDormitoryStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Dormitory",
                value: "",
            });
            if (response.success === true) {
                const arrayData = response.data.rows;
                if(permission_info){
                    const permClassDormArray = permission_info?.perm_class_dorm;
                    const filteredPermDorm = permClassDormArray.filter(item => item?.dormitory_id !== null)
                    const allowedDormIds = filteredPermDorm.map(item => item?.dormitory_id);
                    const filteredData = arrayData.filter(item => allowedDormIds.includes(item.dormitory_id));
                    for (let key in filteredData) {
                        country_level.push({
                            label: filteredData[key].dormitory_name,
                            value: filteredData[key].dormitory_id,
                            dormitory_id: filteredData[key].dormitory_id,
                        });
                    }
                }else{
                    for (let key in arrayData) {
                        country_level.push({
                            label: arrayData[key].dormitory_name,
                            value: arrayData[key].dormitory_id,
                            dormitory_name: arrayData[key].dormitory_name
                        });
                    }
                }
            }
            setDormitoryList(country_level)
        });
    }

    const GetAllAllergyList = async () => {
        props.onGetAllAllergyList().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Allergy",
                value: "",
            });
            if (response.success === true) {
                const arrayData = response.data;
                for (let key in arrayData) {
                    console.log("GetAllAllergyList=========", arrayData[key]);
                    country_level.push({
                        label: arrayData[key].allergy_name,
                        value: arrayData[key].allergy_name,
                    });
                }
            }
            setAllergy_List(country_level)
        });
    }

    const GetAllClassesDetailsStudent = async (form_data) => {
        props.onGetAllClassesDetailsStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Class",
                value: "",
            });
            if (response.success === true) {
                const arrayData = response.data.rows;
                if(permission_info){
                    const permClassDormArray = permission_info?.perm_class_dorm;
                    const filteredPermDorm = permClassDormArray?.filter(item => item?.class_id !== null)
                    const allowedDormIds = filteredPermDorm?.map(item => item?.class_id);
                    const filteredData = arrayData?.filter(item => allowedDormIds?.includes(item?.class_id));
                    for (let key in filteredData) {
                        country_level.push({
                            label: filteredData[key].class_name,
                            value: filteredData[key].class_id,
                            class_id: filteredData[key].class_id,
                        });
                    }
                 }else{
                     for (let key in arrayData) {
                        country_level.push({
                            label: arrayData[key].class_name,
                            value: arrayData[key].class_id,
                            class_name: arrayData[key].class_name,
     
                        });
                    }
                 }
            }
            setClassList(country_level)
        });
    }

    const handleChangeSearch = (event, identifier) => {
        if (identifier === "class_id") {
            setClass_id(event.value);
            setclass_name(event.class_name)
        } else if (identifier === "dormitory_id") {
            setDormitory_id(event.value);
            setdormitory_name(event.dormitory_name);
        } else if (identifier === "gender") {
            setGender(event.target.value)
        } else if (identifier === "student_age") {
            setStudent_age(event.value)
        } else if (identifier === "allergy_name") {
            setAllergy_name(event.value)
        }
    }
    const ExportStudentsAttendancePdf = (event) => {
        console.log("allergy_anme===========", allergy_name);
        props.onGenerateAllergicStudentsReport(dormitory_id, class_id, student_id, filtersearch, gender, allergy_name).then((response) => {
            if (response.success === true) {
                const csv_url = response.file;
                DownloadPdf(csv_url);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
            else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };

    // const DownloadPdf = (csv_url) => {
    //     const link = document.createElement("a");
    //     link.href = csv_url;
    //     link.target = "_blank"
    //     // link.setAttribute("download");
    //     document.body.appendChild(link);
    //     link.click();
    //     // Clean up and remove the link
    //     link.parentNode.removeChild(link);
    // }
    const DownloadPdf = () => {
        GetAllAllergicStudentsDetail(true, false);
        const link = document.createElement("a");
        link.href = pdf_url;
        link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }
    const DownloadCsvFile = () => {
        GetAllAllergicStudentsDetail(false, true);
        const link = document.createElement("a");
        link.href = isCsv;
        // link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }


    const SubmitHandler = () => {

    }
    console.log(AllergyList, "56:AllergyList")
    return (
        <>
            {confirm_modal && (
                <DeleteConfirmRemove
                    title={`Delete Student?`}
                    bodytitle="Are you sure you want to delete this Student?"
                    editconfirmremovedata={editconfirmremovedata}
                    confirm_modal={confirm_modal}
                    confirmButtonText="Delete"
                    ConfirmRemove={(event) => ConfirmRemove(event)}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                />
            )}

            <DeleteAllergyModal
                modalState={showModal}
                toggleModal={toggleModal}
                confirmRemoveData={confirmRemoveData}
                DeleteAllergy={DeleteAllergy}
            />

            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid " id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="school-edit-form">
                                            <div className="" id="Snapshot">
                                                <div className="card-body p-0">
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <div className="card tabs-outer">
                                                                <div className="card-header position-relative py-0 details-bg">
                                                                    <h2 className="card-title  m-0">Allergic students</h2>
                                                                    <div className="card-toolbar">
                                                                        {permission_info?.add_allergic_students !== false &&
                                                                            (<Link to={`/${school_code}/student/allergic-student/add-allergic-student`} className="clear-btn fs-6 pointer btn btn-link btn-color-info btn-active-color-primary">
                                                                                Add Student Allergies
                                                                            </Link>)}
                                                                    </div>
                                                                </div>

                                                                <div className="card-body">
                                                                    <div className="card-toolbar ">
                                                                        <div className="d-flex align-items-center justify-content-between export-out">
                                                                            {/* <!-- <h4 className="card-title  m-0">Schools</h4> --> */}
                                                                            <div className="d-flex align-items-center position-relative">
                                                                                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                        <path
                                                                                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                            fill="currentColor"
                                                                                        ></path>
                                                                                    </svg>
                                                                                </span>

                                                                                <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }}
                                                                                    onChange={(event) => {
                                                                                        setfiltersearch(event.target.value);
                                                                                        setfilterpage(1);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                <div className="card-toolbar allergic-icons">
                                                                                    {/* <NavLink to={pdfData} target="_blank">
                                                                                        <IconButton
                                                                                            // onClick={(event) => ExportStudentsAttendancePdf(event)}
                                                                                            className="me-5"
                                                                                        >
                                                                                            <i class="fas fa-regular fa-file-pdf"></i>
                                                                                        </IconButton>
                                                                                    </NavLink>
                                                                                    <NavLink to={csvData} target="_blank">
                                                                                        <IconButton>
                                                                                            <i class="fas fa-duotone fa-envelope-open-text"></i>
                                                                                        </IconButton>
                                                                                    </NavLink> */}

                                                                                    <Tooltip title="Download .pdf" placement="top">
                                                                                        <IconButton onClick={() => DownloadPdf()}>
                                                                                            <i class="fas fa-regular fa-file-pdf"></i>
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    <Tooltip title="Download .csv" placement="top">
                                                                                        <IconButton onClick={() => DownloadCsvFile()}>
                                                                                            <i class="fas fa-duotone fa-envelope-open-text"></i>
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <AllergyItem
                                                                                AllergyListcount={AllergyListcount}
                                                                                data={AllergyList}
                                                                                filterlimit={filterlimit}
                                                                                filterpage={filterpage}
                                                                                RemoveCourseModule={(event, data) => RemoveCourseModule(event, data)}
                                                                                propsfilterpage={(event) => setfilterpage(event)}
                                                                                handleChange={(event, student_uuid, value) => handleChange(event, student_uuid, value)}
                                                                                propsfilterlimit={(filterlimit) => {
                                                                                    setfilterlimit(filterlimit);
                                                                                }}

                                                                                handleDelete={(event, row) => { toggleModal(event, row) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>






                                                        <div className="col-sm-4">
                                                            <div className="card">
                                                                {/* <!--begin::Header--> */}
                                                                <div className="card-header border-bottom border-bottom-1">
                                                                    <h3 className="card-title align-items-start flex-column">
                                                                        <span className="card-label fw-bold text-dark">Filters</span>
                                                                    </h3>
                                                                </div>

                                                                <div className="card-body p-6">
                                                                    <div className="d-flex flex-column">
                                                                        <h5>Sort By</h5>
                                                                        <div className="d-flex">
                                                                            {console.log("wdfwefdefdsf", filtersort)}
                                                                            <button className=

                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (filterorder === "desc" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    if (filterorder === 'desc') {
                                                                                        setfilterorder('asc');
                                                                                    }
                                                                                    else {
                                                                                        setfilterorder('desc');
                                                                                    }
                                                                                    setfiltersort('student_first_name');
                                                                                    setfilterpage(1);

                                                                                }}

                                                                            >Name</button>


                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="d-flex flex-column mt-6">
                                                                        <h5>Show</h5>
                                                                        <div className="d-flex">
                                                                            <span className=
                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (is_student_activate === "" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    setis_student_activate('');
                                                                                    setfilterpage(1);

                                                                                }}

                                                                            >All</span>
                                                                            <span className=

                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (is_student_activate === true ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    setis_student_activate(true);
                                                                                    setfilterpage(1);
                                                                                }}
                                                                            >Active</span>
                                                                            <span className=
                                                                                {"filter-badge me-2 filter-button " +
                                                                                    (is_student_activate === false ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                                }
                                                                                onClick={(event) => {
                                                                                    setis_student_activate(false);
                                                                                    setfilterpage(1);
                                                                                }}

                                                                            >Inactive</span>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="card tabs-outer mt-5">
                                                                <form onSubmit={SubmitHandler} id="search-form" >
                                                                    <div className="card-header border-bottom border-bottom-1 align-items-center">
                                                                        <h3 className="card-title align-items-start flex-column">
                                                                            <span className="card-label fw-bold text-dark">Student Search</span>
                                                                        </h3>
                                                                        <button type="reset" className="clear-btn fs-6 pointer btn btn-link btn-color-info btn-active-color-primary"
                                                                            onClick={(event) => {
                                                                                setClass_id("");
                                                                                setDormitory_id("");
                                                                                setStudent_age("");
                                                                                setclass_name("");
                                                                                setdormitory_name("");
                                                                            }}
                                                                        >Clear Search</button>
                                                                    </div>
                                                                    <div className="card-body add-new-schools p-6">
                                                                        <div className="row">
                                                                            <div className="col-lg-12 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Select class *</label>
                                                                                {console.log("class_list", classList)}
                                                                                <Select
                                                                                    placeholder="Select Class"
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={classList}
                                                                                    value={classList?.filter(function (option) {
                                                                                        return option?.value === class_id
                                                                                    })}
                                                                                    onChange={(newValue) => handleChangeSearch(newValue, "class_id")}
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-12 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Select Dormitory *</label>
                                                                                <Select
                                                                                    placeholder="Select Dormitory"
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={dormitoryList}
                                                                                    value={dormitoryList?.filter(function (option) {
                                                                                        return option?.value === dormitory_id;
                                                                                    })}
                                                                                    onChange={(newValue) => handleChangeSearch(newValue, "dormitory_id")}
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-12 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Gender</label>
                                                                                <div className="d-flex">
                                                                                    <label className="cursor-pointer">
                                                                                        <input type="radio" id="gender" name="gen" className="form-check-input me-2 cursor-pointer" value="male" onChange={(event) => handleChangeSearch(event, "gender")} />
                                                                                        Male
                                                                                    </label>

                                                                                    <label className="ms-8 cursor-pointer">
                                                                                        <input type="radio" id="gender1" name="gen" className="form-check-input me-2 cursor-pointer" value="female" onChange={(event) => handleChangeSearch(event, "gender")} />
                                                                                        Female
                                                                                    </label>

                                                                                    <label className="ms-8 cursor-pointer">
                                                                                        <input type="radio" id="gender2" name="gen" className="form-check-input me-2 cursor-pointer" value="all" onChange={(event) => handleChangeSearch(event, "gender")} defaultChecked />
                                                                                        All
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-12 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Select Age *</label>
                                                                                <Select
                                                                                    placeholder="Select Age"
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={studentAgeList}
                                                                                    value={studentAgeList.filter(function (option) {
                                                                                        return option.value === student_age;
                                                                                    })}
                                                                                    onChange={(newValue) => handleChangeSearch(newValue, "student_age")}
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-12 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Select Allergy</label>
                                                                                <Select
                                                                                    placeholder="Select Allergy"
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={Allergy_List}
                                                                                    value={Allergy_List.filter(function (option) {
                                                                                        return option.label === allergy_name;
                                                                                    })}
                                                                                    onChange={(newValue) => handleChangeSearch(newValue, "allergy_name")}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

        </>
    );
}


const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesDetailsStudent: userActions.GetAllClassesDetailsStudent,
    onGetAllAllergicStudentsDetail: userActions.GetAllAllergicStudentsDetail,
    onRemoveAllAllergyOfStudent: userActions.RemoveAllAllergyOfStudent,
    onGenerateAllergicStudentsReport: userActions.GenerateAllergicStudentsReport,
    onGetAllAllergyList: userActions.GetAllAllergyList
};
export default connect(mapStateToProps, mapDispatchToProps)(AllergicStudent);