import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SchoolList from "../../component/superadmin/School/SchoolList"
import Dashboard from "../../component/superadmin/Dashboard/dashboard"

import SchoolAdd from "../../component/superadmin/School/addschool";
import SchoolEdit from "../../component/superadmin/School/editschool";
import Country from "../../component/superadmin/Country/Country";
import StateList from "../../component/superadmin/Country/StateList";
import CityList from "../../component/superadmin/Country/CityList";
// import EditBoardingHead from "../../component/superadmin/School/EditBoardingHead";
import SchoolDetails from "../../component/superadmin/School/schoolDetails/SchoolDetails"
import Subscriptions from "../../component/superadmin/School/Subscriptions"

const School = (props) => {
    const Renderview = () => {
        let location = useLocation();
        let userId = useParams();
        if (location.pathname === "/superadmin/dashboard" || location.pathname === "/superadmin/dashboard/") {
            return <Dashboard />;
        }
        if (location.pathname === "/superadmin/school" || location.pathname === "/superadmin/school/") {
            return <SchoolList />;
        }
        if (location.pathname === "/superadmin/add-school" || location.pathname === "/superadmin/add-school/") {
            return <SchoolAdd />;
        }
        if (location.pathname === `/superadmin/edit-school/${userId.id}` || location.pathname === `/superadmin/edit-school/${userId.id}/`) {
            return <SchoolEdit school_detail_uuid={userId.id}  />;
        }
        if (location.pathname === "/superadmin/country" || location.pathname === "/superadmin/country/") {
            return <Country />;
        }
        if (location.pathname === `/superadmin/state/${userId.id}` || location.pathname === `/superadmin/state/${userId.id}/`) {
            return <StateList country_id={userId.id} />;
        }
        if (location.pathname === `/superadmin/city/${userId.country_id}/${userId.state_id}` || location.pathname === `/superadmin/city/${userId.country_id}/${userId.state_id}/`) {
            return <CityList country_id={userId.country_id} state_id={userId.state_id} />;
        }
        if (location.pathname === "/superadmin/subscriptions" || location.pathname === "/superadmin/subscriptions/") {
            return <Subscriptions />;
        }
        if (location.pathname === `/superadmin/school/school-details/${userId.id}` || location.pathname === `/superadmin/school/school-details/${userId.id}/`) {
            return <SchoolDetails school_detail_uuid={userId.id}  />;
        }
        
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};
export default School;