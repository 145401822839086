import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import SchoolSubscriptionDetails from "./Subscription/SchoolSubscriptionDetails"
import SchoolTrialDetails from "./Trial/SchoolTrialDetails"

function SchoolPayments({ schoolData }) {
  console.log(schoolData?.schoolDetails?.school_detail_id, "::subscriptionDetails")
  return (
    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
      <div className="post d-flex flex-column-fluid" id="kt_post">
        <div id="kt_content_container" className="container-fluid">
          <ul className="nav nav-tabs home-tabs fontweight" id="myTab" role="tablist">
            <li className="nav-item me-6" role="presentation">
              <button className="nav-link active" id="Subscription-tab" data-bs-toggle="tab" data-bs-target="#Subscription" type="button" role="tab" aria-controls="process" aria-selected="false">
                Subscription Details
              </button>
            </li>
            <li className="nav-item me-6" role="presentation">
              <button className="nav-link " id="Trial-tab" data-bs-toggle="tab" data-bs-target="#Trial" type="button" role="tab" aria-controls="home" aria-selected="true">
                Trial Details
              </button>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="Subscription" role="tabpanel" aria-labelledby="Subscription-tab">
              {/* <SchoolSubscription subscriptionDetails={schoolData?.subscriptionDetails} /> */}
              {schoolData?.school_detail_id && <SchoolSubscriptionDetails type={"subscription"} id={schoolData?.school_detail_id} />}
            </div>
            <div className="tab-pane fade " id="Trial" role="tabpanel" aria-labelledby="Trial-tab">
              {schoolData?.school_detail_id && <SchoolTrialDetails type={"demo"} id={schoolData?.school_detail_id} />}
              {/* <Card variant="outlined" sx={{ marginTop: "20px" }}>
                <Box sx={{ background: "#f5f8fa", padding: "20px 16px", display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ color: 'text.secondary', fontWeight: "bold" }}> {subscriptionDetails?.type === "demo" ? "Trial Details" : "Subscription Details"}</Typography>
                </Box>
                <CardContent>
                  {
                    subscriptionDetails?.type === "demo" ?
                      <Grid container spacing={2} sx={{ padding: "0px 16px" }}>
                        <Grid item xs={12} md={6}>
                          <Stack direction="row" spacing={1} alignItems={"center"}>
                            <label class="fw-bold">Trial Start Date:</label>
                            <p className="mb-0 heding-element">
                              {subscriptionDetails?.start_date}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack direction="row" spacing={1} alignItems={"center"}>
                            <label class="fw-bold">Trial End Date:</label>
                            <p className="mb-0 heding-element">
                              {subscriptionDetails?.end_date}
                            </p>
                          </Stack>
                        </Grid>
                      </Grid>
                      :
                      <Grid container spacing={2} sx={{ padding: "0px 16px" }}>
                        <Grid item xs={12} md={6}>
                          <Stack direction="row" spacing={1} alignItems={"center"}>
                            <label class="fw-bold">Subscription Start Date:</label>
                            <p className="mb-0 heding-element">
                              {subscriptionDetails?.start_date}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack direction="row" spacing={1} alignItems={"center"}>
                            <label class="fw-bold">Subscription End Date:</label>
                            <p className="mb-0 heding-element">
                              {subscriptionDetails?.end_date}
                            </p>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack direction="row" spacing={1} alignItems={"center"}>
                            <label class="fw-bold">Amount:</label>
                            <p className="mb-0 heding-element">
                              {subscriptionDetails?.amount}
                            </p>
                          </Stack>
                        </Grid>
                      </Grid>
                  }
                </CardContent>
              </Card> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SchoolPayments