import React, { useState } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment-timezone";

function SubItems(props) {
    console.log(props, "::vprops")
    const [perpagedata, setperpagedata] = useState(10);

    const [columns] = useState([

        {
            text: 'S.No.',
            style: {
                width: '40px'
            },
            name: 'S.no',
            dataField: 'perpage',
        },
        {
            text: "School Created Date",
            dataField: "created_date",
        },
        {

            text: "Subscription Start Date",
            dataField: "start_date",
        },
        {

            text: "Subscription End Date",
            dataField: "end_date",
        },

        {

            text: "Amount",
            dataField: "amount",
        }

    ]);
    const handleTableChange = (type, { sortField, sortOrder, page }) => {
        if (type === "sort") {
            props.propsfiltersort(sortField);
            props.propsfilterorder(sortOrder);
        }

        if (type === "pagination") {
            props.propsfilterpage(page);
        }
    };
    const RemotePagination = ({ columns, dataField, order, data, page, sizePerPage, onTableChange, totalSize }) => (
        <PaginationProvider
            pagination={paginationFactory({
                custom: true,
                page,
                sizePerPage,
                totalSize,
            })}
        >
            {({ paginationProps, paginationTableProps }) => (
                <>
                    <div className="table-responsive common-table">
                        <BootstrapTable
                            remote
                            bootstrap4
                            data={data}
                            keyField="student_uuid"
                            sort={{ dataField: dataField, order: order }}
                            columns={columns}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            sizePerPageList={sizePerPage}
                            headerClasses="fw-bolder text-white bg-dark-row"
                            classes="table-row-bordered"
                            noDataIndication={() => <span className="text-center text-gray-400">No records found</span>}
                        />
                    </div>
                    <div className="d-flex flex-stack justify-content-between flex-wrap pt-3 pagination-main">

                        {totalSize > 0 && (
                            <div className="pagination_limt">
                                <select id='select' name='group' className='form-control input-sm form-select form-select-sm form-select-solid' size='1' value={perpagedata}
                                    onChange={(event) => {
                                        props.propsfilterpage(1);
                                        setperpagedata(event.target.value);
                                        props.propsfilterlimit(parseInt(event.target.value));
                                    }}
                                >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={250}>250</option>
                                    <option value={500}>500</option>
                                </select>
                                {(parseInt(sizePerPage) * parseInt(page)) > totalSize ? (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{totalSize} of {totalSize} records</div>
                                ) : (
                                    <div className="count_number">Displaying {(parseInt(sizePerPage) * parseInt(page) + parseInt(1)) - (parseInt(perpagedata))}-{parseInt(sizePerPage) * parseInt(page)} of {totalSize} records</div>
                                )}
                            </div>
                        )}

                        <div className="pagination_page">
                            <PaginationListStandalone {...paginationProps} />
                        </div>
                    </div>
                </>
            )}
        </PaginationProvider>
    );
    return (
        <React.Fragment>
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-12">

                    <div id="kt_profile_overview_table" className="dataTables_wrapper dt-bootstrap4 no-footer team-list-table">
                        <div className="table-responsive">
                            <div className="card-body p-0 bg-white radious-style radious-users">
                                {/* <Spin spinning={props.loading}> */}
                                <RemotePagination
                                    data={props?.data}
                                    columns={columns}
                                    page={props?.filterpage}
                                    totalSize={props?.SuperAdminSubscriptioncount}
                                    sizePerPage={props?.filterlimit}
                                    order={props?.filterorder}
                                    onTableChange={handleTableChange}
                                />
                                {/* </Spin> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
}

export default SubItems