import { Avatar, Box, Card, CardContent, Grid, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import Select from "react-select";
import CapitalFirstLetter from "../Utility/CapitalFirstLetter";

function SidemenuSchoolDetails({ schoolData }) {
    // suffixNotation
    const getNumberWithSuffix = (number) => {
        if (typeof number !== 'number') {
            throw new Error('Input must be a number');
        }

        const lastDigit = number % 10;
        const lastTwoDigits = number % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return `${number}th year`;
        }

        switch (lastDigit) {
            case 1:
                return `${number}st Year`;
            case 2:
                return `${number}nd Year`;
            case 3:
                return `${number}rd Year`;
            default:
                return `${number}th Year`;
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined" sx={{ marginTop: "20px" }}>
                        <Box sx={{ background: "#f5f8fa", padding: "20px 16px", display: "flex", justifyContent: "space-between" }}>
                            <Typography sx={{ color: 'text.secondary', fontWeight: "bold" }}> School Profile</Typography>
                            <Link to={"/superadmin/edit-school/" + schoolData?.school_detail_uuid}>
                                <i className="bi bi-pencil-square text-primary cursor-pointer"></i>
                            </Link>
                        </Box>
                        <CardContent>
                            <Grid container spacing={2} sx={{ padding: "0px 16px" }}>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">School Name:</label>
                                        <p className="mb-0 heding-element">{schoolData?.school_name}</p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">School Code:</label>
                                        <p className="mb-0 heding-element">{schoolData?.school_code}</p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Zip Code:</label>
                                        <p className="mb-0 heding-element">{schoolData?.zipcode}</p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Country/Region:</label>
                                        <p className="mb-0 heding-element">{schoolData?.country}</p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">State:</label>
                                        <p className="mb-0 heding-element">{schoolData?.state}</p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">City:</label>
                                        <p className="mb-0 heding-element">{schoolData?.city}</p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Highest Year:</label>
                                        <p className="mb-0 heding-element">
                                            {schoolData?.highest_class && getNumberWithSuffix(schoolData?.highest_class)}
                                        </p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Time Zone:</label>
                                        <p className="mb-0 heding-element">
                                            {schoolData?.timezone}
                                        </p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Physical Address:</label>
                                        <p className="mb-0 heding-element">{schoolData?.school_address}</p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">School Email Domain:</label>
                                        <p className="mb-0 heding-element">{schoolData?.domain_name}</p>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined" sx={{ marginTop: "20px" }}>
                        <Typography gutterBottom sx={{ color: 'text.secondary', fontWeight: "bold", background: "#f5f8fa", padding: "20px 16px" }}>
                            School Details
                        </Typography>
                        <CardContent>
                            <Grid container spacing={2} sx={{ padding: "0px 16px" }}>
                                <Grid item xs={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Admin Name:</label>
                                        <p className="mb-0 heding-element">{CapitalFirstLetter(schoolData?.users?.first_name)} {schoolData?.users?.last_name}</p>
                                    </Stack>
                                </Grid>

                                <Grid item xs={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Admin Email:</label>
                                        <p className="mb-0 heding-element">
                                            {schoolData?.users?.email}
                                        </p>
                                    </Stack>
                                </Grid>

                                <Grid item xs={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Session Start Month/Year:</label>
                                        <p className="mb-0 heding-element">
                                            {(schoolData?.session_start_month)}-{schoolData?.session_start_year}
                                        </p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Session End Month/Year:</label>
                                        <p className="mb-0 heding-element">
                                            {(schoolData?.session_end_month)}-{schoolData?.session_end_year}
                                        </p>
                                    </Stack>
                                </Grid>

                                <Grid item xs={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Subscription Start Date:</label>
                                        <p className="mb-0 heding-element">
                                            {schoolData?.subscriptionData?.start_date}
                                        </p>
                                    </Stack>
                                </Grid>


                                <Grid item xs={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Subscription End Date:</label>
                                        <p className="mb-0 heding-element">
                                            {schoolData?.subscriptionData?.end_date}
                                        </p>
                                    </Stack>
                                </Grid>

                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Address:</label>
                                        <p className="mb-0 heding-element">
                                            {schoolData?.city} ({schoolData?.country})
                                        </p>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card variant="outlined" sx={{ marginTop: "20px" }}>
                        <Box sx={{ background: "#f5f8fa", padding: "20px 16px", display: "flex", justifyContent: "space-between" }}>
                            <Typography sx={{ color: 'text.secondary', fontWeight: "bold" }}> Weekend Details</Typography>
                            <Link to={"/superadmin/edit-school/" + schoolData?.school_detail_uuid}>
                                <i className="bi bi-pencil-square text-primary cursor-pointer"></i>
                            </Link>
                        </Box>
                        <CardContent>
                            <Grid container spacing={2} sx={{ padding: "0px 16px" }}>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Weekend Start:</label>
                                        <p className="mb-0 heding-element">{schoolData?.weekend_day_from} - {schoolData?.weekend_time_from}</p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Weekend Finish:</label>
                                        <p className="mb-0 heding-element">{schoolData?.weekend_day_to} - {schoolData?.weekend_time_to}</p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Cut Off Day:</label>
                                        <p className="mb-0 heding-element">{schoolData?.cut_off_day}</p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Cut Off Time :</label>
                                        <p className="mb-0 heding-element">{schoolData?.cut_off_time}</p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Session Start:</label>
                                        <p className="mb-0 heding-element">{schoolData?.session_start_month} - {schoolData?.session_start_year}</p>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                        <label class="fw-bold">Session End:</label>
                                        <p className="mb-0 heding-element">{schoolData?.session_end_month} - {schoolData?.session_end_year}</p>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default SidemenuSchoolDetails;
